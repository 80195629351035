/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);

  i {
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: transform;
  }

  &:hover,
  &:focus,
  &:active {
    i {
      transform: scale(1.25);
    }
  }
}

.hvr-grow-flip-icon {
  display: inline-block;
  vertical-align: middle;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);

  i {
    transform: perspective(1px) translateZ(0) rotate(90deg);
    transition-duration: 0.3s;
    transition-property: transform;
  }

  &:hover,
  &:focus,
  &:active {
    i {
      transform: scale(1.25) rotate(90deg);
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.faa-pulse.animated,
.faa-pulse.animated-hover:hover,
.animation-icon-hover:hover > .faa-pulse {
  -webkit-animation: pulse 3s linear infinite;
  animation: pulse 3s linear infinite;
}
