.country-list {
  width: 100%;

  .country {
    display: flex;

    .country-name {
      display: block;
      font-size: 14px;
      max-width: 80px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .dial-code {
      font-size: 14px;
    }
  }
}

.react-tel-input {
  .selected-flag {
    border-radius: 5px 0 0 5px;
  }

  .flag-dropdown {
    &.open {
      .selected-flag {
        background: transparent;
        border-radius: 5px 0 0 0;
      }
    }
  }
}
