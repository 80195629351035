html {
  font-size: 16px;
}

@media (max-width: 719.95px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 720px) and (max-width: 1079.95px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1080px) and (max-width: 1439.95px) {
  html {
    font-size: 15px;
  }
}
