@import '../base/colors';

$primary-dark-color: #0a1526 !default;
$secondary-dark-color: #112034 !default;
$primary-light-color: #74809a !default;
$primary-highlight-color: $highlight-color !default;
$primary-disabled-color: #bac0cd !default;

$primary-dark-text-color: #e4e9f3 !default;

body {
  & > div > .DateRangePicker_picker {
    z-index: 99999;
  }
}

.DatePicker-dark {
  & .DateRangePicker_picker {
    border: 1px solid #111f33;
    box-shadow: 0 2px 4px 0 #111f339c;

    & .DayPickerKeyboardShortcuts_panel {
      background: $secondary-dark-color;
      color: $primary-light-color;

      &::-webkit-scrollbar {
        width: 5px;
        margin-right: -5px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c4cad9;
        border-radius: 2.5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: darken(#c4cad9, 5%);
      }
    }
  }

  & .DayPicker__horizontal {
    background: $primary-dark-color;
  }

  & .DayPicker_focusRegion {
    background: $primary-dark-color;
  }

  & .CalendarMonthGrid {
    background: $primary-dark-color;

    & .CalendarMonth {
      background: $primary-dark-color;

      & .CalendarMonth_caption {
        color: #e4e9f3;
      }

      & .CalendarDay {
        background: $primary-dark-color;
        color: #74809a;
        border-color: #1e3966;

        &:hover {
          background: #1e3966;
        }
      }

      & .CalendarDay__selected,
      & .CalendarDay__selected_start {
        background: #0a83c8;
        color: #fff;

        &:hover {
          background: #0a83c8 !important;
        }
      }

      & .CalendarDay__selected_span {
        background: #1e3966;
      }
    }
  }
  & .DayPickerNavigation_button,
  & .DayPicker_weekHeader_li {
    background: $primary-dark-color;
    border-color: #74809a;
    color: #74809a;

    & svg {
      fill: #74809a;
    }
  }
}

.DarkTheme {
  .DateRangePicker {
    background: #112034;
    border-radius: 5px;

    &Input {
      &__withBorder {
        border-color: #1e3966;
      }

      &_arrow {
        background: #1e3966;

        p {
          color: #fff;
        }
      }

      .DateInput {
        background: transparent;
        &_input {
          background: #112034;
          color: #74809a;
          overflow: hidden;
        }
      }
    }

    &_picker {
      border-color: #1e3966;
      box-shadow: none;
    }
  }

  .SingleDatePicker {
    border-radius: 5px;
    border-color: #1e3966;
    background: #0a1526;

    &Input {
      border-color: #1e3966;
      background: #112034;
    }

    .DateInput {
      background: transparent;
      &_input {
        background: #112034;
        border: none;
      }
    }
  }
}

.DateRangePicker {
  width: 204px;

  &Input {
    &__withBorder {
      display: flex;
      border-radius: 3px;
    }

    &_arrow {
      padding: 0 7px;
      background: #d4dce7;
    }
  }

  &_picker {
    font-family: 'Nunito Sans';
    border: 1px solid #e4e9f3;
    box-shadow: 0 2px 4px 0 #b6bac6;
    border-radius: 6px;

    top: 40px !important;
  }

  .DateInput {
    width: auto;

    &_fang {
      transform: translateY(2px);

      top: 28px !important;
    }

    &_input {
      padding: 2px 6px 0;
      color: #74809a;
      font-size: 13px;
      font-weight: 500;
      font-family: 'Nunito Sans';

      &::-webkit-input-placeholder {
        color: #4c5057;
        font-size: 12px;
        font-weight: bold;
      }

      &::-moz-placeholder {
        color: #4c5057;
        font-size: 12px;
        font-weight: bold;
      }

      &:-ms-input-placeholder {
        color: #4c5057;
        font-size: 12px;
        font-weight: bold;
      }

      &:-moz-placeholder {
        color: #4c5057;
        font-size: 12px;
        font-weight: bold;
      }

      &__focused {
        border-bottom-color: $primary-highlight-color;
      }
    }
  }
}

.SingleDatePicker {
  &_picker {
    font-family: 'Nunito Sans';
    border: 1px solid #e4e9f3;
    box-shadow: 0 2px 4px 0 #b6bac6;
    border-radius: 6px;
  }

  &Input {
    background-color: #fefeff;

    &__withBorder {
      border-radius: 4px;
      border: 1px solid #e4e9f3;
    }

    &_calendarIcon {
      padding: 6px 10px;

      &_svg {
        fill: #74809a;
        width: 20px;
        height: 20px;
      }
    }
  }

  .DateInput {
    border-radius: 4px;

    &_fang {
      transform: translateY(2px);

      .FormControlSingleDatePicker & {
        top: 48px !important;
      }
    }

    &_input {
      padding-top: 0;
      padding-bottom: 0;
      line-height: 24px;
      color: #74809a;
      border-radius: 4px;
      font-size: 13px;
      font-family: 'Nunito Sans';

      &__focused {
        border-bottom-color: transparent;
      }
    }
  }
}

.CalendarDay {
  font-size: 13px;

  &__default {
    color: $primary-light-color;

    &:hover {
      color: $primary-light-color;
    }
  }

  &__blocked_out_of_range {
    color: $primary-disabled-color;

    &:hover {
      color: $primary-disabled-color;
    }
  }

  &__hovered {
    &_span {
      &,
      &:hover {
        border-color: rgba(224, 224, 224, 1);
        background-color: #f5fbff;
      }
    }
  }

  &__selected {
    &,
    &:hover,
    &:active {
      background-color: $primary-highlight-color;
      border-color: $primary-highlight-color;
      color: #fff;
    }

    &_span {
      color: #040d37;
      border-color: rgba(224, 224, 224, 1);
      background-color: #f5fbff;

      &:active,
      &:hover {
        background-color: $primary-highlight-color;
        border-color: $primary-highlight-color;
      }
    }
  }
}

.DayPicker {
  font-family: 'Nunito Sans';

  &_weekHeader_ul {
    font-size: 12px;
  }
}

.CalendarMonth {
  &_caption {
    color: $primary-dark-color;
  }
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  &::before {
    border-right-color: $primary-highlight-color;
  }
}

.FormControlMultipleDatesPicker {
  .DayPicker__withBorder {
    box-shadow: none;
    border-radius: 0;
  }
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth > div > div.react-datepicker__input-container,
.customDatePickerWidth > div > div.react-datepicker__input-container input {
  width: 100%;
  height: 100%;
}

.react-datepicker {
  border: none !important;
  box-shadow: 0px 2px 4px #b6bac6;
}
.react-datepicker__header {
  background-color: #f5f6fa !important;
  border-bottom: 1px solid #e4e9f3 !important;
}
.react-datepicker__month-container {
  background-color: #fff !important;
  border-radius: 0.3em;
}

.react-datepicker__day {
  border-radius: 50% !important;
  transition: background 100ms cubic-bezier(0.4, 0, 0.2, 1),
    color 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: $primary-highlight-color !important;
  color: #fff;
}

.react-datepicker__month--selecting-range {
  color: #fff !important;
  .react-datepicker__day--in-range {
    color: #fff !important;
  }
}

.react-datepicker__input-container {
  width: inherit;
  height: inherit;
}
.react-datepicker__input-container input {
  width: inherit;
  height: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__month-container,
.react-datepicker__time-container {
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}
.react-datepicker__triangle {
  border-bottom-color: #f5f6fa !important;
  border-top-color: #f5f6fa !important;

  &::before {
    border-bottom-color: #f5f6fa !important;
    border-top-color: #f5f6fa !important;
  }
}
.DarkTheme {
  .react-datepicker {
    box-shadow: 0px 2px 4px #000;
  }
  .react-datepicker,
  .react-datepicker__time {
    background-color: #15263d !important;
    border-color: #0a1526;
  }

  .react-datepicker__header {
    background-color: #15263d !important;
    border-bottom: 1px solid #162949 !important;
  }
  .react-datepicker__month-container {
    background-color: #0a1526 !important;
  }
  .react-datepicker-time__header {
    color: #fff;
  }

  .react-datepicker__triangle {
    border-bottom-color: #15263d !important;
    border-top-color: #15263d !important;

    &::before {
      border-bottom-color: #15263d !important;
      border-top-color: #15263d !important;
    }
  }

  .react-datepicker__navigation {
    &--previous {
      border-right-color: $primary-light-color;

      &:hover {
        border-right-color: $highlight-color;
      }
    }

    &--next {
      border-left-color: $primary-light-color;

      &:hover {
        border-left-color: $highlight-color;
      }
    }
  }

  .react-datepicker__day-name {
    color: #9394a0;
  }

  .react-datepicker__day {
    color: #9394a0;

    &--selected,
    &--in-range {
      color: #fff !important;
      background-color: #216ba5 !important;
    }

    &--in-selecting-range {
      background-color: #216ba5 !important;
      color: #fff !important;
    }
  }

  .react-datepicker__time-list-item {
    color: #9394a0;

    &--selected {
      background-color: #15263d !important;
    }

    &:hover {
      background-color: #15263d !important;
    }
  }

  .react-datepicker__current-month {
    color: #fff;
  }
}

.SeparateFields {
  .DateRangePicker {
    width: 100%;
  }
  .DateRangePickerInput {
    border: none;

    .DateInput {
      border: 1px solid #dbdbdb;
      border-radius: 4px;
    }

    .DateRangePickerInput_arrow {
      background-color: transparent;
      padding: 0px 10px;
    }
  }

  .DateInput_fang {
    display: none;
  }
}

.DarkTheme .SeparateFields {
  .DateRangePickerInput {
    background: #15263d !important;
  }
  .DateInput {
    border-color: #1e3966 !important;
  }
}

body {
  & > div > .DateRangePicker_picker {
    top: unset !important;
  }

  &.social-network-setting-open > div > .DateRangePicker_picker {
    top: 40px !important;
  }
}
