//@font-face {
//  font-family: 'Evolve';
//  src: url('../../../../common/evo.otf') format('opentype');
//}
//
//@font-face {
//  font-family: 'Hazard';
//  src: url('../../../../common/haz.otf') format('opentype');
//}

.design-gallery {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  width: 100vw;
  overflow: hidden;
  height: calc(100vh - 80px - 16px);
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';

  .ml-auto {
    margin-left: auto;
  }

  .scroll-container {
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      margin-right: -5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c4cad9;
      border-radius: 2.5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: darken(#c4cad9, 5%);
    }
  }

  .TabToggleButtonGroup {
    &.full-width {
      width: 100%;

      .TabToggleButton {
        flex: 1;
        flex-shrink: 0;
        min-width: auto;
      }
    }

    .TabToggleButton {
      color: #5c697f;
      height: 32px;
      padding: 2px 10px 0;
      border-color: #d4dce7;
    }
  }
}

.SingleDatePicker_picker {
  .DayPicker_transitionContainer {
    max-height: 400px;
  }
}
