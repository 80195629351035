@import '../../base/colors';

/* Radial Out */
.hvr-radial-out {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  transition-property: color, background-color !important;
  transition-duration: 0.3s;
  border: 1px solid $highlight-color !important;
  color: $highlight-color !important;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    height: 140%;
    width: 140%;
    margin-top: calc(0.75em + 6px);
    padding-top: 125%;
    background-color: $highlight-color;
    border-radius: 50%;
    transform: translateY(-50%) scale(0);
    transition-property: transform, width, height;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  &--small {
    &:before {
      height: 42px;
      width: 42px;
      margin-top: calc(0.75em + 4px);
    }
  }

  svg,
  i {
    color: $highlight-color;
  }

  &:hover,
  &focus,
  &:active {
    &:before {
      transform: translateY(-50%) scale(1);
    }
  }

  &--primary {
    background-color: $highlight-color !important;
    border-color: $highlight-color !important;

    &:hover,
    &focus,
    &:active {
      color: $highlight-color !important;
      border-color: #d8deea;

      svg,
      i {
        color: $highlight-color !important;
      }
    }
  }
  &--danger {
    color: #e34843 !important;
    border-color: #e34843 !important;
    svg,
    i {
      color: #e34843;
    }

    &:before {
      background-color: #e34843;
    }
    &:hover,
    &focus,
    &:active {
      background: #e34843;
    }
  }
  &--info {
    color: $info-color !important;
    border-color: $info-color !important;
    svg,
    i {
      color: $info-color;
    }

    &:before {
      background-color: $info-color;
    }
    &:hover,
    &focus,
    &:active {
      background: $info-color;
    }
  }
  &--accent {
    color: $accent-color !important;
    border-color: $accent-color !important;
    svg,
    i {
      color: $accent-color;
    }

    &:before {
      background-color: $accent-color;
    }
    &:hover,
    &focus,
    &:active {
      background: $accent-color;
    }
  }
  &--accent-filled {
    background-color: $accent-color !important;
    border-color: $accent-color !important;

    &:hover,
    &focus,
    &:active {
      color: $accent-color !important;
      border-color: $accent-color;

      svg,
      i {
        color: $accent-color !important;
      }
    }
  }
}
