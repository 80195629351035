@font-face {
  font-family: 'custom-icons';
  src: url('../../../common/assets/fonts/CustomIcons/custom-icons.eot?kgn7t8');
  src: url('../../../common/assets/fonts/CustomIcons/custom-icons.eot?kgn7t8#iefix')
      format('embedded-opentype'),
    url('../../../common/assets/fonts/CustomIcons/custom-icons.ttf?kgn7t8')
      format('truetype'),
    url('../../../common/assets/fonts/CustomIcons/custom-icons.woff?kgn7t8')
      format('woff'),
    url('../../../common/assets/fonts/CustomIcons/custom-icons.svg?kgn7t8#custom-icons')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-youtube'],
[class*='icon-youtube'],
[class^='icon-google-drive'],
[class*='icon-google-drive'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'custom-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
  @return unquote('"') + unquote(str-insert($str, '\\', 1)) + unquote('"');
}

.DarkTheme {
  .icon-youtube:before {
    color: #fff;
  }
}

.icon-youtube:before {
  content: unicode('e901');
  color: #252323;
  font-size: 18px;
}
.icon-google-drive:before {
  content: unicode('e900');
  color: #757575;
}
