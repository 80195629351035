.Snackbar {
  pointer-events: all;
  margin-bottom: 8px;
  .customActionWidth {
    width: 448px;
  }
  & > div {
    max-width: 520px; //max 7-8 words
    flex-direction: row;
    padding: 16px;
    & > div:last-child {
      margin-right: 0px;
      padding-left: 0px;
    }
  }
  svg {
    width: 24px;
    height: 24px;
    margin-right: 9px;
    align-self: flex-start;
  }
  button {
    height: 32px;
    color: white;
    font-weight: bold;
    line-height: 32px;
    border: solid 1px #0001;
    box-shadow: 0 0 6px #0001;
  }
  #notistack-snackbar {
    overflow-wrap: anywhere;
    padding: 0px;
  }
}

#notistack-snackbar + div {
  padding-left: 16px;
}

.snackbarContainer {
  margin-top: 70px;
}

@media screen and (max-width: 600px) {
  .Snackbar {
    & > div {
      max-width: 300px;
    }
  }
}
