.design-gallery {
  .tabPane {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 20px;
      padding-top: 8px;
      padding-left: 16px;
      padding-right: 40px;
      position: relative;

      .tabPane {
        flex: 2;
      }

      &__close {
        position: absolute;
        top: 50%;
        right: -10px;
        margin-top: 4px;
        transform: translateY(-50%);
        margin-left: auto;
        color: #e8eaed;
        padding: 10px;

        svg {
          width: 20px;
          height: 20px;
          transition: 0.25s ease;
        }

        &:hover {
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }

    &-search {
      margin-bottom: 10px;
      flex-shrink: 0;
      display: flex;

      .search {
        flex-grow: 1;
      }
    }

    &-filter {
      display: flex;
      align-items: center;
    }

    &-tabs {
      margin-bottom: 10px;
    }

    &-content {
      flex-grow: 1;
    }
  }
}

.dark {
  .tabPane {
    &title {
      color: #1481ce;
    }
  }
}
