@font-face {
  font-family: 'Montserrat';
  src: url('montserrat-v26-latin-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('montserrat-v26-latin-700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('montserrat-v26-latin-300.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('montserrat-v26-latin-100.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}
