@charset "UTF-8";

@import "../../common/assets/fonts/Montserrat/stylesheet";

@font-face {
  font-family: 'Nunito Sans';
  src: url('../../common/assets/fonts/NunitoSans/NunitoSans-Regular.woff2')
  format('woff2'),
  url('../../common/assets/fonts/NunitoSans/NunitoSans-Regular.woff')
  format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../../common/assets/fonts/NunitoSans/NunitoSans-Bold.woff2')
  format('woff2'),
  url('../../common/assets/fonts/NunitoSans/NunitoSans-Bold.woff')
  format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../common/assets/fonts/OpenSans/OpenSans.woff2') format('woff2'),
  url('../../common/assets/fonts/OpenSans/OpenSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../../common/assets/fonts/Helvetica/Helvetica-Oblique.woff2')
  format('woff2'),
  url('../../common/assets/fonts/Helvetica/Helvetica-Oblique.woff')
  format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../../common/assets/fonts/Helvetica/Helvetica-Bold.woff2')
  format('woff2'),
  url('../../common/assets/fonts/Helvetica/Helvetica-Bold.woff')
  format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../../common/assets/fonts/Helvetica/Helvetica.woff2')
  format('woff2'),
  url('../../common/assets/fonts/Helvetica/Helvetica.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nova-icons';
  src: url('../../common/assets/fonts/nova-icons.eot?azv9s5');
  src: url('../../common/assets/fonts/nova-icons.eot?azv9s5#iefix')
  format('embedded-opentype'),
  url('../../common/assets/fonts/nova-icons.ttf?azv9s5') format('truetype'),
  url('../../common/assets/fonts/nova-icons.woff?azv9s5') format('woff'),
  url('../../common/assets/fonts/nova-icons.svg?azv9s5#nova-icons')
  format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'nova-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function unicode($str){
  @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"")
}

.icon-user-hierarchy1:before {
  content: unicode('e0a6');
}
.icon-search:before {
  content: unicode('e0a4');
}
.icon-file-statistic-close-2:before {
  content: unicode('e900');
}
.icon-wifi-signal-question-mark:before {
  content: unicode('e901');
}
.icon-eco-field:before {
  content: unicode('e902');
}
.icon-blackhole:before {
  content: unicode('e903');
}
.icon-socks:before {
  content: unicode('e904');
}
.icon-user-programming:before {
  content: unicode('e905');
}
.icon-religion-shield-2:before {
  content: unicode('e906');
}
.icon-t-shirt:before {
  content: unicode('e907');
}
.icon-underline:before {
  content: unicode('e908');
}
.icon-file-images-photos-1:before {
  content: unicode('e909');
}
.icon-shopping-cart-block:before {
  content: unicode('e90a');
}
.icon-focus-1:before {
  content: unicode('e90b');
}
.icon-expand-9:before {
  content: unicode('e90c');
}
.icon-sign-bitcoin:before {
  content: unicode('e90d');
}
.icon-sigma:before {
  content: unicode('e90e');
}
.icon-credit-card-share:before {
  content: unicode('e90f');
}
.icon-ladder:before {
  content: unicode('e910');
}
.icon-smiley-glasses-smile-1:before {
  content: unicode('e911');
}
.icon-computer-chip-4-core:before {
  content: unicode('e912');
}
.icon-astronaut-1:before {
  content: unicode('e913');
}
.icon-files-coding-box-ym:before {
  content: unicode('e914');
}
.icon-data-download-3:before {
  content: unicode('e915');
}
.icon-places-ziggurat:before {
  content: unicode('e916');
}
.icon-construction-fence:before {
  content: unicode('e917');
}
.icon-baby-crib:before {
  content: unicode('e918');
}
.icon-real-estate-sign-sale-1:before {
  content: unicode('e919');
}
.icon-file-statistic-settings-2:before {
  content: unicode('e91a');
}
.icon-location-globe-share:before {
  content: unicode('e91b');
}
.icon-beauty-makeup-box:before {
  content: unicode('e91c');
}
.icon-grid:before {
  content: unicode('e91d');
}
.icon-location-pin-restaurants-2:before {
  content: unicode('e91e');
}
.icon-euro-decreasing:before {
  content: unicode('e91f');
}
.icon-submarine:before {
  content: unicode('e920');
}
.icon-food-fried-egg:before {
  content: unicode('e921');
}
.icon-car-alert-signal:before {
  content: unicode('e922');
}
.icon-gender-male-male:before {
  content: unicode('e923');
}
.icon-cloudy-hail:before {
  content: unicode('e924');
}
.icon-file-audio-box-mp3:before {
  content: unicode('e925');
}
.icon-navigation-filter-4:before {
  content: unicode('e926');
}
.icon-building-barn:before {
  content: unicode('e927');
}
.icon-construction-truck-2:before {
  content: unicode('e928');
}
.icon-cloudy-thunder:before {
  content: unicode('e929');
}
.icon-grid-view:before {
  content: unicode('e92a');
}
.icon-food-icecream-4:before {
  content: unicode('e92b');
}
.icon-clock-fire:before {
  content: unicode('e92c');
}
.icon-vote-flag-3:before {
  content: unicode('e92d');
}
.icon-food-grape:before {
  content: unicode('e92e');
}
.icon-file-images-box-tiff:before {
  content: unicode('e92f');
}
.icon-file-images-favorite-heart:before {
  content: unicode('e930');
}
.icon-file-video-question-mark:before {
  content: unicode('e931');
}
.icon-chat-bubble-square-2:before {
  content: unicode('e932');
}
.icon-shopping-bag-settings:before {
  content: unicode('e933');
}
.icon-beauty-comb:before {
  content: unicode('e934');
}
.icon-cloud-harddisk-1:before {
  content: unicode('e935');
}
.icon-violin:before {
  content: unicode('e936');
}
.icon-wifi-signal-favorite-heart:before {
  content: unicode('e937');
}
.icon-programming-reel:before {
  content: unicode('e938');
}
.icon-file-audio-shield:before {
  content: unicode('e939');
}
.icon-car-signal:before {
  content: unicode('e93a');
}
.icon-select-area:before {
  content: unicode('e93b');
}
.icon-water-drop-check:before {
  content: unicode('e93c');
}
.icon-email-heart:before {
  content: unicode('e93d');
}
.icon-file-video-box-qt:before {
  content: unicode('e93e');
}
.icon-envelope-1:before {
  content: unicode('e93f');
}
.icon-pen-4:before {
  content: unicode('e940');
}
.icon-navigation-filter-10:before {
  content: unicode('e941');
}
.icon-vote-thumbs-up:before {
  content: unicode('e942');
}
.icon-move-down:before {
  content: unicode('e943');
}
.icon-email-add:before {
  content: unicode('e944');
}
.icon-vector-circle:before {
  content: unicode('e945');
}
.icon-id-card-2:before {
  content: unicode('e946');
}
.icon-security-footprint:before {
  content: unicode('e947');
}
.icon-nature-oxygen:before {
  content: unicode('e948');
}
.icon-shrink-vertical-3:before {
  content: unicode('e949');
}
.icon-cursor-select-area-1:before {
  content: unicode('e94a');
}
.icon-vote-plus-one:before {
  content: unicode('e94b');
}
.icon-tag-1:before {
  content: unicode('e94c');
}
.icon-eraser:before {
  content: unicode('e94d');
}
.icon-quote-opening:before {
  content: unicode('e94e');
}
.icon-transfer-computer-computer-2:before {
  content: unicode('e94f');
}
.icon-expand-horizontal-5:before {
  content: unicode('e950');
}
.icon-christmas-candle:before {
  content: unicode('e951');
}
.icon-mobile-phone-2:before {
  content: unicode('e952');
}
.icon-server-network-1:before {
  content: unicode('e953');
}
.icon-chat-bubble-circle-heart:before {
  content: unicode('e954');
}
.icon-vote-heart:before {
  content: unicode('e955');
}
.icon-network-business:before {
  content: unicode('e956');
}
.icon-banking-donation-2:before {
  content: unicode('e957');
}
.icon-banking-bill-reminder:before {
  content: unicode('e958');
}
.icon-space-ship-2:before {
  content: unicode('e959');
}
.icon-sign-barber-shop:before {
  content: unicode('e95a');
}
.icon-navigation-left-3:before {
  content: unicode('e95b');
}
.icon-disc-box:before {
  content: unicode('e95c');
}
.icon-cursor-click-1:before {
  content: unicode('e95d');
}
.icon-android-view:before {
  content: unicode('e95e');
}
.icon-transfer-mobile-satellite:before {
  content: unicode('e95f');
}
.icon-vector-line:before {
  content: unicode('e960');
}
.icon-bitcoin-price:before {
  content: unicode('e961');
}
.icon-arrow-down:before {
  content: unicode('e962');
}
.icon-radio-1:before {
  content: unicode('e963');
}
.icon-content-newspaper-1:before {
  content: unicode('e964');
}
.icon-road-1:before {
  content: unicode('e965');
}
.icon-tag-lock:before {
  content: unicode('e966');
}
.icon-eco-energy:before {
  content: unicode('e967');
}
.icon-content-book-add:before {
  content: unicode('e968');
}
.icon-calendar-alert:before {
  content: unicode('e969');
}
.icon-wifi-signal-timeout:before {
  content: unicode('e96a');
}
.icon-coin-star:before {
  content: unicode('e96b');
}
.icon-bell-boy:before {
  content: unicode('e96c');
}
.icon-interface-radioactive-circle:before {
  content: unicode('e96d');
}
.icon-kitchen-timer:before {
  content: unicode('e96e');
}
.icon-gesture-rotate-left-2:before {
  content: unicode('e96f');
}
.icon-book-listening:before {
  content: unicode('e970');
}
.icon-lamp-1:before {
  content: unicode('e971');
}
.icon-food-bread-loaf:before {
  content: unicode('e972');
}
.icon-computer-personal-1:before {
  content: unicode('e973');
}
.icon-flashlight:before {
  content: unicode('e974');
}
.icon-sport-rafting:before {
  content: unicode('e975');
}
.icon-window-view-1:before {
  content: unicode('e976');
}
.icon-real-estate-address-book-2:before {
  content: unicode('e977');
}
.icon-layout-2:before {
  content: unicode('e978');
}
.icon-synchronize-8:before {
  content: unicode('e979');
}
.icon-video-clip-4:before {
  content: unicode('e97a');
}
.icon-cloud-download-2:before {
  content: unicode('e97b');
}
.icon-expand-vertical-7:before {
  content: unicode('e97c');
}
.icon-cloud-lock:before {
  content: unicode('e97d');
}
.icon-save-nature-planet:before {
  content: unicode('e97e');
}
.icon-nature-carbon-dioxide-1:before {
  content: unicode('e97f');
}
.icon-file-notes-edit:before {
  content: unicode('e980');
}
.icon-transfer-files-computer:before {
  content: unicode('e981');
}
.icon-vote-heart-broken:before {
  content: unicode('e982');
}
.icon-camera-1:before {
  content: unicode('e983');
}
.icon-file-statistic-check-1:before {
  content: unicode('e984');
}
.icon-money-atm-2:before {
  content: unicode('e985');
}
.icon-speakers-2:before {
  content: unicode('e986');
}
.icon-location-globe-favorite-heart:before {
  content: unicode('e987');
}
.icon-plastic-glass:before {
  content: unicode('e988');
}
.icon-time-alert:before {
  content: unicode('e989');
}
.icon-smart-watch-square-line-graph:before {
  content: unicode('e98a');
}
.icon-tap-swipe-vertical-2:before {
  content: unicode('e98b');
}
.icon-abacus:before {
  content: unicode('e98c');
}
.icon-book-alphabet:before {
  content: unicode('e98d');
}
.icon-folder-upload:before {
  content: unicode('e98e');
}
.icon-move-up-4:before {
  content: unicode('e98f');
}
.icon-chat-bubble-circle-graph:before {
  content: unicode('e990');
}
.icon-chat-bubble-square-share:before {
  content: unicode('e991');
}
.icon-hat-magician:before {
  content: unicode('e992');
}
.icon-file-edit-1:before {
  content: unicode('e993');
}
.icon-credit-card-visa:before {
  content: unicode('e994');
}
.icon-diver:before {
  content: unicode('e995');
}
.icon-video-games-gameboy:before {
  content: unicode('e996');
}
.icon-health-medicine-bottle:before {
  content: unicode('e997');
}
.icon-user-group-close:before {
  content: unicode('e998');
}
.icon-thermometer-cold:before {
  content: unicode('e999');
}
.icon-content-book-home:before {
  content: unicode('e99a');
}
.icon-speakers-3:before {
  content: unicode('e99b');
}
.icon-shopping-bag-download:before {
  content: unicode('e99c');
}
.icon-real-estate-house-measurements:before {
  content: unicode('e99d');
}
.icon-travel-globe:before {
  content: unicode('e99e');
}
.icon-chat-bubble-square-question-mark:before {
  content: unicode('e99f');
}
.icon-bulldozer-2:before {
  content: unicode('e9a0');
}
.icon-time-bomb:before {
  content: unicode('e9a1');
}
.icon-expand-vertical-6:before {
  content: unicode('e9a2');
}
.icon-food-cherry:before {
  content: unicode('e9a3');
}
.icon-tag-timeout:before {
  content: unicode('e9a4');
}
.icon-circle-view:before {
  content: unicode('e9a5');
}
.icon-real-estate-subtract:before {
  content: unicode('e9a6');
}
.icon-layout-3:before {
  content: unicode('e9a7');
}
.icon-content-bookmark-1:before {
  content: unicode('e9a8');
}
.icon-yen-price:before {
  content: unicode('e9a9');
}
.icon-smiley-angry-2:before {
  content: unicode('e9aa');
}
.icon-programming-shield:before {
  content: unicode('e9ab');
}
.icon-romance-just-married-car:before {
  content: unicode('e9ac');
}
.icon-banking-transaction-stop-2:before {
  content: unicode('e9ad');
}
.icon-sparkling-wine-bucket:before {
  content: unicode('e9ae');
}
.icon-briefcase-2:before {
  content: unicode('e9af');
}
.icon-camera-box-2:before {
  content: unicode('e9b0');
}
.icon-content-books-4:before {
  content: unicode('e9b1');
}
.icon-two-finger-flick-up:before {
  content: unicode('e9b2');
}
.icon-stapler:before {
  content: unicode('e9b3');
}
.icon-tag-subtract:before {
  content: unicode('e9b4');
}
.icon-chat-bubble-circle-question-mark:before {
  content: unicode('e9b5');
}
.icon-switches:before {
  content: unicode('e9b6');
}
.icon-android-subtract:before {
  content: unicode('e9b7');
}
.icon-user-lightbulb-1:before {
  content: unicode('e9b8');
}
.icon-baby-apron:before {
  content: unicode('e9b9');
}
.icon-box-question-mark:before {
  content: unicode('e9ba');
}
.icon-location-pin-parking-2:before {
  content: unicode('e9bb');
}
.icon-window-information:before {
  content: unicode('e9bc');
}
.icon-save-planet-2:before {
  content: unicode('e9bd');
}
.icon-eco-barn:before {
  content: unicode('e9be');
}
.icon-car-siren-2:before {
  content: unicode('e9bf');
}
.icon-chat-bubble-circle-block:before {
  content: unicode('e9c0');
}
.icon-chat-bubble-square-favorite-heart:before {
  content: unicode('e9c1');
}
.icon-vote-heart-refresh:before {
  content: unicode('e9c2');
}
.icon-sport-jetskii:before {
  content: unicode('e9c3');
}
.icon-user-timeout:before {
  content: unicode('e9c4');
}
.icon-window-star:before {
  content: unicode('e9c5');
}
.icon-places-statue-of-liberty:before {
  content: unicode('e9c6');
}
.icon-banking-donation-3:before {
  content: unicode('e9c7');
}
.icon-interface-loading:before {
  content: unicode('e9c8');
}
.icon-tuktuk:before {
  content: unicode('e9c9');
}
.icon-navigation-left-2:before {
  content: unicode('e9ca');
}
.icon-satellite-signal:before {
  content: unicode('e9cb');
}
.icon-files-copyright-trademark:before {
  content: unicode('e9cc');
}
.icon-mobile-phone-3:before {
  content: unicode('e9cd');
}
.icon-tap-double-1:before {
  content: unicode('e9ce');
}
.icon-file-statistic-dollar-2:before {
  content: unicode('e9cf');
}
.icon-file-audio-favorite-heart:before {
  content: unicode('e9d0');
}
.icon-files-copyright-no-derivation:before {
  content: unicode('e9d1');
}
.icon-device-sync:before {
  content: unicode('e9d2');
}
.icon-expand-horizontal-4:before {
  content: unicode('e9d3');
}
.icon-eco-mind:before {
  content: unicode('e9d4');
}
.icon-location-pin-refresh:before {
  content: unicode('e9d5');
}
.icon-two-finger-flick-down:before {
  content: unicode('e9d6');
}
.icon-shrink-vertical-2:before {
  content: unicode('e9d7');
}
.icon-leisure-ticket-1:before {
  content: unicode('e9d8');
}
.icon-file-download-2:before {
  content: unicode('e9d9');
}
.icon-file-video-share:before {
  content: unicode('e9da');
}
.icon-places-map:before {
  content: unicode('e9db');
}
.icon-window-bookmark-1:before {
  content: unicode('e9dc');
}
.icon-file-statistic-edit-2:before {
  content: unicode('e9dd');
}
.icon-switch-left:before {
  content: unicode('e9de');
}
.icon-subscript:before {
  content: unicode('e9df');
}
.icon-camera-fast-forward:before {
  content: unicode('e9e0');
}
.icon-id-card-3:before {
  content: unicode('e9e1');
}
.icon-clothes-cap:before {
  content: unicode('e9e2');
}
.icon-gesture-zoom-out-1:before {
  content: unicode('e9e3');
}
.icon-chat-bubble-circle-subtract:before {
  content: unicode('e9e4');
}
.icon-network-close:before {
  content: unicode('e9e5');
}
.icon-books-apple:before {
  content: unicode('e9e6');
}
.icon-leisure-card-spades:before {
  content: unicode('e9e7');
}
.icon-coffee-paper-glass:before {
  content: unicode('e9e8');
}
.icon-vote-flag-2:before {
  content: unicode('e9e9');
}
.icon-wanted-poster:before {
  content: unicode('e9ea');
}
.icon-chat-bubble-square-3:before {
  content: unicode('e9eb');
}
.icon-hurricane-1:before {
  content: unicode('e9ec');
}
.icon-glasses-1:before {
  content: unicode('e9ed');
}
.icon-shopping-basket-lock:before {
  content: unicode('e9ee');
}
.icon-navigation-filter-5:before {
  content: unicode('e9ef');
}
.icon-food-icecream-5:before {
  content: unicode('e9f0');
}
.icon-shear-left:before {
  content: unicode('e9f1');
}
.icon-scissors:before {
  content: unicode('e9f2');
}
.icon-content-filter:before {
  content: unicode('e9f3');
}
.icon-network-mobile:before {
  content: unicode('e9f4');
}
.icon-box-shipping-truck:before {
  content: unicode('e9f5');
}
.icon-file-statistic-shield-1:before {
  content: unicode('e9f6');
}
.icon-email-block:before {
  content: unicode('e9f7');
}
.icon-direction-button-1:before {
  content: unicode('e9f8');
}
.icon-shopping-basket-check:before {
  content: unicode('e9f9');
}
.icon-files-coding-favorite-star-1:before {
  content: unicode('e9fa');
}
.icon-bank-note:before {
  content: unicode('e9fb');
}
.icon-health-medical-shield:before {
  content: unicode('e9fc');
}
.icon-location-pin-information-1:before {
  content: unicode('e9fd');
}
.icon-folder-bookmark:before {
  content: unicode('e9fe');
}
.icon-navigation-right-1:before {
  content: unicode('e9ff');
}
.icon-backhoe-loader:before {
  content: unicode('ea00');
}
.icon-file-audio-question-mark:before {
  content: unicode('ea01');
}
.icon-data-checker-flag-2:before {
  content: unicode('ea02');
}
.icon-network-favorite-star:before {
  content: unicode('ea03');
}
.icon-fire-lighter:before {
  content: unicode('ea04');
}
.icon-floppydisk:before {
  content: unicode('ea05');
}
.icon-camera-timer-0-second:before {
  content: unicode('ea06');
}
.icon-data-download-2:before {
  content: unicode('ea07');
}
.icon-files-coding-xml:before {
  content: unicode('ea08');
}
.icon-share-signal-tower:before {
  content: unicode('ea09');
}
.icon-boat-sail:before {
  content: unicode('ea0a');
}
.icon-location-pin-flash-1:before {
  content: unicode('ea0b');
}
.icon-spy:before {
  content: unicode('ea0c');
}
.icon-location-globe-mail:before {
  content: unicode('ea0d');
}
.icon-car-settings:before {
  content: unicode('ea0e');
}
.icon-children-slider:before {
  content: unicode('ea0f');
}
.icon-expand-8:before {
  content: unicode('ea10');
}
.icon-smiley-shy-1:before {
  content: unicode('ea11');
}
.icon-leisure-rest:before {
  content: unicode('ea12');
}
.icon-folder-swap:before {
  content: unicode('ea13');
}
.icon-flick-vertical-2:before {
  content: unicode('ea14');
}
.icon-file-images-upload:before {
  content: unicode('ea15');
}
.icon-car-clean:before {
  content: unicode('ea16');
}
.icon-symbol-bluetooth:before {
  content: unicode('ea17');
}
.icon-religion-shield-1:before {
  content: unicode('ea18');
}
.icon-space-probe:before {
  content: unicode('ea19');
}
.icon-symbol-wifi:before {
  content: unicode('ea1a');
}
.icon-file-images-photos-2:before {
  content: unicode('ea1b');
}
.icon-two-finger-flick-right-1:before {
  content: unicode('ea1c');
}
.icon-video-call-conference:before {
  content: unicode('ea1d');
}
.icon-real-estate-star:before {
  content: unicode('ea1e');
}
.icon-close-shield:before {
  content: unicode('ea1f');
}
.icon-shopping-trolley-download:before {
  content: unicode('ea20');
}
.icon-file-statistic-close-1:before {
  content: unicode('ea21');
}
.icon-content-view-agenda:before {
  content: unicode('ea22');
}
.icon-beaker-boiling:before {
  content: unicode('ea23');
}
.icon-android-information:before {
  content: unicode('ea24');
}
.icon-whiskey-bottle-glass:before {
  content: unicode('ea25');
}
.icon-smiley-glasses-smile-2:before {
  content: unicode('ea26');
}
.icon-moon-waning-crescent:before {
  content: unicode('ea27');
}
.icon-shopping-bag-timeout:before {
  content: unicode('ea28');
}
.icon-file-audio-box-mid:before {
  content: unicode('ea29');
}
.icon-focus-2:before {
  content: unicode('ea2a');
}
.icon-grid-subtract:before {
  content: unicode('ea2b');
}
.icon-clapboard-fast-forward:before {
  content: unicode('ea2c');
}
.icon-wifi-signal-download:before {
  content: unicode('ea2d');
}
.icon-real-estate-sign-sale-2:before {
  content: unicode('ea2e');
}
.icon-file-statistic-settings-1:before {
  content: unicode('ea2f');
}
.icon-time-favorite-star:before {
  content: unicode('ea30');
}
.icon-spray-paint:before {
  content: unicode('ea31');
}
.icon-navigation-right-3:before {
  content: unicode('ea32');
}
.icon-hotel-3-stars:before {
  content: unicode('ea33');
}
.icon-location-globe-upload:before {
  content: unicode('ea34');
}
.icon-android-upload:before {
  content: unicode('ea35');
}
.icon-color-contrast-on:before {
  content: unicode('ea36');
}
.icon-location-pin-restaurants-1:before {
  content: unicode('ea37');
}
.icon-romance-relationship:before {
  content: unicode('ea38');
}
.icon-bitcoin-laptop:before {
  content: unicode('ea39');
}
.icon-mobile-phone-refresh:before {
  content: unicode('ea3a');
}
.icon-hourglass:before {
  content: unicode('ea3b');
}
.icon-paragraph-right-to-left:before {
  content: unicode('ea3c');
}
.icon-android-sync:before {
  content: unicode('ea3d');
}
.icon-file-zipped-new:before {
  content: unicode('ea3e');
}
.icon-file-design-box-dwg:before {
  content: unicode('ea3f');
}
.icon-file-tasks-check:before {
  content: unicode('ea40');
}
.icon-car-parking:before {
  content: unicode('ea41');
}
.icon-user-close:before {
  content: unicode('ea42');
}
.icon-smart-watch-circle-location:before {
  content: unicode('ea43');
}
.icon-real-estate-share:before {
  content: unicode('ea44');
}
.icon-chat-bubble-square-1:before {
  content: unicode('ea45');
}
.icon-bullets:before {
  content: unicode('ea46');
}
.icon-audio-control-next:before {
  content: unicode('ea47');
}
.icon-smart-watch-square-alarm:before {
  content: unicode('ea48');
}
.icon-register-machine:before {
  content: unicode('ea49');
}
.icon-building-9:before {
  content: unicode('ea4a');
}
.icon-bookmark-document:before {
  content: unicode('ea4b');
}
.icon-user-clipboard:before {
  content: unicode('ea4c');
}
.icon-navigation-filter-7:before {
  content: unicode('ea4d');
}
.icon-glasses-3:before {
  content: unicode('ea4e');
}
.icon-wifi-signal-home:before {
  content: unicode('ea4f');
}
.icon-construction-truck-1:before {
  content: unicode('ea50');
}
.icon-food-stand:before {
  content: unicode('ea51');
}
.icon-mobile-phone-record:before {
  content: unicode('ea52');
}
.icon-credit-card-refresh:before {
  content: unicode('ea53');
}
.icon-cloud-harddisk-2:before {
  content: unicode('ea54');
}
.icon-grid-alert:before {
  content: unicode('ea55');
}
.icon-health-sperm:before {
  content: unicode('ea56');
}
.icon-navigation-filter-text:before {
  content: unicode('ea57');
}
.icon-mobile-phone-subtract:before {
  content: unicode('ea58');
}
.icon-inbox-skull:before {
  content: unicode('ea59');
}
.icon-expand-10:before {
  content: unicode('ea5a');
}
.icon-id-card-1:before {
  content: unicode('ea5b');
}
.icon-data-transfer-network:before {
  content: unicode('ea5c');
}
.icon-envelope-2:before {
  content: unicode('ea5d');
}
.icon-eco-watering-can:before {
  content: unicode('ea5e');
}
.icon-file-audio-download:before {
  content: unicode('ea5f');
}
.icon-transfer-computer-computer-1:before {
  content: unicode('ea60');
}
.icon-expand-horizontal-6:before {
  content: unicode('ea61');
}
.icon-drought:before {
  content: unicode('ea62');
}
.icon-tag-2:before {
  content: unicode('ea63');
}
.icon-leisure-ticket-3:before {
  content: unicode('ea64');
}
.icon-cursor-select-area-2:before {
  content: unicode('ea65');
}
.icon-space-ship-1:before {
  content: unicode('ea66');
}
.icon-organic-tea:before {
  content: unicode('ea67');
}
.icon-clapboard-stop:before {
  content: unicode('ea68');
}
.icon-banking-donation-1:before {
  content: unicode('ea69');
}
.icon-shopping-basket-download:before {
  content: unicode('ea6a');
}
.icon-sunrise:before {
  content: unicode('ea6b');
}
.icon-moonset:before {
  content: unicode('ea6c');
}
.icon-real-estate-percent:before {
  content: unicode('ea6d');
}
.icon-computer-fan:before {
  content: unicode('ea6e');
}
.icon-mobile-phone-1:before {
  content: unicode('ea6f');
}
.icon-server-network-2:before {
  content: unicode('ea70');
}
.icon-vote-heart-check:before {
  content: unicode('ea71');
}
.icon-sign-smoking:before {
  content: unicode('ea72');
}
.icon-toolbox:before {
  content: unicode('ea73');
}
.icon-signal-heart:before {
  content: unicode('ea74');
}
.icon-places-chedi:before {
  content: unicode('ea75');
}
.icon-money-note:before {
  content: unicode('ea76');
}
.icon-cloudy-hurricane:before {
  content: unicode('ea77');
}
.icon-cursor-click-2:before {
  content: unicode('ea78');
}
.icon-music-album-block:before {
  content: unicode('ea79');
}
.icon-grid-sync:before {
  content: unicode('ea7a');
}
.icon-window-error-404:before {
  content: unicode('ea7b');
}
.icon-sd-card-subtract:before {
  content: unicode('ea7c');
}
.icon-copy-paste:before {
  content: unicode('ea7d');
}
.icon-pet-dog:before {
  content: unicode('ea7e');
}
.icon-romance-lock-heart:before {
  content: unicode('ea7f');
}
.icon-radio-2:before {
  content: unicode('ea80');
}
.icon-content-newspaper-2:before {
  content: unicode('ea81');
}
.icon-road-2:before {
  content: unicode('ea82');
}
.icon-window-view-2:before {
  content: unicode('ea83');
}
.icon-interface-information:before {
  content: unicode('ea84');
}
.icon-romance-heart-wand:before {
  content: unicode('ea85');
}
.icon-computer-personal-2:before {
  content: unicode('ea86');
}
.icon-gesture-rotate-left-1:before {
  content: unicode('ea87');
}
.icon-christmas-shooting-star:before {
  content: unicode('ea88');
}
.icon-window-subtract:before {
  content: unicode('ea89');
}
.icon-file-design-cone:before {
  content: unicode('ea8a');
}
.icon-lamp-2:before {
  content: unicode('ea8b');
}
.icon-window-edit:before {
  content: unicode('ea8c');
}
.icon-cloud-download-1:before {
  content: unicode('ea8d');
}
.icon-expand-vertical-4:before {
  content: unicode('ea8e');
}
.icon-move-right-4:before {
  content: unicode('ea8f');
}
.icon-file-zipped-upload:before {
  content: unicode('ea90');
}
.icon-user-question-mark:before {
  content: unicode('ea91');
}
.icon-construction-helmet:before {
  content: unicode('ea92');
}
.icon-content-bookmark-3:before {
  content: unicode('ea93');
}
.icon-real-estate-address-book-1:before {
  content: unicode('ea94');
}
.icon-layout-1:before {
  content: unicode('ea95');
}
.icon-user-subtract:before {
  content: unicode('ea96');
}
.icon-leisure-clown:before {
  content: unicode('ea97');
}
.icon-food-cheese:before {
  content: unicode('ea98');
}
.icon-translate:before {
  content: unicode('ea99');
}
.icon-money-atm-1:before {
  content: unicode('ea9a');
}
.icon-speakers-1:before {
  content: unicode('ea9b');
}
.icon-network-add:before {
  content: unicode('ea9c');
}
.icon-camera-2:before {
  content: unicode('ea9d');
}
.icon-file-statistic-check-2:before {
  content: unicode('ea9e');
}
.icon-file-audio-4:before {
  content: unicode('ea9f');
}
.icon-nature-carbon-dioxide-2:before {
  content: unicode('eaa0');
}
.icon-shield-fire:before {
  content: unicode('eaa1');
}
.icon-user-group-conversation:before {
  content: unicode('eaa2');
}
.icon-tap-swipe-vertical-1:before {
  content: unicode('eaa3');
}
.icon-beauty-cream:before {
  content: unicode('eaa4');
}
.icon-camera-mobile-front:before {
  content: unicode('eaa5');
}
.icon-travel-sun-bathing:before {
  content: unicode('eaa6');
}
.icon-romance-love-protect:before {
  content: unicode('eaa7');
}
.icon-camera-e:before {
  content: unicode('eaa8');
}
.icon-vote-heart-add:before {
  content: unicode('eaa9');
}
.icon-smart-watch-signal:before {
  content: unicode('eaaa');
}
.icon-android-check:before {
  content: unicode('eaab');
}
.icon-car-block:before {
  content: unicode('eaac');
}
.icon-network-timeout:before {
  content: unicode('eaad');
}
.icon-food-cupcake:before {
  content: unicode('eaae');
}
.icon-projector-screen-programming:before {
  content: unicode('eaaf');
}
.icon-box-share:before {
  content: unicode('eab0');
}
.icon-file-edit-2:before {
  content: unicode('eab1');
}
.icon-pet-lost:before {
  content: unicode('eab2');
}
.icon-camera-3:before {
  content: unicode('eab3');
}
.icon-amperemeter:before {
  content: unicode('eab4');
}
.icon-smart-watch-circle-audio:before {
  content: unicode('eab5');
}
.icon-bulldozer-1:before {
  content: unicode('eab6');
}
.icon-high-voltage-electric-tower:before {
  content: unicode('eab7');
}
.icon-flash-tripod:before {
  content: unicode('eab8');
}
.icon-file-tasks-home:before {
  content: unicode('eab9');
}
.icon-smart-watch-square-heart:before {
  content: unicode('eaba');
}
.icon-content-bookmark-2:before {
  content: unicode('eabb');
}
.icon-smiley-angry-1:before {
  content: unicode('eabc');
}
.icon-treasure-chest-open:before {
  content: unicode('eabd');
}
.icon-location-pin-off:before {
  content: unicode('eabe');
}
.icon-fax-machine:before {
  content: unicode('eabf');
}
.icon-expand-vertical-5:before {
  content: unicode('eac0');
}
.icon-user:before {
  content: unicode('eac1');
}
.icon-sign-dollar:before {
  content: unicode('eac2');
}
.icon-user-group-upload:before {
  content: unicode('eac3');
}
.icon-lamp-3:before {
  content: unicode('eac4');
}
.icon-smart-watch-circle-clock:before {
  content: unicode('eac5');
}
.icon-smiley-poker-face:before {
  content: unicode('eac6');
}
.icon-highlight:before {
  content: unicode('eac7');
}
.icon-music-album-lock:before {
  content: unicode('eac8');
}
.icon-briefcase-1:before {
  content: unicode('eac9');
}
.icon-camera-box-1:before {
  content: unicode('eaca');
}
.icon-sd-card-question-mark:before {
  content: unicode('eacb');
}
.icon-mobile-phone-home:before {
  content: unicode('eacc');
}
.icon-banking-transaction-stop-1:before {
  content: unicode('eacd');
}
.icon-location-pin-parking-1:before {
  content: unicode('eace');
}
.icon-camera-stop:before {
  content: unicode('eacf');
}
.icon-save-planet-1:before {
  content: unicode('ead0');
}
.icon-food-pumpkin:before {
  content: unicode('ead1');
}
.icon-window-reduce-down:before {
  content: unicode('ead2');
}
.icon-email-favorite-star:before {
  content: unicode('ead3');
}
.icon-radio-3:before {
  content: unicode('ead4');
}
.icon-world-wide-web:before {
  content: unicode('ead5');
}
.icon-user-lightbulb-2:before {
  content: unicode('ead6');
}
.icon-credit-card-favorite-star:before {
  content: unicode('ead7');
}
.icon-education-online:before {
  content: unicode('ead8');
}
.icon-pet-bubble:before {
  content: unicode('ead9');
}
.icon-file-video-box-m4v:before {
  content: unicode('eada');
}
.icon-sign-transfer:before {
  content: unicode('eadb');
}
.icon-car-siren-1:before {
  content: unicode('eadc');
}
.icon-construction-hook:before {
  content: unicode('eadd');
}
.icon-tap-double-2:before {
  content: unicode('eade');
}
.icon-align-portrait:before {
  content: unicode('eadf');
}
.icon-file-statistic-dollar-1:before {
  content: unicode('eae0');
}
.icon-navigation-left-1:before {
  content: unicode('eae1');
}
.icon-file-video-box-mov:before {
  content: unicode('eae2');
}
.icon-audio-control-record:before {
  content: unicode('eae3');
}
.icon-location-globe-lock:before {
  content: unicode('eae4');
}
.icon-wifi-signal-settings:before {
  content: unicode('eae5');
}
.icon-fragile:before {
  content: unicode('eae6');
}
.icon-window-bookmark-2:before {
  content: unicode('eae7');
}
.icon-file-statistic-edit-1:before {
  content: unicode('eae8');
}
.icon-clothes-button:before {
  content: unicode('eae9');
}
.icon-shrink-vertical-1:before {
  content: unicode('eaea');
}
.icon-leisure-ticket-2:before {
  content: unicode('eaeb');
}
.icon-file-download-1:before {
  content: unicode('eaec');
}
.icon-expand-horizontal-7:before {
  content: unicode('eaed');
}
.icon-sign-ramp-left:before {
  content: unicode('eaee');
}
.icon-shopping-basket-timeout:before {
  content: unicode('eaef');
}
.icon-symbol-usb:before {
  content: unicode('eaf0');
}
.icon-scale-vertical:before {
  content: unicode('eaf1');
}
.icon-hotel-heater:before {
  content: unicode('eaf2');
}
.icon-user-home-1:before {
  content: unicode('eaf3');
}
.icon-envelope-3:before {
  content: unicode('eaf4');
}
.icon-file-audio-check:before {
  content: unicode('eaf5');
}
.icon-night-clear-sky:before {
  content: unicode('eaf6');
}
.icon-religion-egypt-eye-pyramid:before {
  content: unicode('eaf7');
}
.icon-typewriter:before {
  content: unicode('eaf8');
}
.icon-tools-handdrill:before {
  content: unicode('eaf9');
}
.icon-phone-record:before {
  content: unicode('eafa');
}
.icon-user-male:before {
  content: unicode('eafb');
}
.icon-gesture-zoom-out-2:before {
  content: unicode('eafc');
}
.icon-folder-check:before {
  content: unicode('eafd');
}
.icon-file-images-alert:before {
  content: unicode('eafe');
}
.icon-cloud-downloading-complete:before {
  content: unicode('eaff');
}
.icon-file-statistic-shield-2:before {
  content: unicode('eb00');
}
.icon-glasses-2:before {
  content: unicode('eb01');
}
.icon-files-coding-box-exe:before {
  content: unicode('eb02');
}
.icon-navigation-filter-6:before {
  content: unicode('eb03');
}
.icon-building-8:before {
  content: unicode('eb04');
}
.icon-vote-heart-home:before {
  content: unicode('eb05');
}
.icon-android:before {
  content: unicode('eb06');
}
.icon-inbox-dollar:before {
  content: unicode('eb07');
}
.icon-hurricane-2:before {
  content: unicode('eb08');
}
.icon-compass-4:before {
  content: unicode('eb09');
}
.icon-vote-flag-1:before {
  content: unicode('eb0a');
}
.icon-nature-cactus:before {
  content: unicode('eb0b');
}
.icon-location-pin-information-2:before {
  content: unicode('eb0c');
}
.icon-files-coding-favorite-star-2:before {
  content: unicode('eb0d');
}
.icon-day-cloudy:before {
  content: unicode('eb0e');
}
.icon-road-pin:before {
  content: unicode('eb0f');
}
.icon-direction-button-2:before {
  content: unicode('eb10');
}
.icon-shrink-4:before {
  content: unicode('eb11');
}
.icon-data-checker-flag-1:before {
  content: unicode('eb12');
}
.icon-flash-bracket:before {
  content: unicode('eb13');
}
.icon-navigation-right-2:before {
  content: unicode('eb14');
}
.icon-file-audio-settings:before {
  content: unicode('eb15');
}
.icon-file-video-information:before {
  content: unicode('eb16');
}
.icon-circle:before {
  content: unicode('eb17');
}
.icon-beauty-toothbrush:before {
  content: unicode('eb18');
}
.icon-high-heels:before {
  content: unicode('eb19');
}
.icon-shopping-basket-settings:before {
  content: unicode('eb1a');
}
.icon-smart-watch-circle-phone:before {
  content: unicode('eb1b');
}
.icon-data-download-1:before {
  content: unicode('eb1c');
}
.icon-math-board:before {
  content: unicode('eb1d');
}
.icon-email-subtract:before {
  content: unicode('eb1e');
}
.icon-eco-house:before {
  content: unicode('eb1f');
}
.icon-focus-3:before {
  content: unicode('eb20');
}
.icon-kitchen-fork-knife:before {
  content: unicode('eb21');
}
.icon-location-pin-flash-2:before {
  content: unicode('eb22');
}
.icon-smiley-glasses-smile-3:before {
  content: unicode('eb23');
}
.icon-network-laptop:before {
  content: unicode('eb24');
}
.icon-file-audio-timeout:before {
  content: unicode('eb25');
}
.icon-graduation-hat:before {
  content: unicode('eb26');
}
.icon-flick-vertical-1:before {
  content: unicode('eb27');
}
.icon-nuclear-transportation:before {
  content: unicode('eb28');
}
.icon-smiley-shy-2:before {
  content: unicode('eb29');
}
.icon-sport-american-football:before {
  content: unicode('eb2a');
}
.icon-real-estate-sign-for-rent:before {
  content: unicode('eb2b');
}
.icon-doctor:before {
  content: unicode('eb2c');
}
.icon-programming-flow-chart:before {
  content: unicode('eb2d');
}
.icon-shear-right:before {
  content: unicode('eb2e');
}
.icon-safe:before {
  content: unicode('eb2f');
}
.icon-food-soup:before {
  content: unicode('eb30');
}
.icon-time-favorite-heart:before {
  content: unicode('eb31');
}
.icon-cc-camera-2:before {
  content: unicode('eb32');
}
.icon-travel-beach:before {
  content: unicode('eb33');
}
.icon-international-dollar:before {
  content: unicode('eb34');
}
.icon-sd-card-check:before {
  content: unicode('eb35');
}
.icon-sign-this-way-up:before {
  content: unicode('eb36');
}
.icon-spelling-check-1:before {
  content: unicode('eb37');
}
.icon-file-zipped-check:before {
  content: unicode('eb38');
}
.icon-nature-flower-2:before {
  content: unicode('eb39');
}
.icon-shopping-bag-favorite-heart:before {
  content: unicode('eb3a');
}
.icon-leisure-mask-happy:before {
  content: unicode('eb3b');
}
.icon-boat-canoe:before {
  content: unicode('eb3c');
}
.icon-road-right-turn:before {
  content: unicode('eb3d');
}
.icon-phone-pending:before {
  content: unicode('eb3e');
}
.icon-file-office-xls:before {
  content: unicode('eb3f');
}
.icon-alarm-close:before {
  content: unicode('eb40');
}
.icon-romance-love-message:before {
  content: unicode('eb41');
}
.icon-location-map-pin:before {
  content: unicode('eb42');
}
.icon-handbag:before {
  content: unicode('eb43');
}
.icon-shopping-cart-favorite-heart:before {
  content: unicode('eb44');
}
.icon-network-view:before {
  content: unicode('eb45');
}
.icon-files-coding-view-1:before {
  content: unicode('eb46');
}
.icon-two-finger-tap-double-2:before {
  content: unicode('eb47');
}
.icon-data-download-5:before {
  content: unicode('eb48');
}
.icon-files-coding-box-bin:before {
  content: unicode('eb49');
}
.icon-file-video-download:before {
  content: unicode('eb4a');
}
.icon-add-tab:before {
  content: unicode('eb4b');
}
.icon-television:before {
  content: unicode('eb4c');
}
.icon-vote-flag-5:before {
  content: unicode('eb4d');
}
.icon-dollar-circle:before {
  content: unicode('eb4e');
}
.icon-hotel-hair-dryer:before {
  content: unicode('eb4f');
}
.icon-chat-bubble-square-4:before {
  content: unicode('eb50');
}
.icon-hotel-pets-allow:before {
  content: unicode('eb51');
}
.icon-real-estate-heart:before {
  content: unicode('eb52');
}
.icon-chat-bubble-circle-timeout:before {
  content: unicode('eb53');
}
.icon-navigation-filter-2:before {
  content: unicode('eb54');
}
.icon-file-audio-home:before {
  content: unicode('eb55');
}
.icon-glasses-6:before {
  content: unicode('eb56');
}
.icon-server-upload-2:before {
  content: unicode('eb57');
}
.icon-turntable:before {
  content: unicode('eb58');
}
.icon-smart-watch-square-wireless-signal:before {
  content: unicode('eb59');
}
.icon-food-icecream-2:before {
  content: unicode('eb5a');
}
.icon-shopping-basket-upload:before {
  content: unicode('eb5b');
}
.icon-smart-watch-square-phone:before {
  content: unicode('eb5c');
}
.icon-file-zipped-favorite-heart:before {
  content: unicode('eb5d');
}
.icon-location-pin-airport-2:before {
  content: unicode('eb5e');
}
.icon-shopping-bag-edit:before {
  content: unicode('eb5f');
}
.icon-file-statistic-subtract-1:before {
  content: unicode('eb60');
}
.icon-leisure-slot-machine:before {
  content: unicode('eb61');
}
.icon-sport-bicycle:before {
  content: unicode('eb62');
}
.icon-drum-1:before {
  content: unicode('eb63');
}
.icon-business-graph-bar-status:before {
  content: unicode('eb64');
}
.icon-pen-2:before {
  content: unicode('eb65');
}
.icon-arrow-up-left:before {
  content: unicode('eb66');
}
.icon-tap-swipe-right-1:before {
  content: unicode('eb67');
}
.icon-shopping-cart-2:before {
  content: unicode('eb68');
}
.icon-calendar-close-1:before {
  content: unicode('eb69');
}
.icon-tag-question-mark:before {
  content: unicode('eb6a');
}
.icon-window-skull:before {
  content: unicode('eb6b');
}
.icon-vote-heart-upload:before {
  content: unicode('eb6c');
}
.icon-color-palette:before {
  content: unicode('eb6d');
}
.icon-file-images-refresh:before {
  content: unicode('eb6e');
}
.icon-arrow-enter:before {
  content: unicode('eb6f');
}
.icon-quill:before {
  content: unicode('eb70');
}
.icon-motorcycle-1:before {
  content: unicode('eb71');
}
.icon-bicycle-2:before {
  content: unicode('eb72');
}
.icon-backpack:before {
  content: unicode('eb73');
}
.icon-email-dollar-1:before {
  content: unicode('eb74');
}
.icon-expand-horizontal-3:before {
  content: unicode('eb75');
}
.icon-sign-slippery-floor:before {
  content: unicode('eb76');
}
.icon-smart-watch-square-audio:before {
  content: unicode('eb77');
}
.icon-sd-card-view:before {
  content: unicode('eb78');
}
.icon-calendar-check-2:before {
  content: unicode('eb79');
}
.icon-computer-chip-2:before {
  content: unicode('eb7a');
}
.icon-transfer-camera-laptop:before {
  content: unicode('eb7b');
}
.icon-content-view-column:before {
  content: unicode('eb7c');
}
.icon-international-bitcoin:before {
  content: unicode('eb7d');
}
.icon-location-pin-subway-station-1:before {
  content: unicode('eb7e');
}
.icon-cloud-check:before {
  content: unicode('eb7f');
}
.icon-navigation-before-2:before {
  content: unicode('eb80');
}
.icon-nature-honey-bee:before {
  content: unicode('eb81');
}
.icon-calendar-home:before {
  content: unicode('eb82');
}
.icon-smart-watch-circle-heart:before {
  content: unicode('eb83');
}
.icon-hotel-car-lock:before {
  content: unicode('eb84');
}
.icon-tag-shield:before {
  content: unicode('eb85');
}
.icon-real-estate-sign-rent-1:before {
  content: unicode('eb86');
}
.icon-cloud-downloading-fail:before {
  content: unicode('eb87');
}
.icon-ring-planet:before {
  content: unicode('eb88');
}
.icon-camera-pin:before {
  content: unicode('eb89');
}
.icon-pin-2:before {
  content: unicode('eb8a');
}
.icon-mouse-wireless:before {
  content: unicode('eb8b');
}
.icon-android-favorite-heart:before {
  content: unicode('eb8c');
}
.icon-smart-watch-square-clock:before {
  content: unicode('eb8d');
}
.icon-kitchen-rice-cooker:before {
  content: unicode('eb8e');
}
.icon-smiley-sigh-1:before {
  content: unicode('eb8f');
}
.icon-clothes-bra:before {
  content: unicode('eb90');
}
.icon-eco-throw-trash:before {
  content: unicode('eb91');
}
.icon-sign-spa-2:before {
  content: unicode('eb92');
}
.icon-expand-vertical-1:before {
  content: unicode('eb93');
}
.icon-move-right-1:before {
  content: unicode('eb94');
}
.icon-credit-card-shield:before {
  content: unicode('eb95');
}
.icon-layout-4:before {
  content: unicode('eb96');
}
.icon-video-clip-2:before {
  content: unicode('eb97');
}
.icon-religion-judaism:before {
  content: unicode('eb98');
}
.icon-arrange-9-to-1:before {
  content: unicode('eb99');
}
.icon-baby-trolley:before {
  content: unicode('eb9a');
}
.icon-location-globe-settings:before {
  content: unicode('eb9b');
}
.icon-hdr-on:before {
  content: unicode('eb9c');
}
.icon-user-group-question-mark:before {
  content: unicode('eb9d');
}
.icon-content-book-shield:before {
  content: unicode('eb9e');
}
.icon-content-books-3:before {
  content: unicode('eb9f');
}
.icon-keyboard-key-escape:before {
  content: unicode('eba0');
}
.icon-health-scissors:before {
  content: unicode('eba1');
}
.icon-truck-tow:before {
  content: unicode('eba2');
}
.icon-box-block:before {
  content: unicode('eba3');
}
.icon-data-upload-8:before {
  content: unicode('eba4');
}
.icon-food-fish:before {
  content: unicode('eba5');
}
.icon-cloudy-rain:before {
  content: unicode('eba6');
}
.icon-food-strawberry:before {
  content: unicode('eba7');
}
.icon-email-refresh:before {
  content: unicode('eba8');
}
.icon-door-house:before {
  content: unicode('eba9');
}
.icon-move-up-3:before {
  content: unicode('ebaa');
}
.icon-time-lock:before {
  content: unicode('ebab');
}
.icon-health-heart-pulse:before {
  content: unicode('ebac');
}
.icon-pet-medal-2:before {
  content: unicode('ebad');
}
.icon-life-jacket:before {
  content: unicode('ebae');
}
.icon-table-office:before {
  content: unicode('ebaf');
}
.icon-sign-free-2:before {
  content: unicode('ebb0');
}
.icon-sneakers:before {
  content: unicode('ebb1');
}
.icon-banking-bill-paid-1:before {
  content: unicode('ebb2');
}
.icon-food-flour:before {
  content: unicode('ebb3');
}
.icon-window-flash:before {
  content: unicode('ebb4');
}
.icon-hotel-toilet-seat:before {
  content: unicode('ebb5');
}
.icon-qr-code-1:before {
  content: unicode('ebb6');
}
.icon-travel-user:before {
  content: unicode('ebb7');
}
.icon-speakers-4:before {
  content: unicode('ebb8');
}
.icon-kitchen-barbecue-stove:before {
  content: unicode('ebb9');
}
.icon-ethernet-cable:before {
  content: unicode('ebba');
}
.icon-file-video-edit:before {
  content: unicode('ebbb');
}
.icon-share-signal-antenna-2:before {
  content: unicode('ebbc');
}
.icon-female-2:before {
  content: unicode('ebbd');
}
.icon-pet-search:before {
  content: unicode('ebbe');
}
.icon-network-upload:before {
  content: unicode('ebbf');
}
.icon-file-audio-1:before {
  content: unicode('ebc0');
}
.icon-folder-view:before {
  content: unicode('ebc1');
}
.icon-female-3:before {
  content: unicode('ebc2');
}
.icon-shirt:before {
  content: unicode('ebc3');
}
.icon-day-hail:before {
  content: unicode('ebc4');
}
.icon-chat-bubble-square-check-2:before {
  content: unicode('ebc5');
}
.icon-file-notes-subtract:before {
  content: unicode('ebc6');
}
.icon-banking-atm-1:before {
  content: unicode('ebc7');
}
.icon-sign-free-3:before {
  content: unicode('ebc8');
}
.icon-remove-tab:before {
  content: unicode('ebc9');
}
.icon-move-up-2:before {
  content: unicode('ebca');
}
.icon-hat-detective:before {
  content: unicode('ebcb');
}
.icon-programming-graph-bar:before {
  content: unicode('ebcc');
}
.icon-server-favorite-heart-2:before {
  content: unicode('ebcd');
}
.icon-chat-bubble-square-sync:before {
  content: unicode('ebce');
}
.icon-data-upload-9:before {
  content: unicode('ebcf');
}
.icon-shirt-tie:before {
  content: unicode('ebd0');
}
.icon-car-remote:before {
  content: unicode('ebd1');
}
.icon-formula:before {
  content: unicode('ebd2');
}
.icon-file-video-add:before {
  content: unicode('ebd3');
}
.icon-atomic-bomb:before {
  content: unicode('ebd4');
}
.icon-user-group-view:before {
  content: unicode('ebd5');
}
.icon-cone:before {
  content: unicode('ebd6');
}
.icon-day-snowy:before {
  content: unicode('ebd7');
}
.icon-file-design-box-psd:before {
  content: unicode('ebd8');
}
.icon-content-books-2:before {
  content: unicode('ebd9');
}
.icon-video-clip-3:before {
  content: unicode('ebda');
}
.icon-network-share-1:before {
  content: unicode('ebdb');
}
.icon-food-steak:before {
  content: unicode('ebdc');
}
.icon-layout-5:before {
  content: unicode('ebdd');
}
.icon-projector-screen-timeout:before {
  content: unicode('ebde');
}
.icon-wrap-text-side:before {
  content: unicode('ebdf');
}
.icon-safety-buoy:before {
  content: unicode('ebe0');
}
.icon-chat-bubble-circle-check-1:before {
  content: unicode('ebe1');
}
.icon-power-button-2:before {
  content: unicode('ebe2');
}
.icon-canon:before {
  content: unicode('ebe3');
}
.icon-kitchen-whisks:before {
  content: unicode('ebe4');
}
.icon-crop-photo-1:before {
  content: unicode('ebe5');
}
.icon-banking-payment-reccuring:before {
  content: unicode('ebe6');
}
.icon-expand-diagonal-8:before {
  content: unicode('ebe7');
}
.icon-user-shield-2:before {
  content: unicode('ebe8');
}
.icon-move-to-front:before {
  content: unicode('ebe9');
}
.icon-file-video-1:before {
  content: unicode('ebea');
}
.icon-leisure-kite:before {
  content: unicode('ebeb');
}
.icon-navigation-before-3:before {
  content: unicode('ebec');
}
.icon-quill-write:before {
  content: unicode('ebed');
}
.icon-shrink-vertical-4:before {
  content: unicode('ebee');
}
.icon-file-office-box-txt:before {
  content: unicode('ebef');
}
.icon-music-album-share:before {
  content: unicode('ebf0');
}
.icon-sign-pound:before {
  content: unicode('ebf1');
}
.icon-fly-swatter:before {
  content: unicode('ebf2');
}
.icon-expand-horizontal-2:before {
  content: unicode('ebf3');
}
.icon-control-loop-back:before {
  content: unicode('ebf4');
}
.icon-file-video-settings:before {
  content: unicode('ebf5');
}
.icon-upload-laptop:before {
  content: unicode('ebf6');
}
.icon-equaliser-2:before {
  content: unicode('ebf7');
}
.icon-file-notes-check:before {
  content: unicode('ebf8');
}
.icon-couch:before {
  content: unicode('ebf9');
}
.icon-car-audio:before {
  content: unicode('ebfa');
}
.icon-circle-percent:before {
  content: unicode('ebfb');
}
.icon-sign-new-1:before {
  content: unicode('ebfc');
}
.icon-computer-chip-broken:before {
  content: unicode('ebfd');
}
.icon-crop:before {
  content: unicode('ebfe');
}
.icon-vote-hot-circle:before {
  content: unicode('ebff');
}
.icon-download-harddisk:before {
  content: unicode('ec00');
}
.icon-shopping-cart-favorite-star:before {
  content: unicode('ec01');
}
.icon-projector-screen-hierarchy:before {
  content: unicode('ec02');
}
.icon-window-dollar-1:before {
  content: unicode('ec03');
}
.icon-inflator-pump:before {
  content: unicode('ec04');
}
.icon-two-finger-swipe-up-2:before {
  content: unicode('ec05');
}
.icon-real-estate-block:before {
  content: unicode('ec06');
}
.icon-car-low-ight:before {
  content: unicode('ec07');
}
.icon-food-fried-egg-pan:before {
  content: unicode('ec08');
}
.icon-file-video-favorite-heart:before {
  content: unicode('ec09');
}
.icon-user-dollar-1:before {
  content: unicode('ec0a');
}
.icon-pen-3:before {
  content: unicode('ec0b');
}
.icon-content-layers-hide:before {
  content: unicode('ec0c');
}
.icon-calendar-1:before {
  content: unicode('ec0d');
}
.icon-sign-no-entry:before {
  content: unicode('ec0e');
}
.icon-hexagon-3d:before {
  content: unicode('ec0f');
}
.icon-file-images-box-eps:before {
  content: unicode('ec10');
}
.icon-smart-watch-circle-incoming-call:before {
  content: unicode('ec11');
}
.icon-files-coding-hqx:before {
  content: unicode('ec12');
}
.icon-pet-shampoo:before {
  content: unicode('ec13');
}
.icon-file-office-box-rtf:before {
  content: unicode('ec14');
}
.icon-two-finger-swipe-all-direction-2:before {
  content: unicode('ec15');
}
.icon-lawn-mower:before {
  content: unicode('ec16');
}
.icon-location-globe-download:before {
  content: unicode('ec17');
}
.icon-file-video-qt:before {
  content: unicode('ec18');
}
.icon-projector-screen-graph-line:before {
  content: unicode('ec19');
}
.icon-grenade:before {
  content: unicode('ec1a');
}
.icon-swipe-right-2:before {
  content: unicode('ec1b');
}
.icon-telephone-1:before {
  content: unicode('ec1c');
}
.icon-shrink-1:before {
  content: unicode('ec1d');
}
.icon-cursor-move-up-2:before {
  content: unicode('ec1e');
}
.icon-email-home-2:before {
  content: unicode('ec1f');
}
.icon-file-statistic-share-2:before {
  content: unicode('ec20');
}
.icon-bitcoin-cloud:before {
  content: unicode('ec21');
}
.icon-glasses-7:before {
  content: unicode('ec22');
}
.icon-navigation-filter-3:before {
  content: unicode('ec23');
}
.icon-mobile-play:before {
  content: unicode('ec24');
}
.icon-laptop-2:before {
  content: unicode('ec25');
}
.icon-food-icecream-3:before {
  content: unicode('ec26');
}
.icon-vote-flag-4:before {
  content: unicode('ec27');
}
.icon-rewards-banner-1:before {
  content: unicode('ec28');
}
.icon-data-transfer-mobile-computer-1:before {
  content: unicode('ec29');
}
.icon-moustache-2:before {
  content: unicode('ec2a');
}
.icon-compass-1:before {
  content: unicode('ec2b');
}
.icon-pin-paper:before {
  content: unicode('ec2c');
}
.icon-files-coding-question-mark-1:before {
  content: unicode('ec2d');
}
.icon-umbrella-open:before {
  content: unicode('ec2e');
}
.icon-pet-food-2:before {
  content: unicode('ec2f');
}
.icon-data-download-4:before {
  content: unicode('ec30');
}
.icon-smart-watch-square-incoming-call:before {
  content: unicode('ec31');
}
.icon-box-forward:before {
  content: unicode('ec32');
}
.icon-sign-no-photo:before {
  content: unicode('ec33');
}
.icon-organic-liquid-glass:before {
  content: unicode('ec34');
}
.icon-boat-transportation-ship:before {
  content: unicode('ec35');
}
.icon-user-add-1:before {
  content: unicode('ec36');
}
.icon-projector-screen-settings:before {
  content: unicode('ec37');
}
.icon-romance-marriage-license:before {
  content: unicode('ec38');
}
.icon-interface-share-time:before {
  content: unicode('ec39');
}
.icon-ruler-1:before {
  content: unicode('ec3a');
}
.icon-window-text:before {
  content: unicode('ec3b');
}
.icon-smart-watch-circle-alarm:before {
  content: unicode('ec3c');
}
.icon-hat-tall:before {
  content: unicode('ec3d');
}
.icon-construction-lift:before {
  content: unicode('ec3e');
}
.icon-calendar-add:before {
  content: unicode('ec3f');
}
.icon-programming-video:before {
  content: unicode('ec40');
}
.icon-cloudy-hail-storm:before {
  content: unicode('ec41');
}
.icon-location-map-view:before {
  content: unicode('ec42');
}
.icon-wifi-signal-check:before {
  content: unicode('ec43');
}
.icon-eco-scarecrow:before {
  content: unicode('ec44');
}
.icon-whiskey-barrel:before {
  content: unicode('ec45');
}
.icon-phone-miss-call:before {
  content: unicode('ec46');
}
.icon-food-french-fries:before {
  content: unicode('ec47');
}
.icon-focus-4:before {
  content: unicode('ec48');
}
.icon-leisure-drama:before {
  content: unicode('ec49');
}
.icon-golden-egg:before {
  content: unicode('ec4a');
}
.icon-video-control-fast-forward:before {
  content: unicode('ec4b');
}
.icon-video-camera-box:before {
  content: unicode('ec4c');
}
.icon-spelling-check-2:before {
  content: unicode('ec4d');
}
.icon-file-office-box-rar:before {
  content: unicode('ec4e');
}
.icon-cc-camera-1:before {
  content: unicode('ec4f');
}
.icon-shopping-bag-check:before {
  content: unicode('ec50');
}
.icon-window-rss-feed:before {
  content: unicode('ec51');
}
.icon-euro-coin:before {
  content: unicode('ec52');
}
.icon-file-zipped-lock:before {
  content: unicode('ec53');
}
.icon-clapboard-record:before {
  content: unicode('ec54');
}
.icon-alarm-timeout:before {
  content: unicode('ec55');
}
.icon-ruler-3:before {
  content: unicode('ec56');
}
.icon-nature-flower-1:before {
  content: unicode('ec57');
}
.icon-smart-watch-circle:before {
  content: unicode('ec58');
}
.icon-sd-card-add:before {
  content: unicode('ec59');
}
.icon-vote-heart-information:before {
  content: unicode('ec5a');
}
.icon-two-finger-tap-double-1:before {
  content: unicode('ec5b');
}
.icon-data-download-6:before {
  content: unicode('ec5c');
}
.icon-paragraph-normal:before {
  content: unicode('ec5d');
}
.icon-files-coding-view-2:before {
  content: unicode('ec5e');
}
.icon-shopping-cart-lock:before {
  content: unicode('ec5f');
}
.icon-computer-port:before {
  content: unicode('ec60');
}
.icon-car-view:before {
  content: unicode('ec61');
}
.icon-inbox:before {
  content: unicode('ec62');
}
.icon-food-icecream-1:before {
  content: unicode('ec63');
}
.icon-star-constellation:before {
  content: unicode('ec64');
}
.icon-navigation-filter-1:before {
  content: unicode('ec65');
}
.icon-glasses-5:before {
  content: unicode('ec66');
}
.icon-server-upload-1:before {
  content: unicode('ec67');
}
.icon-file-design-dxf:before {
  content: unicode('ec68');
}
.icon-compass-3:before {
  content: unicode('ec69');
}
.icon-align-justify:before {
  content: unicode('ec6a');
}
.icon-file-copy:before {
  content: unicode('ec6b');
}
.icon-vote-flag-6:before {
  content: unicode('ec6c');
}
.icon-sport-football-helmet:before {
  content: unicode('ec6d');
}
.icon-sign-yen:before {
  content: unicode('ec6e');
}
.icon-telephone-3:before {
  content: unicode('ec6f');
}
.icon-trolley:before {
  content: unicode('ec70');
}
.icon-location-pin-airport-1:before {
  content: unicode('ec71');
}
.icon-microphone:before {
  content: unicode('ec72');
}
.icon-message-bottle:before {
  content: unicode('ec73');
}
.icon-real-estate-sold:before {
  content: unicode('ec74');
}
.icon-hand-diamond:before {
  content: unicode('ec75');
}
.icon-shrink-3:before {
  content: unicode('ec76');
}
.icon-kitchen-apron:before {
  content: unicode('ec77');
}
.icon-calendar-3:before {
  content: unicode('ec78');
}
.icon-pen-1:before {
  content: unicode('ec79');
}
.icon-email-share:before {
  content: unicode('ec7a');
}
.icon-drum-2:before {
  content: unicode('ec7b');
}
.icon-file-statistic-subtract-2:before {
  content: unicode('ec7c');
}
.icon-music-album-favorite-heart:before {
  content: unicode('ec7d');
}
.icon-shopping-cart-1:before {
  content: unicode('ec7e');
}
.icon-location-user:before {
  content: unicode('ec7f');
}
.icon-calendar-close-2:before {
  content: unicode('ec80');
}
.icon-file-images-box-gif:before {
  content: unicode('ec81');
}
.icon-cassette-recorder:before {
  content: unicode('ec82');
}
.icon-sign-new-3:before {
  content: unicode('ec83');
}
.icon-file-notes-question-mark:before {
  content: unicode('ec84');
}
.icon-tap-swipe-right-2:before {
  content: unicode('ec85');
}
.icon-chat-bubble-square-timeout:before {
  content: unicode('ec86');
}
.icon-camera-off:before {
  content: unicode('ec87');
}
.icon-sd-card-sync:before {
  content: unicode('ec88');
}
.icon-motorcycle-2:before {
  content: unicode('ec89');
}
.icon-shopping-bag-dollar:before {
  content: unicode('ec8a');
}
.icon-window-check:before {
  content: unicode('ec8b');
}
.icon-priest:before {
  content: unicode('ec8c');
}
.icon-shopping-cart-subtract:before {
  content: unicode('ec8d');
}
.icon-chat-bubble-circle-share:before {
  content: unicode('ec8e');
}
.icon-chat-bubble-square-graph:before {
  content: unicode('ec8f');
}
.icon-clouds:before {
  content: unicode('ec90');
}
.icon-programming-graph-pie:before {
  content: unicode('ec91');
}
.icon-computer-chip-1:before {
  content: unicode('ec92');
}
.icon-vote-thumbs-down:before {
  content: unicode('ec93');
}
.icon-user-home:before {
  content: unicode('ec94');
}
.icon-calendar-check-1:before {
  content: unicode('ec95');
}
.icon-night-thunder:before {
  content: unicode('ec96');
}
.icon-bicycle-1:before {
  content: unicode('ec97');
}
.icon-email-dollar-2:before {
  content: unicode('ec98');
}
.icon-shopping-basket-favorite-heart:before {
  content: unicode('ec99');
}
.icon-sport-bicycle-helmet:before {
  content: unicode('ec9a');
}
.icon-car-windshield-clean:before {
  content: unicode('ec9b');
}
.icon-navigation-before-1:before {
  content: unicode('ec9c');
}
.icon-sport-basketball:before {
  content: unicode('ec9d');
}
.icon-file-video-3:before {
  content: unicode('ec9e');
}
.icon-nature-water:before {
  content: unicode('ec9f');
}
.icon-file-bookmark:before {
  content: unicode('eca0');
}
.icon-file-video-block:before {
  content: unicode('eca1');
}
.icon-box-download:before {
  content: unicode('eca2');
}
.icon-day-heavy-snow:before {
  content: unicode('eca3');
}
.icon-location-pin-subway-station-2:before {
  content: unicode('eca4');
}
.icon-credit-card-home:before {
  content: unicode('eca5');
}
.icon-sign-no-parking:before {
  content: unicode('eca6');
}
.icon-file-notes-dollar:before {
  content: unicode('eca7');
}
.icon-boots-lady:before {
  content: unicode('eca8');
}
.icon-photo-portrait:before {
  content: unicode('eca9');
}
.icon-file-video-box-avi:before {
  content: unicode('ecaa');
}
.icon-pin-1:before {
  content: unicode('ecab');
}
.icon-user-group-1:before {
  content: unicode('ecac');
}
.icon-live-global:before {
  content: unicode('ecad');
}
.icon-real-estate-sign-rent-2:before {
  content: unicode('ecae');
}
.icon-nature-dead-fish:before {
  content: unicode('ecaf');
}
.icon-cursor-arrow-target:before {
  content: unicode('ecb0');
}
.icon-layout-7:before {
  content: unicode('ecb1');
}
.icon-all-caps:before {
  content: unicode('ecb2');
}
.icon-video-clip-1:before {
  content: unicode('ecb3');
}
.icon-sign-spa-1:before {
  content: unicode('ecb4');
}
.icon-tag-check:before {
  content: unicode('ecb5');
}
.icon-expand-vertical-2:before {
  content: unicode('ecb6');
}
.icon-move-right-2:before {
  content: unicode('ecb7');
}
.icon-leisure-mask:before {
  content: unicode('ecb8');
}
.icon-toolbox-1:before {
  content: unicode('ecb9');
}
.icon-smiley-sigh-2:before {
  content: unicode('ecba');
}
.icon-file-audio-aac:before {
  content: unicode('ecbb');
}
.icon-cloud-subtract:before {
  content: unicode('ecbc');
}
.icon-location-globe-question-mark:before {
  content: unicode('ecbd');
}
.icon-network-sync:before {
  content: unicode('ecbe');
}
.icon-file-images-bmp:before {
  content: unicode('ecbf');
}
.icon-chat-bubble-square-block:before {
  content: unicode('ecc0');
}
.icon-shopping-cart-timeout:before {
  content: unicode('ecc1');
}
.icon-weather-cock:before {
  content: unicode('ecc2');
}
.icon-pet-medal-1:before {
  content: unicode('ecc3');
}
.icon-audio-control-play:before {
  content: unicode('ecc4');
}
.icon-leisure-cards-hand:before {
  content: unicode('ecc5');
}
.icon-paper-boat:before {
  content: unicode('ecc6');
}
.icon-sign-free-1:before {
  content: unicode('ecc7');
}
.icon-banking-bill-paid-2:before {
  content: unicode('ecc8');
}
.icon-smart-watch-circle-home:before {
  content: unicode('ecc9');
}
.icon-health-view-pulse:before {
  content: unicode('ecca');
}
.icon-control-syncing:before {
  content: unicode('eccb');
}
.icon-email-information:before {
  content: unicode('eccc');
}
.icon-chef:before {
  content: unicode('eccd');
}
.icon-file-audio-2:before {
  content: unicode('ecce');
}
.icon-glass-water:before {
  content: unicode('eccf');
}
.icon-building-18:before {
  content: unicode('ecd0');
}
.icon-female-1:before {
  content: unicode('ecd1');
}
.icon-share-signal-antenna-1:before {
  content: unicode('ecd2');
}
.icon-video-games-tetris:before {
  content: unicode('ecd3');
}
.icon-helicopter:before {
  content: unicode('ecd4');
}
.icon-chat-bubble-square-check-1:before {
  content: unicode('ecd5');
}
.icon-camera-record:before {
  content: unicode('ecd6');
}
.icon-paw:before {
  content: unicode('ecd7');
}
.icon-firewall:before {
  content: unicode('ecd8');
}
.icon-file-audio-3:before {
  content: unicode('ecd9');
}
.icon-server-favorite-heart-1:before {
  content: unicode('ecda');
}
.icon-boat-steering-wheel:before {
  content: unicode('ecdb');
}
.icon-spa-stone:before {
  content: unicode('ecdc');
}
.icon-navigation-clear-all:before {
  content: unicode('ecdd');
}
.icon-move-up-1:before {
  content: unicode('ecde');
}
.icon-places-colosseum:before {
  content: unicode('ecdf');
}
.icon-content-book-refresh:before {
  content: unicode('ece0');
}
.icon-chat-bubble-square-heart:before {
  content: unicode('ece1');
}
.icon-tea-pot:before {
  content: unicode('ece2');
}
.icon-banking-atm-2:before {
  content: unicode('ece3');
}
.icon-eco-seeding:before {
  content: unicode('ece4');
}
.icon-mobile-phone-shield:before {
  content: unicode('ece5');
}
.icon-content-books-1:before {
  content: unicode('ece6');
}
.icon-devices:before {
  content: unicode('ece7');
}
.icon-files-landscape-image:before {
  content: unicode('ece8');
}
.icon-window-email:before {
  content: unicode('ece9');
}
.icon-files-coding-ym:before {
  content: unicode('ecea');
}
.icon-move-right-3:before {
  content: unicode('eceb');
}
.icon-expand-vertical-3:before {
  content: unicode('ecec');
}
.icon-phone-speaker:before {
  content: unicode('eced');
}
.icon-eco-search:before {
  content: unicode('ecee');
}
.icon-leisure-card-diamonds:before {
  content: unicode('ecef');
}
.icon-content-bookmark-4:before {
  content: unicode('ecf0');
}
.icon-hotel-swimming-pool:before {
  content: unicode('ecf1');
}
.icon-chat-bubble-circle-check-2:before {
  content: unicode('ecf2');
}
.icon-power-button-1:before {
  content: unicode('ecf3');
}
.icon-network-share-2:before {
  content: unicode('ecf4');
}
.icon-content-book-alert:before {
  content: unicode('ecf5');
}
.icon-layout-6:before {
  content: unicode('ecf6');
}
.icon-tools-measuring-tape:before {
  content: unicode('ecf7');
}
.icon-music-album-timeout:before {
  content: unicode('ecf8');
}
.icon-coffee-bean-jar:before {
  content: unicode('ecf9');
}
.icon-crop-photo-2:before {
  content: unicode('ecfa');
}
.icon-car-seatbelt:before {
  content: unicode('ecfb');
}
.icon-hotel-spa:before {
  content: unicode('ecfc');
}
.icon-file-video-2:before {
  content: unicode('ecfd');
}
.icon-user-shield-1:before {
  content: unicode('ecfe');
}
.icon-ipod-wireless:before {
  content: unicode('ecff');
}
.icon-location-pin-multiple:before {
  content: unicode('ed00');
}
.icon-expand-horizontal-1:before {
  content: unicode('ed01');
}
.icon-hotel-5-stars:before {
  content: unicode('ed02');
}
.icon-exchange-dollar-bitcoin:before {
  content: unicode('ed03');
}
.icon-box-shipping-air:before {
  content: unicode('ed04');
}
.icon-strategy:before {
  content: unicode('ed05');
}
.icon-leisure-ticket-4:before {
  content: unicode('ed06');
}
.icon-equaliser-1:before {
  content: unicode('ed07');
}
.icon-motorcycle-3:before {
  content: unicode('ed08');
}
.icon-shape-circle:before {
  content: unicode('ed09');
}
.icon-content-book-information:before {
  content: unicode('ed0a');
}
.icon-christmas-decorative-ball:before {
  content: unicode('ed0b');
}
.icon-circle-graph-pie:before {
  content: unicode('ed0c');
}
.icon-file-images-edit:before {
  content: unicode('ed0d');
}
.icon-raw-crystal:before {
  content: unicode('ed0e');
}
.icon-file-office-text:before {
  content: unicode('ed0f');
}
.icon-window-dollar-2:before {
  content: unicode('ed10');
}
.icon-sign-new-2:before {
  content: unicode('ed11');
}
.icon-camera-timer-3-seconds:before {
  content: unicode('ed12');
}
.icon-two-finger-swipe-all-direction-1:before {
  content: unicode('ed13');
}
.icon-drum-3:before {
  content: unicode('ed14');
}
.icon-phone-signal-high:before {
  content: unicode('ed15');
}
.icon-video-control-record:before {
  content: unicode('ed16');
}
.icon-car-speed-gauge:before {
  content: unicode('ed17');
}
.icon-calendar-2:before {
  content: unicode('ed18');
}
.icon-move-to-top:before {
  content: unicode('ed19');
}
.icon-cursor-move-up-1:before {
  content: unicode('ed1a');
}
.icon-file-statistic-share-1:before {
  content: unicode('ed1b');
}
.icon-email-home-1:before {
  content: unicode('ed1c');
}
.icon-shrink-2:before {
  content: unicode('ed1d');
}
.icon-box-edit:before {
  content: unicode('ed1e');
}
.icon-telephone-2:before {
  content: unicode('ed1f');
}
.icon-swipe-right-1:before {
  content: unicode('ed20');
}
.icon-moustache-1:before {
  content: unicode('ed21');
}
.icon-compass-2:before {
  content: unicode('ed22');
}
.icon-vote-flag-7:before {
  content: unicode('ed23');
}
.icon-rewards-banner-2:before {
  content: unicode('ed24');
}
.icon-data-transfer-mobile-computer-2:before {
  content: unicode('ed25');
}
.icon-laptop-1:before {
  content: unicode('ed26');
}
.icon-gender-female-female:before {
  content: unicode('ed27');
}
.icon-glasses-4:before {
  content: unicode('ed28');
}
.icon-file-images-svg:before {
  content: unicode('ed29');
}
.icon-file-images-subtract:before {
  content: unicode('ed2a');
}
.icon-kitchen-grill-rack:before {
  content: unicode('ed2b');
}
.icon-files-coding-html:before {
  content: unicode('ed2c');
}
.icon-shopping-cart-share:before {
  content: unicode('ed2d');
}
.icon-email-audio:before {
  content: unicode('ed2e');
}
.icon-user-group-sync:before {
  content: unicode('ed2f');
}
.icon-files-coding-question-mark-2:before {
  content: unicode('ed30');
}
.icon-pet-food-1:before {
  content: unicode('ed31');
}
.icon-data-download-7:before {
  content: unicode('ed32');
}
.icon-credit-card-block:before {
  content: unicode('ed33');
}
.icon-cursor-move-horizontal:before {
  content: unicode('ed34');
}
.icon-arrow-page-down:before {
  content: unicode('ed35');
}
.icon-food-squid:before {
  content: unicode('ed36');
}
.icon-ruler-2:before {
  content: unicode('ed37');
}
.icon-calendar-settings:before {
  content: unicode('ed38');
}
.icon-horizontal-vertical-cursor:before {
  content: unicode('ed39');
}
.icon-kitchen-microwave:before {
  content: unicode('ed3a');
}
.icon-smart-watch-circle-navigation:before {
  content: unicode('ed3b');
}
.icon-cloud-wifi:before {
  content: unicode('ed3c');
}
.icon-chat-bubble-circle-edit:before {
  content: unicode('ed3d');
}
.icon-calendar-timeout:before {
  content: unicode('ed3e');
}
.icon-food-bread-jam:before {
  content: unicode('ed3f');
}
.icon-box-settings:before {
  content: unicode('ed40');
}
.icon-location-globe-block:before {
  content: unicode('ed41');
}
.icon-magician:before {
  content: unicode('ed42');
}
.icon-arrow-down-left:before {
  content: unicode('ed43');
}
.icon-chat-bubble-square-view:before {
  content: unicode('ed44');
}
.icon-printer-view:before {
  content: unicode('ed45');
}
.icon-focus-5:before {
  content: unicode('ed46');
}
.icon-files-coding-sql:before {
  content: unicode('ed47');
}
.icon-file-add-2:before {
  content: unicode('ed48');
}
.icon-sign-recycle:before {
  content: unicode('ed49');
}
.icon-circle-group:before {
  content: unicode('ed4a');
}
.icon-real-estate-keyhole:before {
  content: unicode('ed4b');
}
.icon-camera-brightness-daylight-2:before {
  content: unicode('ed4c');
}
.icon-focus-off:before {
  content: unicode('ed4d');
}
.icon-location-flag:before {
  content: unicode('ed4e');
}
.icon-places-home-2:before {
  content: unicode('ed4f');
}
.icon-projector-screen:before {
  content: unicode('ed50');
}
.icon-vote-star-add:before {
  content: unicode('ed51');
}
.icon-places-arc-de-triomphe:before {
  content: unicode('ed52');
}
.icon-grid-upload:before {
  content: unicode('ed53');
}
.icon-romance-lips:before {
  content: unicode('ed54');
}
.icon-keyboard:before {
  content: unicode('ed55');
}
.icon-key-1:before {
  content: unicode('ed56');
}
.icon-vote-heart-lock:before {
  content: unicode('ed57');
}
.icon-font-size:before {
  content: unicode('ed58');
}
.icon-kitchen-snowflake:before {
  content: unicode('ed59');
}
.icon-vote-heart-alert:before {
  content: unicode('ed5a');
}
.icon-eco-car:before {
  content: unicode('ed5b');
}
.icon-server-question-mark-1:before {
  content: unicode('ed5c');
}
.icon-user-flag:before {
  content: unicode('ed5d');
}
.icon-smiley-smile-7:before {
  content: unicode('ed5e');
}
.icon-remove-circle-1:before {
  content: unicode('ed5f');
}
.icon-sport-skateboard:before {
  content: unicode('ed60');
}
.icon-places-pyramid:before {
  content: unicode('ed61');
}
.icon-network-edit:before {
  content: unicode('ed62');
}
.icon-playlist-1:before {
  content: unicode('ed63');
}
.icon-user-upload:before {
  content: unicode('ed64');
}
.icon-location-pin-subtract-3:before {
  content: unicode('ed65');
}
.icon-qr-code:before {
  content: unicode('ed66');
}
.icon-banking-customer-service-2:before {
  content: unicode('ed67');
}
.icon-box-refresh:before {
  content: unicode('ed68');
}
.icon-leisure-marry-go-round:before {
  content: unicode('ed69');
}
.icon-files-coding-check-1:before {
  content: unicode('ed6a');
}
.icon-shopping-bag-view:before {
  content: unicode('ed6b');
}
.icon-location-pin-alert-1:before {
  content: unicode('ed6c');
}
.icon-meteorite:before {
  content: unicode('ed6d');
}
.icon-double-ratchet:before {
  content: unicode('ed6e');
}
.icon-vote-star-flag:before {
  content: unicode('ed6f');
}
.icon-health-dentist-1:before {
  content: unicode('ed70');
}
.icon-eco-biofuel:before {
  content: unicode('ed71');
}
.icon-food-banana:before {
  content: unicode('ed72');
}
.icon-infinity-loop-1:before {
  content: unicode('ed73');
}
.icon-sd-card-upload:before {
  content: unicode('ed74');
}
.icon-travel-palm-tree:before {
  content: unicode('ed75');
}
.icon-cat-dump-tray:before {
  content: unicode('ed76');
}
.icon-christmas-tree:before {
  content: unicode('ed77');
}
.icon-file-landscape-image:before {
  content: unicode('ed78');
}
.icon-cloud-favorite-heart:before {
  content: unicode('ed79');
}
.icon-vaccum-cleaner:before {
  content: unicode('ed7a');
}
.icon-interface-decreasing:before {
  content: unicode('ed7b');
}
.icon-copy-down:before {
  content: unicode('ed7c');
}
.icon-file-notes-new:before {
  content: unicode('ed7d');
}
.icon-operator-female:before {
  content: unicode('ed7e');
}
.icon-cable-car:before {
  content: unicode('ed7f');
}
.icon-banking-ledger:before {
  content: unicode('ed80');
}
.icon-folder-favorite-star:before {
  content: unicode('ed81');
}
.icon-file-video-close:before {
  content: unicode('ed82');
}
.icon-location-map:before {
  content: unicode('ed83');
}
.icon-nuclear-power-plant:before {
  content: unicode('ed84');
}
.icon-clapboard-pause:before {
  content: unicode('ed85');
}
.icon-server-refresh-2:before {
  content: unicode('ed86');
}
.icon-user-group-home-1:before {
  content: unicode('ed87');
}
.icon-fishbowl:before {
  content: unicode('ed88');
}
.icon-cloud-refresh:before {
  content: unicode('ed89');
}
.icon-romance-heart-balloon:before {
  content: unicode('ed8a');
}
.icon-sd-card-edit:before {
  content: unicode('ed8b');
}
.icon-server-download-1:before {
  content: unicode('ed8c');
}
.icon-chat-bubble-square-download:before {
  content: unicode('ed8d');
}
.icon-subtract-circle-2:before {
  content: unicode('ed8e');
}
.icon-file-tasks-lock:before {
  content: unicode('ed8f');
}
.icon-two-finger-swipe-right-2:before {
  content: unicode('ed90');
}
.icon-credit-card-subtract:before {
  content: unicode('ed91');
}
.icon-adhesive-tape:before {
  content: unicode('ed92');
}
.icon-mobile-phone-lock:before {
  content: unicode('ed93');
}
.icon-resolution-4k:before {
  content: unicode('ed94');
}
.icon-network-cash:before {
  content: unicode('ed95');
}
.icon-files-coding-close-2:before {
  content: unicode('ed96');
}
.icon-shopping-bag:before {
  content: unicode('ed97');
}
.icon-leisure-domino:before {
  content: unicode('ed98');
}
.icon-divide:before {
  content: unicode('ed99');
}
.icon-piggy-bank:before {
  content: unicode('ed9a');
}
.icon-network-people:before {
  content: unicode('ed9b');
}
.icon-euro-rising:before {
  content: unicode('ed9c');
}
.icon-music-album-home:before {
  content: unicode('ed9d');
}
.icon-video-control-previous:before {
  content: unicode('ed9e');
}
.icon-health-stethoscope:before {
  content: unicode('ed9f');
}
.icon-car-8:before {
  content: unicode('eda0');
}
.icon-file-tasks-alert:before {
  content: unicode('eda1');
}
.icon-trolley-box-3:before {
  content: unicode('eda2');
}
.icon-file-audio-dollar:before {
  content: unicode('eda3');
}
.icon-d-glasses:before {
  content: unicode('eda4');
}
.icon-window-wave:before {
  content: unicode('eda5');
}
.icon-sd-card:before {
  content: unicode('eda6');
}
.icon-file-video-box-mp4:before {
  content: unicode('eda7');
}
.icon-location-pin-5:before {
  content: unicode('eda8');
}
.icon-navigation-down-2:before {
  content: unicode('eda9');
}
.icon-leisure-fire-cracker:before {
  content: unicode('edaa');
}
.icon-user-group-subtract:before {
  content: unicode('edab');
}
.icon-files-coding-box-apk:before {
  content: unicode('edac');
}
.icon-files-coding-box-asp:before {
  content: unicode('edad');
}
.icon-location-globe-home:before {
  content: unicode('edae');
}
.icon-grid-check:before {
  content: unicode('edaf');
}
.icon-monster-truck-2:before {
  content: unicode('edb0');
}
.icon-folder-edit:before {
  content: unicode('edb1');
}
.icon-health-hospital-sign-4:before {
  content: unicode('edb2');
}
.icon-user-group-share:before {
  content: unicode('edb3');
}
.icon-window-share-1:before {
  content: unicode('edb4');
}
.icon-temple-1:before {
  content: unicode('edb5');
}
.icon-bench:before {
  content: unicode('edb6');
}
.icon-shopping-basket-favorite-star:before {
  content: unicode('edb7');
}
.icon-phone-voicemail:before {
  content: unicode('edb8');
}
.icon-file-video-view:before {
  content: unicode('edb9');
}
.icon-chat-bubble-square-close:before {
  content: unicode('edba');
}
.icon-rewards-trophy-1:before {
  content: unicode('edbb');
}
.icon-user-download-1:before {
  content: unicode('edbc');
}
.icon-bookmark-1:before {
  content: unicode('edbd');
}
.icon-cursor-dial:before {
  content: unicode('edbe');
}
.icon-chat-bubble-circle-sync:before {
  content: unicode('edbf');
}
.icon-beauty-powder-brush:before {
  content: unicode('edc0');
}
.icon-file-audio-alert:before {
  content: unicode('edc1');
}
.icon-file-share-1:before {
  content: unicode('edc2');
}
.icon-gesture-zoom-in-2:before {
  content: unicode('edc3');
}
.icon-file-images-check:before {
  content: unicode('edc4');
}
.icon-user-chat-1:before {
  content: unicode('edc5');
}
.icon-thermometer-increase:before {
  content: unicode('edc6');
}
.icon-cursor-target-1:before {
  content: unicode('edc7');
}
.icon-location-pin-target-2:before {
  content: unicode('edc8');
}
.icon-quill-ink:before {
  content: unicode('edc9');
}
.icon-wrench-screwdriver:before {
  content: unicode('edca');
}
.icon-credit-card-add:before {
  content: unicode('edcb');
}
.icon-navigation-down-3:before {
  content: unicode('edcc');
}
.icon-user-group-edit:before {
  content: unicode('edcd');
}
.icon-move-to-bottom:before {
  content: unicode('edce');
}
.icon-music-album-add:before {
  content: unicode('edcf');
}
.icon-leisure-magic:before {
  content: unicode('edd0');
}
.icon-location-pin-4:before {
  content: unicode('edd1');
}
.icon-water-gun:before {
  content: unicode('edd2');
}
.icon-server-share-2:before {
  content: unicode('edd3');
}
.icon-trolley-box-2:before {
  content: unicode('edd4');
}
.icon-mobile-phone-block:before {
  content: unicode('edd5');
}
.icon-flick-horizontal-1:before {
  content: unicode('edd6');
}
.icon-wifi-modem-1:before {
  content: unicode('edd7');
}
.icon-content-book-favorite-star:before {
  content: unicode('edd8');
}
.icon-frame-1:before {
  content: unicode('edd9');
}
.icon-luggage-weight-scale:before {
  content: unicode('edda');
}
.icon-power-shortage:before {
  content: unicode('eddb');
}
.icon-airport-traffic-tower:before {
  content: unicode('eddc');
}
.icon-eco-wind-turbine:before {
  content: unicode('eddd');
}
.icon-box-sync:before {
  content: unicode('edde');
}
.icon-traffic-light-2:before {
  content: unicode('eddf');
}
.icon-cross-over-1:before {
  content: unicode('ede0');
}
.icon-subtract-1:before {
  content: unicode('ede1');
}
.icon-nature-tree-1:before {
  content: unicode('ede2');
}
.icon-files-coding-box-css:before {
  content: unicode('ede3');
}
.icon-spa-essence-candle-2:before {
  content: unicode('ede4');
}
.icon-android-refresh:before {
  content: unicode('ede5');
}
.icon-link:before {
  content: unicode('ede6');
}
.icon-file-favorite-star-1:before {
  content: unicode('ede7');
}
.icon-box-handle-1:before {
  content: unicode('ede8');
}
.icon-file-images-sync:before {
  content: unicode('ede9');
}
.icon-shorts-2:before {
  content: unicode('edea');
}
.icon-file-statistic-refresh-1:before {
  content: unicode('edeb');
}
.icon-health-patient-bed:before {
  content: unicode('edec');
}
.icon-g-signal:before {
  content: unicode('eded');
}
.icon-banking-payment-problem:before {
  content: unicode('edee');
}
.icon-circle-graph-line:before {
  content: unicode('edef');
}
.icon-location-pin-gym-1:before {
  content: unicode('edf0');
}
.icon-interface-alert-circle:before {
  content: unicode('edf1');
}
.icon-android-alert:before {
  content: unicode('edf2');
}
.icon-modules-1:before {
  content: unicode('edf3');
}
.icon-crayons:before {
  content: unicode('edf4');
}
.icon-files-coding-box-php:before {
  content: unicode('edf5');
}
.icon-synchronize-view:before {
  content: unicode('edf6');
}
.icon-files-coding-lock-1:before {
  content: unicode('edf7');
}
.icon-files-coding-app:before {
  content: unicode('edf8');
}
.icon-smart-watch-circle-messages:before {
  content: unicode('edf9');
}
.icon-chat-bubble-square-image:before {
  content: unicode('edfa');
}
.icon-medical-cross-snake:before {
  content: unicode('edfb');
}
.icon-data-transfer-global:before {
  content: unicode('edfc');
}
.icon-undershirt:before {
  content: unicode('edfd');
}
.icon-night-heavy-snow:before {
  content: unicode('edfe');
}
.icon-real-estate-information:before {
  content: unicode('edff');
}
.icon-audio-control-previous:before {
  content: unicode('ee00');
}
.icon-cursor-arrow-1:before {
  content: unicode('ee01');
}
.icon-bitcoin-coin:before {
  content: unicode('ee02');
}
.icon-d-sync:before {
  content: unicode('ee03');
}
.icon-cooking-oil-bottle:before {
  content: unicode('ee04');
}
.icon-files-coding-favorite-heart-2:before {
  content: unicode('ee05');
}
.icon-banking-customer-service-3:before {
  content: unicode('ee06');
}
.icon-rewards-crown-2:before {
  content: unicode('ee07');
}
.icon-food-chocolate-bar:before {
  content: unicode('ee08');
}
.icon-pet-collar-2:before {
  content: unicode('ee09');
}
.icon-location-pin-subtract-2:before {
  content: unicode('ee0a');
}
.icon-file-audio-box-aac:before {
  content: unicode('ee0b');
}
.icon-dollar-rising:before {
  content: unicode('ee0c');
}
.icon-sport-snooker:before {
  content: unicode('ee0d');
}
.icon-box-2:before {
  content: unicode('ee0e');
}
.icon-location-globe-close:before {
  content: unicode('ee0f');
}
.icon-food-menu:before {
  content: unicode('ee10');
}
.icon-store:before {
  content: unicode('ee11');
}
.icon-window-expand-2:before {
  content: unicode('ee12');
}
.icon-smiley-smile-6:before {
  content: unicode('ee13');
}
.icon-coin-stack-2:before {
  content: unicode('ee14');
}
.icon-truck-star:before {
  content: unicode('ee15');
}
.icon-wifi-signal-lock:before {
  content: unicode('ee16');
}
.icon-crop-2:before {
  content: unicode('ee17');
}
.icon-shopping-cart-upload:before {
  content: unicode('ee18');
}
.icon-arrange-1-to-9:before {
  content: unicode('ee19');
}
.icon-files-coding-home-1:before {
  content: unicode('ee1a');
}
.icon-mobile-phone-gps:before {
  content: unicode('ee1b');
}
.icon-hot-glue:before {
  content: unicode('ee1c');
}
.icon-files-coding-box-iso:before {
  content: unicode('ee1d');
}
.icon-places-home-3:before {
  content: unicode('ee1e');
}
.icon-border-inside-vertical:before {
  content: unicode('ee1f');
}
.icon-kitchen-shredder:before {
  content: unicode('ee20');
}
.icon-cloud-rainbow:before {
  content: unicode('ee21');
}
.icon-credit-card-close:before {
  content: unicode('ee22');
}
.icon-places-factory-2:before {
  content: unicode('ee23');
}
.icon-chat-bubble-square-settings:before {
  content: unicode('ee24');
}
.icon-rewards-medal-1:before {
  content: unicode('ee25');
}
.icon-music-album-question-mark:before {
  content: unicode('ee26');
}
.icon-file-swap:before {
  content: unicode('ee27');
}
.icon-location-map-2:before {
  content: unicode('ee28');
}
.icon-files--creative-commons-attribution:before {
  content: unicode('ee29');
}
.icon-coin-point:before {
  content: unicode('ee2a');
}
.icon-car-high-light:before {
  content: unicode('ee2b');
}
.icon-business-graph-line-1:before {
  content: unicode('ee2c');
}
.icon-file-tasks-subtract:before {
  content: unicode('ee2d');
}
.icon-vote-plus:before {
  content: unicode('ee2e');
}
.icon-data-transfer-9:before {
  content: unicode('ee2f');
}
.icon-camera-brightness-daylight-1:before {
  content: unicode('ee30');
}
.icon-polo-shirt:before {
  content: unicode('ee31');
}
.icon-business-graph-line-3:before {
  content: unicode('ee32');
}
.icon-bird-feet:before {
  content: unicode('ee33');
}
.icon-dollar-decreasing:before {
  content: unicode('ee34');
}
.icon-file-add-1:before {
  content: unicode('ee35');
}
.icon-file-video-sync:before {
  content: unicode('ee36');
}
.icon-user-group-refresh:before {
  content: unicode('ee37');
}
.icon-money-bag-pounds:before {
  content: unicode('ee38');
}
.icon-user-audio:before {
  content: unicode('ee39');
}
.icon-rewards-pedestal:before {
  content: unicode('ee3a');
}
.icon-rewards-medal-3:before {
  content: unicode('ee3b');
}
.icon-ambulance:before {
  content: unicode('ee3c');
}
.icon-network-subtract:before {
  content: unicode('ee3d');
}
.icon-places-home-1:before {
  content: unicode('ee3e');
}
.icon-server-question-mark-2:before {
  content: unicode('ee3f');
}
.icon-shopping-basket-home:before {
  content: unicode('ee40');
}
.icon-hotel-luggage:before {
  content: unicode('ee41');
}
.icon-cog-double:before {
  content: unicode('ee42');
}
.icon-key-2:before {
  content: unicode('ee43');
}
.icon-smart-watch-circle-snooze:before {
  content: unicode('ee44');
}
.icon-playlist-2:before {
  content: unicode('ee45');
}
.icon-file-design-3ds:before {
  content: unicode('ee46');
}
.icon-remove-circle-2:before {
  content: unicode('ee47');
}
.icon-baby-milk-bottle:before {
  content: unicode('ee48');
}
.icon-computer-chip-32:before {
  content: unicode('ee49');
}
.icon-smiley-smile-4:before {
  content: unicode('ee4a');
}
.icon-interface-alert-diamond:before {
  content: unicode('ee4b');
}
.icon-calendar-favorite-star:before {
  content: unicode('ee4c');
}
.icon-banking-customer-service-1:before {
  content: unicode('ee4d');
}
.icon-pirate-hat:before {
  content: unicode('ee4e');
}
.icon-hotel-shower:before {
  content: unicode('ee4f');
}
.icon-health-dentist-2:before {
  content: unicode('ee50');
}
.icon-usb-sign:before {
  content: unicode('ee51');
}
.icon-infinity-loop-2:before {
  content: unicode('ee52');
}
.icon-cloud-folder:before {
  content: unicode('ee53');
}
.icon-chat-bubble-square-add:before {
  content: unicode('ee54');
}
.icon-video-control-next:before {
  content: unicode('ee55');
}
.icon-location-pin-alert-2:before {
  content: unicode('ee56');
}
.icon-oil-barrel:before {
  content: unicode('ee57');
}
.icon-files-coding-check-2:before {
  content: unicode('ee58');
}
.icon-building-pisa-tower:before {
  content: unicode('ee59');
}
.icon-cloud-downloading-4:before {
  content: unicode('ee5a');
}
.icon-content-box-5:before {
  content: unicode('ee5b');
}
.icon-smart-watch-square-voice-record:before {
  content: unicode('ee5c');
}
.icon-user-female:before {
  content: unicode('ee5d');
}
.icon-vote-heart-favorite-star:before {
  content: unicode('ee5e');
}
.icon-file-design-indd:before {
  content: unicode('ee5f');
}
.icon-cassette-tape:before {
  content: unicode('ee60');
}
.icon-video-games-mushroom:before {
  content: unicode('ee61');
}
.icon-time-check:before {
  content: unicode('ee62');
}
.icon-paragraph-two-columns:before {
  content: unicode('ee63');
}
.icon-water-mug:before {
  content: unicode('ee64');
}
.icon-server-refresh-1:before {
  content: unicode('ee65');
}
.icon-woodsaw:before {
  content: unicode('ee66');
}
.icon-window-question-mark:before {
  content: unicode('ee67');
}
.icon-folder-hierarchy:before {
  content: unicode('ee68');
}
.icon-grid-flash:before {
  content: unicode('ee69');
}
.icon-music-track:before {
  content: unicode('ee6a');
}
.icon-organization-network:before {
  content: unicode('ee6b');
}
.icon-server-download-2:before {
  content: unicode('ee6c');
}
.icon-file-images-png:before {
  content: unicode('ee6d');
}
.icon-travel-airplane:before {
  content: unicode('ee6e');
}
.icon-transfer-user-group-upload:before {
  content: unicode('ee6f');
}
.icon-content-book-lock:before {
  content: unicode('ee70');
}
.icon-resolution-8k:before {
  content: unicode('ee71');
}
.icon-camera-circle:before {
  content: unicode('ee72');
}
.icon-banking-bill-receive:before {
  content: unicode('ee73');
}
.icon-sign-disable:before {
  content: unicode('ee74');
}
.icon-subtract-circle-1:before {
  content: unicode('ee75');
}
.icon-chat-bubble-circle-favorite-heart:before {
  content: unicode('ee76');
}
.icon-shopping-bag-sync:before {
  content: unicode('ee77');
}
.icon-kitchen-stainer:before {
  content: unicode('ee78');
}
.icon-files-coding-close-1:before {
  content: unicode('ee79');
}
.icon-download-laptop:before {
  content: unicode('ee7a');
}
.icon-window-hierarchy:before {
  content: unicode('ee7b');
}
.icon-chariot:before {
  content: unicode('ee7c');
}
.icon-health-blood-drop:before {
  content: unicode('ee7d');
}
.icon-time-refresh:before {
  content: unicode('ee7e');
}
.icon-pet-trophy:before {
  content: unicode('ee7f');
}
.icon-frame-3:before {
  content: unicode('ee80');
}
.icon-mobile-phone-signal:before {
  content: unicode('ee81');
}
.icon-content-book-settings:before {
  content: unicode('ee82');
}
.icon-navigation-down-1:before {
  content: unicode('ee83');
}
.icon-close-circle:before {
  content: unicode('ee84');
}
.icon-phone-outgoing:before {
  content: unicode('ee85');
}
.icon-location-pin-6:before {
  content: unicode('ee86');
}
.icon-monster-truck-1:before {
  content: unicode('ee87');
}
.icon-mobile-phone-credit-card:before {
  content: unicode('ee88');
}
.icon-speaker-volume-off:before {
  content: unicode('ee89');
}
.icon-cursor-target-3:before {
  content: unicode('ee8a');
}
.icon-time-download:before {
  content: unicode('ee8b');
}
.icon-rewards-trophy-2:before {
  content: unicode('ee8c');
}
.icon-sport-tennis:before {
  content: unicode('ee8d');
}
.icon-box-close:before {
  content: unicode('ee8e');
}
.icon-magnet:before {
  content: unicode('ee8f');
}
.icon-bookmark-3:before {
  content: unicode('ee90');
}
.icon-outbox:before {
  content: unicode('ee91');
}
.icon-temple-2:before {
  content: unicode('ee92');
}
.icon-smart-watch-square-home:before {
  content: unicode('ee93');
}
.icon-window-share-2:before {
  content: unicode('ee94');
}
.icon-camera-adjust-brightness:before {
  content: unicode('ee95');
}
.icon-file-share-2:before {
  content: unicode('ee96');
}
.icon-gesture-zoom-in-1:before {
  content: unicode('ee97');
}
.icon-files-coding-box-dmg:before {
  content: unicode('ee98');
}
.icon-rewards-trophy-3:before {
  content: unicode('ee99');
}
.icon-bookmark-2:before {
  content: unicode('ee9a');
}
.icon-iphone-speaker:before {
  content: unicode('ee9b');
}
.icon-kitchen-oven:before {
  content: unicode('ee9c');
}
.icon-kitchen-heat-medium:before {
  content: unicode('ee9d');
}
.icon-missile:before {
  content: unicode('ee9e');
}
.icon-hat-snow:before {
  content: unicode('ee9f');
}
.icon-cursor-target-2:before {
  content: unicode('eea0');
}
.icon-location-pin-target-1:before {
  content: unicode('eea1');
}
.icon-sport-fishing-bait:before {
  content: unicode('eea2');
}
.icon-user-chat-2:before {
  content: unicode('eea3');
}
.icon-tools-shovel:before {
  content: unicode('eea4');
}
.icon-location-pin-7:before {
  content: unicode('eea5');
}
.icon-frame-2:before {
  content: unicode('eea6');
}
.icon-phone-check:before {
  content: unicode('eea7');
}
.icon-car-four-wheel:before {
  content: unicode('eea8');
}
.icon-shopping-basket-alert:before {
  content: unicode('eea9');
}
.icon-flick-horizontal-2:before {
  content: unicode('eeaa');
}
.icon-nature-dolphin:before {
  content: unicode('eeab');
}
.icon-tag-home:before {
  content: unicode('eeac');
}
.icon-wifi-modem-2:before {
  content: unicode('eead');
}
.icon-food-candy:before {
  content: unicode('eeae');
}
.icon-paper-pin:before {
  content: unicode('eeaf');
}
.icon-server-share-1:before {
  content: unicode('eeb0');
}
.icon-trolley-box-1:before {
  content: unicode('eeb1');
}
.icon-monocle-moustache:before {
  content: unicode('eeb2');
}
.icon-cross-over-2:before {
  content: unicode('eeb3');
}
.icon-traffic-light-1:before {
  content: unicode('eeb4');
}
.icon-airport-radar:before {
  content: unicode('eeb5');
}
.icon-hot-coffee-cup:before {
  content: unicode('eeb6');
}
.icon-alien-head:before {
  content: unicode('eeb7');
}
.icon-spa-essence-candle-1:before {
  content: unicode('eeb8');
}
.icon-money-bag-dollar:before {
  content: unicode('eeb9');
}
.icon-cloud-home:before {
  content: unicode('eeba');
}
.icon-mobile-phone-add:before {
  content: unicode('eebb');
}
.icon-subtract-2:before {
  content: unicode('eebc');
}
.icon-flip-up-1:before {
  content: unicode('eebd');
}
.icon-nature-tree-2:before {
  content: unicode('eebe');
}
.icon-email-media:before {
  content: unicode('eebf');
}
.icon-shorts-1:before {
  content: unicode('eec0');
}
.icon-box-handle-2:before {
  content: unicode('eec1');
}
.icon-file-favorite-star-2:before {
  content: unicode('eec2');
}
.icon-hotel-television:before {
  content: unicode('eec3');
}
.icon-day-thunder:before {
  content: unicode('eec4');
}
.icon-keyboard-key-enter:before {
  content: unicode('eec5');
}
.icon-file-office-pps:before {
  content: unicode('eec6');
}
.icon-tools-axe:before {
  content: unicode('eec7');
}
.icon-camera-macro-mode:before {
  content: unicode('eec8');
}
.icon-kitchen-refrigerator:before {
  content: unicode('eec9');
}
.icon-file-statistic-refresh-2:before {
  content: unicode('eeca');
}
.icon-view-lock:before {
  content: unicode('eecb');
}
.icon-files-landscape-programming:before {
  content: unicode('eecc');
}
.icon-modules-2:before {
  content: unicode('eecd');
}
.icon-forklift:before {
  content: unicode('eece');
}
.icon-location-pin-gym-2:before {
  content: unicode('eecf');
}
.icon-grid-favorite-star:before {
  content: unicode('eed0');
}
.icon-file-images-view:before {
  content: unicode('eed1');
}
.icon-electric-pole:before {
  content: unicode('eed2');
}
.icon-headphone-pulse:before {
  content: unicode('eed3');
}
.icon-content-box-4:before {
  content: unicode('eed4');
}
.icon-smart-watch-circle-temperature:before {
  content: unicode('eed5');
}
.icon-content-book-download:before {
  content: unicode('eed6');
}
.icon-places-anchor:before {
  content: unicode('eed7');
}
.icon-file-zipped-question-mark:before {
  content: unicode('eed8');
}
.icon-guitar-hear:before {
  content: unicode('eed9');
}
.icon-kitchen-cooker-hood:before {
  content: unicode('eeda');
}
.icon-baby-pacifier:before {
  content: unicode('eedb');
}
.icon-file-favorite:before {
  content: unicode('eedc');
}
.icon-bluetooth-signal:before {
  content: unicode('eedd');
}
.icon-box-view:before {
  content: unicode('eede');
}
.icon-cloud-question-mark:before {
  content: unicode('eedf');
}
.icon-projector:before {
  content: unicode('eee0');
}
.icon-infinity-loop-3:before {
  content: unicode('eee1');
}
.icon-real-estate-close:before {
  content: unicode('eee2');
}
.icon-files-coding-lock-2:before {
  content: unicode('eee3');
}
.icon-user-share:before {
  content: unicode('eee4');
}
.icon-pet-collar-1:before {
  content: unicode('eee5');
}
.icon-location-pin-subtract-1:before {
  content: unicode('eee6');
}
.icon-wifi-signal-upload:before {
  content: unicode('eee7');
}
.icon-files-coding-favorite-heart-1:before {
  content: unicode('eee8');
}
.icon-phone-list:before {
  content: unicode('eee9');
}
.icon-rewards-crown-1:before {
  content: unicode('eeea');
}
.icon-time-settings:before {
  content: unicode('eeeb');
}
.icon-cursor-arrow-2:before {
  content: unicode('eeec');
}
.icon-window-expand-1:before {
  content: unicode('eeed');
}
.icon-smiley-smile-5:before {
  content: unicode('eeee');
}
.icon-car-pickup:before {
  content: unicode('eeef');
}
.icon-box-1:before {
  content: unicode('eef0');
}
.icon-vote-heart-subtract:before {
  content: unicode('eef1');
}
.icon-playlist-3:before {
  content: unicode('eef2');
}
.icon-mobile-phone-location:before {
  content: unicode('eef3');
}
.icon-religion-taoism:before {
  content: unicode('eef4');
}
.icon-arrow-backtab:before {
  content: unicode('eef5');
}
.icon-cursor-dial-1:before {
  content: unicode('eef6');
}
.icon-angle-brackets:before {
  content: unicode('eef7');
}
.icon-coin-stack-1:before {
  content: unicode('eef8');
}
.icon-places-factory-1:before {
  content: unicode('eef9');
}
.icon-pen-tablet:before {
  content: unicode('eefa');
}
.icon-rewards-medal-2:before {
  content: unicode('eefb');
}
.icon-control-cross-over:before {
  content: unicode('eefc');
}
.icon-keyboard-key-alt:before {
  content: unicode('eefd');
}
.icon-files-coding-home-2:before {
  content: unicode('eefe');
}
.icon-shovel-cement:before {
  content: unicode('eeff');
}
.icon-chat-bubble-circle-view:before {
  content: unicode('ef00');
}
.icon-pet-bell:before {
  content: unicode('ef01');
}
.icon-crop-1:before {
  content: unicode('ef02');
}
.icon-kitchen-wine-opener:before {
  content: unicode('ef03');
}
.icon-business-graph-line-2:before {
  content: unicode('ef04');
}
.icon-location-map-1:before {
  content: unicode('ef05');
}
.icon-chat-bubble-square-edit:before {
  content: unicode('ef06');
}
.icon-synchronize-timeout:before {
  content: unicode('ef07');
}
.icon-chat-bubble-circle-upload:before {
  content: unicode('ef08');
}
.icon-file-shield:before {
  content: unicode('ef09');
}
.icon-data-transfer-8:before {
  content: unicode('ef0a');
}
.icon-dial-pad:before {
  content: unicode('ef0b');
}
.icon-window-alert:before {
  content: unicode('ef0c');
}
.icon-food-crab:before {
  content: unicode('ef0d');
}
.icon-cog-box:before {
  content: unicode('ef0e');
}
.icon-places-home-4:before {
  content: unicode('ef0f');
}
.icon-compasses:before {
  content: unicode('ef10');
}
.icon-files-landscape-video:before {
  content: unicode('ef11');
}
.icon-tag-dollar:before {
  content: unicode('ef12');
}
.icon-folders:before {
  content: unicode('ef13');
}
.icon-file-statistic-new-2:before {
  content: unicode('ef14');
}
.icon-email-upload:before {
  content: unicode('ef15');
}
.icon-inbox-document:before {
  content: unicode('ef16');
}
.icon-health-x-ray:before {
  content: unicode('ef17');
}
.icon-shopping-bag-information:before {
  content: unicode('ef18');
}
.icon-file-design-box-otf:before {
  content: unicode('ef19');
}
.icon-save-water:before {
  content: unicode('ef1a');
}
.icon-smiley-smile-1:before {
  content: unicode('ef1b');
}
.icon-camera-lens-1:before {
  content: unicode('ef1c');
}
.icon-flip-down:before {
  content: unicode('ef1d');
}
.icon-files-coding-download-1:before {
  content: unicode('ef1e');
}
.icon-find-job:before {
  content: unicode('ef1f');
}
.icon-audio-control-pause:before {
  content: unicode('ef20');
}
.icon-user-graph:before {
  content: unicode('ef21');
}
.icon-files-coding-box-java:before {
  content: unicode('ef22');
}
.icon-food-pie:before {
  content: unicode('ef23');
}
.icon-window-sync:before {
  content: unicode('ef24');
}
.icon-quote-closing:before {
  content: unicode('ef25');
}
.icon-sewing-machine:before {
  content: unicode('ef26');
}
.icon-dna:before {
  content: unicode('ef27');
}
.icon-moon-last-quarter:before {
  content: unicode('ef28');
}
.icon-file-check-2:before {
  content: unicode('ef29');
}
.icon-location-globe-information:before {
  content: unicode('ef2a');
}
.icon-tag-alert:before {
  content: unicode('ef2b');
}
.icon-new-square:before {
  content: unicode('ef2c');
}
.icon-lamp-studio-1:before {
  content: unicode('ef2d');
}
.icon-file-design-box-ttf:before {
  content: unicode('ef2e');
}
.icon-religion-share-egypt-eye-2:before {
  content: unicode('ef2f');
}
.icon-places-fence:before {
  content: unicode('ef30');
}
.icon-exchange-dollar-euro:before {
  content: unicode('ef31');
}
.icon-laptop-user-1:before {
  content: unicode('ef32');
}
.icon-alarm-clock:before {
  content: unicode('ef33');
}
.icon-files-landscape:before {
  content: unicode('ef34');
}
.icon-banking-customer-service-4:before {
  content: unicode('ef35');
}
.icon-computer-chip-2-core:before {
  content: unicode('ef36');
}
.icon-server-favorite-star-1:before {
  content: unicode('ef37');
}
.icon-grid-edit:before {
  content: unicode('ef38');
}
.icon-phone-signal-low:before {
  content: unicode('ef39');
}
.icon-camera-brightness-night:before {
  content: unicode('ef3a');
}
.icon-slider-1:before {
  content: unicode('ef3b');
}
.icon-server-timeout-1:before {
  content: unicode('ef3c');
}
.icon-food-broccoli:before {
  content: unicode('ef3d');
}
.icon-bird-house:before {
  content: unicode('ef3e');
}
.icon-content-drawer-1:before {
  content: unicode('ef3f');
}
.icon-network-cable-1:before {
  content: unicode('ef40');
}
.icon-user-block:before {
  content: unicode('ef41');
}
.icon-credit-card-dollar:before {
  content: unicode('ef42');
}
.icon-camera-portrait-mode:before {
  content: unicode('ef43');
}
.icon-content-book-dollar:before {
  content: unicode('ef44');
}
.icon-file-design-box-indd:before {
  content: unicode('ef45');
}
.icon-location-pin-home-2:before {
  content: unicode('ef46');
}
.icon-server-check-1:before {
  content: unicode('ef47');
}
.icon-vector-square-1:before {
  content: unicode('ef48');
}
.icon-music-album-upload:before {
  content: unicode('ef49');
}
.icon-cloud-downloading-1:before {
  content: unicode('ef4a');
}
.icon-bonfire:before {
  content: unicode('ef4b');
}
.icon-files-coding-shield-1:before {
  content: unicode('ef4c');
}
.icon-bullet-proof-vest:before {
  content: unicode('ef4d');
}
.icon-file-zipped-subtract:before {
  content: unicode('ef4e');
}
.icon-atoms-1:before {
  content: unicode('ef4f');
}
.icon-scale-analog:before {
  content: unicode('ef50');
}
.icon-music-album-close:before {
  content: unicode('ef51');
}
.icon-skull:before {
  content: unicode('ef52');
}
.icon-data-syncing:before {
  content: unicode('ef53');
}
.icon-indent-decrease-2:before {
  content: unicode('ef54');
}
.icon-move-back-2:before {
  content: unicode('ef55');
}
.icon-network-favorite-heart:before {
  content: unicode('ef56');
}
.icon-car-muscle:before {
  content: unicode('ef57');
}
.icon-shopping-bag-alert:before {
  content: unicode('ef58');
}
.icon-paragraph:before {
  content: unicode('ef59');
}
.icon-server-close-2:before {
  content: unicode('ef5a');
}
.icon-file-favorite-heart-1:before {
  content: unicode('ef5b');
}
.icon-file-close-1:before {
  content: unicode('ef5c');
}
.icon-smart-watch-square-snooze:before {
  content: unicode('ef5d');
}
.icon-food-plate-knife-fork:before {
  content: unicode('ef5e');
}
.icon-add-circle-1:before {
  content: unicode('ef5f');
}
.icon-eco-paper:before {
  content: unicode('ef60');
}
.icon-mobile-phone-ringtone:before {
  content: unicode('ef61');
}
.icon-file-images-new-1:before {
  content: unicode('ef62');
}
.icon-android-edit:before {
  content: unicode('ef63');
}
.icon-list-bullets-1:before {
  content: unicode('ef64');
}
.icon-spa-mask:before {
  content: unicode('ef65');
}
.icon-banking-spendings-3:before {
  content: unicode('ef66');
}
.icon-thermometer-decrease:before {
  content: unicode('ef67');
}
.icon-files-coding-dollar-2:before {
  content: unicode('ef68');
}
.icon-picture-layer-1:before {
  content: unicode('ef69');
}
.icon-ladle:before {
  content: unicode('ef6a');
}
.icon-wallet:before {
  content: unicode('ef6b');
}
.icon-file-office-box-zip:before {
  content: unicode('ef6c');
}
.icon-children-remote-car:before {
  content: unicode('ef6d');
}
.icon-kitchen-dining-set:before {
  content: unicode('ef6e');
}
.icon-location-pin-3:before {
  content: unicode('ef6f');
}
.icon-network-unknown:before {
  content: unicode('ef70');
}
.icon-content-view-headline:before {
  content: unicode('ef71');
}
.icon-camera-flash-on:before {
  content: unicode('ef72');
}
.icon-camper-trailer:before {
  content: unicode('ef73');
}
.icon-ticket-airplane:before {
  content: unicode('ef74');
}
.icon-folder-new:before {
  content: unicode('ef75');
}
.icon-swipe-up-1:before {
  content: unicode('ef76');
}
.icon-network-computer-1:before {
  content: unicode('ef77');
}
.icon-candle-lamp-2:before {
  content: unicode('ef78');
}
.icon-health-hospital-sign-2:before {
  content: unicode('ef79');
}
.icon-file-notes-view:before {
  content: unicode('ef7a');
}
.icon-file-office-box-doc:before {
  content: unicode('ef7b');
}
.icon-multi-platform-1:before {
  content: unicode('ef7c');
}
.icon-controller:before {
  content: unicode('ef7d');
}
.icon-upload-computer:before {
  content: unicode('ef7e');
}
.icon-business-man:before {
  content: unicode('ef7f');
}
.icon-sign-zig-zag:before {
  content: unicode('ef80');
}
.icon-file-video-box-flv:before {
  content: unicode('ef81');
}
.icon-flash:before {
  content: unicode('ef82');
}
.icon-flick-right-2:before {
  content: unicode('ef83');
}
.icon-files-coding-information-1:before {
  content: unicode('ef84');
}
.icon-angle-tool:before {
  content: unicode('ef85');
}
.icon-rewards-gift:before {
  content: unicode('ef86');
}
.icon-google-nest:before {
  content: unicode('ef87');
}
.icon-file-question-mark-1:before {
  content: unicode('ef88');
}
.icon-health-hospital-sign-3:before {
  content: unicode('ef89');
}
.icon-lunar-eclipse:before {
  content: unicode('ef8a');
}
.icon-cloud-upload-home:before {
  content: unicode('ef8b');
}
.icon-files-coding-share-2:before {
  content: unicode('ef8c');
}
.icon-window-home-1:before {
  content: unicode('ef8d');
}
.icon-money-bag-euro:before {
  content: unicode('ef8e');
}
.icon-user-lock:before {
  content: unicode('ef8f');
}
.icon-medical-cross:before {
  content: unicode('ef90');
}
.icon-credit-card-lock:before {
  content: unicode('ef91');
}
.icon-travel-camping:before {
  content: unicode('ef92');
}
.icon-location-pin-2:before {
  content: unicode('ef93');
}
.icon-login-lock:before {
  content: unicode('ef94');
}
.icon-flip-right:before {
  content: unicode('ef95');
}
.icon-car-gearbox-automatic:before {
  content: unicode('ef96');
}
.icon-uv-ray:before {
  content: unicode('ef97');
}
.icon-file-information-1:before {
  content: unicode('ef98');
}
.icon-folder-information:before {
  content: unicode('ef99');
}
.icon-banking-spendings-2:before {
  content: unicode('ef9a');
}
.icon-flick-up-1:before {
  content: unicode('ef9b');
}
.icon-window-tabs-1:before {
  content: unicode('ef9c');
}
.icon-romance-bow:before {
  content: unicode('ef9d');
}
.icon-file-statistic-information-1:before {
  content: unicode('ef9e');
}
.icon-files-coding-add-2:before {
  content: unicode('ef9f');
}
.icon-leisure-playing-cards:before {
  content: unicode('efa0');
}
.icon-window-lock-1:before {
  content: unicode('efa1');
}
.icon-file-tasks-refresh:before {
  content: unicode('efa2');
}
.icon-cloth-hanger:before {
  content: unicode('efa3');
}
.icon-sport-dumbbell-2:before {
  content: unicode('efa4');
}
.icon-flip-down-1:before {
  content: unicode('efa5');
}
.icon-location-direction-2:before {
  content: unicode('efa6');
}
.icon-shield-8:before {
  content: unicode('efa7');
}
.icon-file-audio-mid:before {
  content: unicode('efa8');
}
.icon-wifi-signal-add:before {
  content: unicode('efa9');
}
.icon-sd-card-timeout:before {
  content: unicode('efaa');
}
.icon-mobile-phone-off:before {
  content: unicode('efab');
}
.icon-chat-bubble-square-upload:before {
  content: unicode('efac');
}
.icon-mobile-phone-video:before {
  content: unicode('efad');
}
.icon-bank-1:before {
  content: unicode('efae');
}
.icon-real-estate-view:before {
  content: unicode('efaf');
}
.icon-content-box-1:before {
  content: unicode('efb0');
}
.icon-video-games-gameboy-snake:before {
  content: unicode('efb1');
}
.icon-places-taj-mahal:before {
  content: unicode('efb2');
}
.icon-shopping-bag-question-mark:before {
  content: unicode('efb3');
}
.icon-lightbulb-cash:before {
  content: unicode('efb4');
}
.icon-files-coding-sync-2:before {
  content: unicode('efb5');
}
.icon-sport-punching-bag:before {
  content: unicode('efb6');
}
.icon-file-zipped-home:before {
  content: unicode('efb7');
}
.icon-folder-subtract:before {
  content: unicode('efb8');
}
.icon-camera-landscape-mode:before {
  content: unicode('efb9');
}
.icon-file-statistic-timeout-2:before {
  content: unicode('efba');
}
.icon-smart-watch-circle-network:before {
  content: unicode('efbb');
}
.icon-tools-trowel-2:before {
  content: unicode('efbc');
}
.icon-video-call-1:before {
  content: unicode('efbd');
}
.icon-car-shield:before {
  content: unicode('efbe');
}
.icon-video-games-wall-e:before {
  content: unicode('efbf');
}
.icon-sport-weight-lifting:before {
  content: unicode('efc0');
}
.icon-paragraph-spacing:before {
  content: unicode('efc1');
}
.icon-headphone-wireless:before {
  content: unicode('efc2');
}
.icon-cursor-arrow-question-mark:before {
  content: unicode('efc3');
}
.icon-user-heart:before {
  content: unicode('efc4');
}
.icon-video-control-pause:before {
  content: unicode('efc5');
}
.icon-camera-mobile-back:before {
  content: unicode('efc6');
}
.icon-circle-cash:before {
  content: unicode('efc7');
}
.icon-shopping-cart-home:before {
  content: unicode('efc8');
}
.icon-content-book-check:before {
  content: unicode('efc9');
}
.icon-cog-check:before {
  content: unicode('efca');
}
.icon-cube:before {
  content: unicode('efcb');
}
.icon-file-dollar-1:before {
  content: unicode('efcc');
}
.icon-smart-watch-square-fitness:before {
  content: unicode('efcd');
}
.icon-cursor-touch-1:before {
  content: unicode('efce');
}
.icon-construction-shovel:before {
  content: unicode('efcf');
}
.icon-bomb:before {
  content: unicode('efd0');
}
.icon-alarm-silent:before {
  content: unicode('efd1');
}
.icon-hotel-fire-alarm:before {
  content: unicode('efd2');
}
.icon-file-office-box-key:before {
  content: unicode('efd3');
}
.icon-file-design-box-ai:before {
  content: unicode('efd4');
}
.icon-organic-coffee:before {
  content: unicode('efd5');
}
.icon-location-pin-user-2:before {
  content: unicode('efd6');
}
.icon-religion-christianity:before {
  content: unicode('efd7');
}
.icon-astronaut:before {
  content: unicode('efd8');
}
.icon-hanging-board-1:before {
  content: unicode('efd9');
}
.icon-programming-html:before {
  content: unicode('efda');
}
.icon-pencil-ruler:before {
  content: unicode('efdb');
}
.icon-smiley-silence-2:before {
  content: unicode('efdc');
}
.icon-server-edit-2:before {
  content: unicode('efdd');
}
.icon-email-send-1:before {
  content: unicode('efde');
}
.icon-laptop-home-1:before {
  content: unicode('efdf');
}
.icon-real-estate-sign-for-sale:before {
  content: unicode('efe0');
}
.icon-sign-command-1:before {
  content: unicode('efe1');
}
.icon-rewards-medal-5:before {
  content: unicode('efe2');
}
.icon-food-watermelon:before {
  content: unicode('efe3');
}
.icon-email-lock:before {
  content: unicode('efe4');
}
.icon-eco-farming:before {
  content: unicode('efe5');
}
.icon-shipping-truck:before {
  content: unicode('efe6');
}
.icon-business-graph-line-5:before {
  content: unicode('efe7');
}
.icon-file-sync:before {
  content: unicode('efe8');
}
.icon-religion-hinduism:before {
  content: unicode('efe9');
}
.icon-file-statistic-new-1:before {
  content: unicode('efea');
}
.icon-smiley-smile-2:before {
  content: unicode('efeb');
}
.icon-trolley-box-service:before {
  content: unicode('efec');
}
.icon-camera-lens-2:before {
  content: unicode('efed');
}
.icon-shopping-bag-shield:before {
  content: unicode('efee');
}
.icon-cloud-alert:before {
  content: unicode('efef');
}
.icon-lte-signal:before {
  content: unicode('eff0');
}
.icon-file-images-information:before {
  content: unicode('eff1');
}
.icon-save-nature:before {
  content: unicode('eff2');
}
.icon-chat-bubble-circle-image:before {
  content: unicode('eff3');
}
.icon-files-landscape-audio:before {
  content: unicode('eff4');
}
.icon-transfer-user-group:before {
  content: unicode('eff5');
}
.icon-cleats:before {
  content: unicode('eff6');
}
.icon-shopping-cart-close:before {
  content: unicode('eff7');
}
.icon-mobile-phone-contact-add:before {
  content: unicode('eff8');
}
.icon-file-video-upload:before {
  content: unicode('eff9');
}
.icon-files-coding-download-2:before {
  content: unicode('effa');
}
.icon-minus-front:before {
  content: unicode('effb');
}
.icon-server-bug:before {
  content: unicode('effc');
}
.icon-service:before {
  content: unicode('effd');
}
.icon-food-burger:before {
  content: unicode('effe');
}
.icon-bold:before {
  content: unicode('efff');
}
.icon-lamp-studio-2:before {
  content: unicode('f000');
}
.icon-file-check-1:before {
  content: unicode('f001');
}
.icon-beauty-shampoo:before {
  content: unicode('f002');
}
.icon-bird-cage:before {
  content: unicode('f003');
}
.icon-interface-alert-triangle:before {
  content: unicode('f004');
}
.icon-sport-skiing:before {
  content: unicode('f005');
}
.icon-server-favorite-star-2:before {
  content: unicode('f006');
}
.icon-religion-share-egypt-eye-1:before {
  content: unicode('f007');
}
.icon-box-move-all-direction:before {
  content: unicode('f008');
}
.icon-laptop-user-2:before {
  content: unicode('f009');
}
.icon-shipping-truck-delivery-time:before {
  content: unicode('f00a');
}
.icon-network-cable-2:before {
  content: unicode('f00b');
}
.icon-user-group-add:before {
  content: unicode('f00c');
}
.icon-file-tasks-dollar:before {
  content: unicode('f00d');
}
.icon-content-drawer-2:before {
  content: unicode('f00e');
}
.icon-eco-factory:before {
  content: unicode('f00f');
}
.icon-cloud-file:before {
  content: unicode('f010');
}
.icon-food-organic-bread:before {
  content: unicode('f011');
}
.icon-slider-2:before {
  content: unicode('f012');
}
.icon-server-timeout-2:before {
  content: unicode('f013');
}
.icon-cloud-downloading-2:before {
  content: unicode('f014');
}
.icon-files-coding-shield-2:before {
  content: unicode('f015');
}
.icon-content-box-3:before {
  content: unicode('f016');
}
.icon-location-pin-home-1:before {
  content: unicode('f017');
}
.icon-pound-rising:before {
  content: unicode('f018');
}
.icon-server-check-2:before {
  content: unicode('f019');
}
.icon-vector-square-2:before {
  content: unicode('f01a');
}
.icon-eco-gas-pump:before {
  content: unicode('f01b');
}
.icon-hash:before {
  content: unicode('f01c');
}
.icon-file-images-jpg:before {
  content: unicode('f01d');
}
.icon-atoms-2:before {
  content: unicode('f01e');
}
.icon-music-album-subtract:before {
  content: unicode('f01f');
}
.icon-interface-share-location:before {
  content: unicode('f020');
}
.icon-cloud-transfer-computer:before {
  content: unicode('f021');
}
.icon-video-dvd:before {
  content: unicode('f022');
}
.icon-box-upload:before {
  content: unicode('f023');
}
.icon-file-video-timeout:before {
  content: unicode('f024');
}
.icon-file-zipped-alert:before {
  content: unicode('f025');
}
.icon-server-close-1:before {
  content: unicode('f026');
}
.icon-file-favorite-heart-2:before {
  content: unicode('f027');
}
.icon-file-office-ppt:before {
  content: unicode('f028');
}
.icon-sd-card-alert:before {
  content: unicode('f029');
}
.icon-paper-cutter:before {
  content: unicode('f02a');
}
.icon-move-back-1:before {
  content: unicode('f02b');
}
.icon-indent-decrease-1:before {
  content: unicode('f02c');
}
.icon-calendar-shield:before {
  content: unicode('f02d');
}
.icon-spa-lotus-flower:before {
  content: unicode('f02e');
}
.icon-file-images-new-2:before {
  content: unicode('f02f');
}
.icon-add-circle-2:before {
  content: unicode('f030');
}
.icon-file-close-2:before {
  content: unicode('f031');
}
.icon-chat-bubbles-square:before {
  content: unicode('f032');
}
.icon-grid-timeout:before {
  content: unicode('f033');
}
.icon-pet-tick-lice:before {
  content: unicode('f034');
}
.icon-files-coding-dollar-1:before {
  content: unicode('f035');
}
.icon-eco-windmill:before {
  content: unicode('f036');
}
.icon-mobile-phone-question-mark:before {
  content: unicode('f037');
}
.icon-computer-chip-8-core:before {
  content: unicode('f038');
}
.icon-measuring-tool:before {
  content: unicode('f039');
}
.icon-window-tabs-3:before {
  content: unicode('f03a');
}
.icon-list-bullets-2:before {
  content: unicode('f03b');
}
.icon-water-droplet:before {
  content: unicode('f03c');
}
.icon-gradient:before {
  content: unicode('f03d');
}
.icon-window-timeout:before {
  content: unicode('f03e');
}
.icon-sport-iceskate-shoes:before {
  content: unicode('f03f');
}
.icon-email-image:before {
  content: unicode('f040');
}
.icon-picture-layer-2:before {
  content: unicode('f041');
}
.icon-battery-high:before {
  content: unicode('f042');
}
.icon-kitchen-blender:before {
  content: unicode('f043');
}
.icon-files-coding-box-dat:before {
  content: unicode('f044');
}
.icon-file-zipped-timeout:before {
  content: unicode('f045');
}
.icon-file-video-box-mpg:before {
  content: unicode('f046');
}
.icon-swipe-up-2:before {
  content: unicode('f047');
}
.icon-user-group-information:before {
  content: unicode('f048');
}
.icon-multi-platform-2:before {
  content: unicode('f049');
}
.icon-chat-bubble-refresh:before {
  content: unicode('f04a');
}
.icon-close-bubble:before {
  content: unicode('f04b');
}
.icon-travel-departure-cancel:before {
  content: unicode('f04c');
}
.icon-video-control-play:before {
  content: unicode('f04d');
}
.icon-file-office-pdf:before {
  content: unicode('f04e');
}
.icon-health-hospital-sign-1:before {
  content: unicode('f04f');
}
.icon-mobile-phone-share:before {
  content: unicode('f050');
}
.icon-candle-lamp-1:before {
  content: unicode('f051');
}
.icon-rewards-trophy-4:before {
  content: unicode('f052');
}
.icon-network-computer-2:before {
  content: unicode('f053');
}
.icon-list-number:before {
  content: unicode('f054');
}
.icon-romance-wedding-cake:before {
  content: unicode('f055');
}
.icon-sport-motorcycle-helmet:before {
  content: unicode('f056');
}
.icon-sport-fencing-sword:before {
  content: unicode('f057');
}
.icon-audio-jack:before {
  content: unicode('f058');
}
.icon-stair-truck:before {
  content: unicode('f059');
}
.icon-flick-right-1:before {
  content: unicode('f05a');
}
.icon-files-coding-information-2:before {
  content: unicode('f05b');
}
.icon-leisure-party-popper:before {
  content: unicode('f05c');
}
.icon-rewards-trophy-5:before {
  content: unicode('f05d');
}
.icon-window-home-2:before {
  content: unicode('f05e');
}
.icon-location-globe-timeout:before {
  content: unicode('f05f');
}
.icon-settings-1:before {
  content: unicode('f060');
}
.icon-bookmark-4:before {
  content: unicode('f061');
}
.icon-files-coding-share-1:before {
  content: unicode('f062');
}
.icon-organic-liquid:before {
  content: unicode('f063');
}
.icon-brick-wall-1:before {
  content: unicode('f064');
}
.icon-file-question-mark-2:before {
  content: unicode('f065');
}
.icon-food-citrus:before {
  content: unicode('f066');
}
.icon-phone-ring-volume:before {
  content: unicode('f067');
}
.icon-user-group-block:before {
  content: unicode('f068');
}
.icon-rewards-certified-badge:before {
  content: unicode('f069');
}
.icon-network-users:before {
  content: unicode('f06a');
}
.icon-file-information-2:before {
  content: unicode('f06b');
}
.icon-picture-layer-3:before {
  content: unicode('f06c');
}
.icon-file-timeout:before {
  content: unicode('f06d');
}
.icon-time-information:before {
  content: unicode('f06e');
}
.icon-file-audio-mp3:before {
  content: unicode('f06f');
}
.icon-location-pin-1:before {
  content: unicode('f070');
}
.icon-international-pound:before {
  content: unicode('f071');
}
.icon-window-tabs-2:before {
  content: unicode('f072');
}
.icon-nature-clover:before {
  content: unicode('f073');
}
.icon-kitchen-roller:before {
  content: unicode('f074');
}
.icon-align-landscape:before {
  content: unicode('f075');
}
.icon-list-bullets-3:before {
  content: unicode('f076');
}
.icon-travel-arrival:before {
  content: unicode('f077');
}
.icon-flick-up-2:before {
  content: unicode('f078');
}
.icon-banking-spendings-1:before {
  content: unicode('f079');
}
.icon-mobile-phone-audio:before {
  content: unicode('f07a');
}
.icon-banking-office-hours-1:before {
  content: unicode('f07b');
}
.icon-files-coding-box-csv:before {
  content: unicode('f07c');
}
.icon-window-lock-2:before {
  content: unicode('f07d');
}
.icon-toilet-roll:before {
  content: unicode('f07e');
}
.icon-content-book-disc:before {
  content: unicode('f07f');
}
.icon-file-statistic-information-2:before {
  content: unicode('f080');
}
.icon-box-favorite-heart:before {
  content: unicode('f081');
}
.icon-files-coding-add-1:before {
  content: unicode('f082');
}
.icon-scale:before {
  content: unicode('f083');
}
.icon-sport-boxing-mask:before {
  content: unicode('f084');
}
.icon-food-pear:before {
  content: unicode('f085');
}
.icon-file-notes-quill:before {
  content: unicode('f086');
}
.icon-sport-fishing-hook:before {
  content: unicode('f087');
}
.icon-interface-question-mark:before {
  content: unicode('f088');
}
.icon-health-dentist-shield:before {
  content: unicode('f089');
}
.icon-location-direction-1:before {
  content: unicode('f08a');
}
.icon-nature-butterfly:before {
  content: unicode('f08b');
}
.icon-sport-dumbbell-1:before {
  content: unicode('f08c');
}
.icon-headphone-ipod:before {
  content: unicode('f08d');
}
.icon-time-home:before {
  content: unicode('f08e');
}
.icon-keyboard-key-delete:before {
  content: unicode('f08f');
}
.icon-control-crop-area:before {
  content: unicode('f090');
}
.icon-window-html:before {
  content: unicode('f091');
}
.icon-king:before {
  content: unicode('f092');
}
.icon-chat-bubble-circle-close:before {
  content: unicode('f093');
}
.icon-area-circle:before {
  content: unicode('f094');
}
.icon-wifi-signal-alert:before {
  content: unicode('f095');
}
.icon-list-subtract:before {
  content: unicode('f096');
}
.icon-atoms-3:before {
  content: unicode('f097');
}
.icon-health-syringe:before {
  content: unicode('f098');
}
.icon-ipod:before {
  content: unicode('f099');
}
.icon-mobile-phone-dollar:before {
  content: unicode('f09a');
}
.icon-smart-watch-circle-fitness:before {
  content: unicode('f09b');
}
.icon-computer-graphic:before {
  content: unicode('f09c');
}
.icon-cloud-downloading-3:before {
  content: unicode('f09d');
}
.icon-bank-2:before {
  content: unicode('f09e');
}
.icon-content-box-2:before {
  content: unicode('f09f');
}
.icon-email-close:before {
  content: unicode('f0a0');
}
.icon-content-drawer-3:before {
  content: unicode('f0a1');
}
.icon-video-call-2:before {
  content: unicode('f0a2');
}
.icon-files-creative-commons-no-c:before {
  content: unicode('f0a3');
}
.icon-tools-trowel-1:before {
  content: unicode('f0a4');
}
.icon-file-statistic-timeout-1:before {
  content: unicode('f0a5');
}
.icon-content-filter-text:before {
  content: unicode('f0a6');
}
.icon-files-coding-sync-1:before {
  content: unicode('f0a7');
}
.icon-hotel-television-off:before {
  content: unicode('f0a8');
}
.icon-business-whiteboard:before {
  content: unicode('f0a9');
}
.icon-beaker-science:before {
  content: unicode('f0aa');
}
.icon-smart-watch-square-network:before {
  content: unicode('f0ab');
}
.icon-health-walker:before {
  content: unicode('f0ac');
}
.icon-file-dollar-2:before {
  content: unicode('f0ad');
}
.icon-cursor-touch-2:before {
  content: unicode('f0ae');
}
.icon-christmas-snowman:before {
  content: unicode('f0af');
}
.icon-yen-rising:before {
  content: unicode('f0b0');
}
.icon-network-block:before {
  content: unicode('f0b1');
}
.icon-graph-equation:before {
  content: unicode('f0b2');
}
.icon-file-audio-lock:before {
  content: unicode('f0b3');
}
.icon-food-rice:before {
  content: unicode('f0b4');
}
.icon-file-design-max:before {
  content: unicode('f0b5');
}
.icon-battery-add:before {
  content: unicode('f0b6');
}
.icon-file-tasks-favorite-star:before {
  content: unicode('f0b7');
}
.icon-door-wooden:before {
  content: unicode('f0b8');
}
.icon-religion-fish:before {
  content: unicode('f0b9');
}
.icon-water-tap:before {
  content: unicode('f0ba');
}
.icon-user-add:before {
  content: unicode('f0bb');
}
.icon-location-pin-user-1:before {
  content: unicode('f0bc');
}
.icon-smiley-smile-3:before {
  content: unicode('f0bd');
}
.icon-time-upload:before {
  content: unicode('f0be');
}
.icon-business-graph-line-4:before {
  content: unicode('f0bf');
}
.icon-hanging-board-2:before {
  content: unicode('f0c0');
}
.icon-beauty-hand-mirror:before {
  content: unicode('f0c1');
}
.icon-laptop-home-2:before {
  content: unicode('f0c2');
}
.icon-email-send-2:before {
  content: unicode('f0c3');
}
.icon-cursor-arrow-double:before {
  content: unicode('f0c4');
}
.icon-sign-command-2:before {
  content: unicode('f0c5');
}
.icon-file-notes-alert:before {
  content: unicode('f0c6');
}
.icon-camera-view-finder:before {
  content: unicode('f0c7');
}
.icon-server-edit-1:before {
  content: unicode('f0c8');
}
.icon-rewards-medal-4:before {
  content: unicode('f0c9');
}
.icon-international-euro:before {
  content: unicode('f0ca');
}
.icon-smiley-silence-1:before {
  content: unicode('f0cb');
}
.icon-moon-gibbous:before {
  content: unicode('f0cc');
}
.icon-handcuffs:before {
  content: unicode('f0cd');
}
.icon-location-pin-bar-1:before {
  content: unicode('f0ce');
}
.icon-time-dollar:before {
  content: unicode('f0cf');
}
.icon-solar-eclipse:before {
  content: unicode('f0d0');
}
.icon-close:before {
  content: unicode('f0d1');
}
.icon-file-audio-wav:before {
  content: unicode('f0d2');
}
.icon-files-coding-csv:before {
  content: unicode('f0d3');
}
.icon-water-reuse:before {
  content: unicode('f0d4');
}
.icon-telephone-booth:before {
  content: unicode('f0d5');
}
.icon-calendar-lock-2:before {
  content: unicode('f0d6');
}
.icon-data-transfer-3:before {
  content: unicode('f0d7');
}
.icon-tag-sync:before {
  content: unicode('f0d8');
}
.icon-banking-money-receive-1:before {
  content: unicode('f0d9');
}
.icon-milk-carton:before {
  content: unicode('f0da');
}
.icon-spray-bottle:before {
  content: unicode('f0db');
}
.icon-file-landscape-new-2:before {
  content: unicode('f0dc');
}
.icon-sport-football-goal:before {
  content: unicode('f0dd');
}
.icon-tag-double-1:before {
  content: unicode('f0de');
}
.icon-window-at-sign:before {
  content: unicode('f0df');
}
.icon-vote-heart-download:before {
  content: unicode('f0e0');
}
.icon-car-jeep:before {
  content: unicode('f0e1');
}
.icon-tank:before {
  content: unicode('f0e2');
}
.icon-location-pin-list-1:before {
  content: unicode('f0e3');
}
.icon-cursor-move-left-2:before {
  content: unicode('f0e4');
}
.icon-cog-magnify:before {
  content: unicode('f0e5');
}
.icon-smart-watch-square-flash:before {
  content: unicode('f0e6');
}
.icon-link-broken:before {
  content: unicode('f0e7');
}
.icon-laptop-shopping-2:before {
  content: unicode('f0e8');
}
.icon-asterisk-2:before {
  content: unicode('f0e9');
}
.icon-arrow-right-1:before {
  content: unicode('f0ea');
}
.icon-keyboard-key-empty:before {
  content: unicode('f0eb');
}
.icon-lock-5:before {
  content: unicode('f0ec');
}
.icon-organization-hierarchy-3:before {
  content: unicode('f0ed');
}
.icon-sandals:before {
  content: unicode('f0ee');
}
.icon-window-audio:before {
  content: unicode('f0ef');
}
.icon-audio-control-fast-forward:before {
  content: unicode('f0f0');
}
.icon-file-notes-favorite-heart:before {
  content: unicode('f0f1');
}
.icon-beauty-shaver:before {
  content: unicode('f0f2');
}
.icon-bitcoin-decreasing:before {
  content: unicode('f0f3');
}
.icon-video-call-computer:before {
  content: unicode('f0f4');
}
.icon-email-edit:before {
  content: unicode('f0f5');
}
.icon-user-group-wifi:before {
  content: unicode('f0f6');
}
.icon-places-warehouse-2:before {
  content: unicode('f0f7');
}
.icon-navigation-filter-plus-one:before {
  content: unicode('f0f8');
}
.icon-nurse-male:before {
  content: unicode('f0f9');
}
.icon-leisure-disco-ball:before {
  content: unicode('f0fa');
}
.icon-window-application-1:before {
  content: unicode('f0fb');
}
.icon-user-reception:before {
  content: unicode('f0fc');
}
.icon-business-receipt:before {
  content: unicode('f0fd');
}
.icon-user-timeout-1:before {
  content: unicode('f0fe');
}
.icon-cursor-move-vertical:before {
  content: unicode('f0ff');
}
.icon-romance-love-whale:before {
  content: unicode('f100');
}
.icon-text-input-area:before {
  content: unicode('f101');
}
.icon-flick-all-direction-1:before {
  content: unicode('f102');
}
.icon-calendar-airplane:before {
  content: unicode('f103');
}
.icon-content-book-subtract:before {
  content: unicode('f104');
}
.icon-expand-corner-4:before {
  content: unicode('f105');
}
.icon-cloud-sync:before {
  content: unicode('f106');
}
.icon-hotel-bath-shower:before {
  content: unicode('f107');
}
.icon-shopping-cart-question-mark:before {
  content: unicode('f108');
}
.icon-two-finger-tap-single-1:before {
  content: unicode('f109');
}
.icon-android-favorite-star:before {
  content: unicode('f10a');
}
.icon-calendar-download-1:before {
  content: unicode('f10b');
}
.icon-pet-cat:before {
  content: unicode('f10c');
}
.icon-share-radar:before {
  content: unicode('f10d');
}
.icon-organization-graph:before {
  content: unicode('f10e');
}
.icon-tram:before {
  content: unicode('f10f');
}
.icon-banking-two-factor-authentication-1:before {
  content: unicode('f110');
}
.icon-file-office-key:before {
  content: unicode('f111');
}
.icon-smart-watch-square-location:before {
  content: unicode('f112');
}
.icon-exit-sign:before {
  content: unicode('f113');
}
.icon-vote-star-subtract:before {
  content: unicode('f114');
}
.icon-hotel-sauna:before {
  content: unicode('f115');
}
.icon-vodka-bottle-heart:before {
  content: unicode('f116');
}
.icon-maths-laptop:before {
  content: unicode('f117');
}
.icon-shield-6:before {
  content: unicode('f118');
}
.icon-zoom-in:before {
  content: unicode('f119');
}
.icon-location-pin-globe-2:before {
  content: unicode('f11a');
}
.icon-tap-hold-1:before {
  content: unicode('f11b');
}
.icon-sport-rollerblade:before {
  content: unicode('f11c');
}
.icon-file-tasks-settings:before {
  content: unicode('f11d');
}
.icon-pound-decreasing:before {
  content: unicode('f11e');
}
.icon-construction-rock-driller:before {
  content: unicode('f11f');
}
.icon-banking-money-send-1:before {
  content: unicode('f120');
}
.icon-smart-watch-circle-star:before {
  content: unicode('f121');
}
.icon-shopping-cart-check:before {
  content: unicode('f122');
}
.icon-data-transfer-11:before {
  content: unicode('f123');
}
.icon-move-left-4:before {
  content: unicode('f124');
}
.icon-tap-swipe-horizontal-2:before {
  content: unicode('f125');
}
.icon-cursor-move-right-2:before {
  content: unicode('f126');
}
.icon-phone-signal-medium:before {
  content: unicode('f127');
}
.icon-banking-transfer-reccuring-2:before {
  content: unicode('f128');
}
.icon-network-settings:before {
  content: unicode('f129');
}
.icon-file-statistic-add-2:before {
  content: unicode('f12a');
}
.icon-vote-star-circle:before {
  content: unicode('f12b');
}
.icon-coins:before {
  content: unicode('f12c');
}
.icon-phone-off-circle:before {
  content: unicode('f12d');
}
.icon-files-coding-block-2:before {
  content: unicode('f12e');
}
.icon-network-location-pin:before {
  content: unicode('f12f');
}
.icon-credit-card-amex:before {
  content: unicode('f130');
}
.icon-drink-bottle-1:before {
  content: unicode('f131');
}
.icon-pencil-fill:before {
  content: unicode('f132');
}
.icon-bridge-car:before {
  content: unicode('f133');
}
.icon-travel-bag-1:before {
  content: unicode('f134');
}
.icon-drinking-fountain:before {
  content: unicode('f135');
}
.icon-price-down:before {
  content: unicode('f136');
}
.icon-navigation-filter-heart:before {
  content: unicode('f137');
}
.icon-shopping-bag-audio:before {
  content: unicode('f138');
}
.icon-cog-play:before {
  content: unicode('f139');
}
.icon-car-1:before {
  content: unicode('f13a');
}
.icon-location-path-1:before {
  content: unicode('f13b');
}
.icon-border-right:before {
  content: unicode('f13c');
}
.icon-resolution-uhd:before {
  content: unicode('f13d');
}
.icon-content-book-favorite-heart:before {
  content: unicode('f13e');
}
.icon-eco-save-animal:before {
  content: unicode('f13f');
}
.icon-focus-auto:before {
  content: unicode('f140');
}
.icon-files-coding-pl:before {
  content: unicode('f141');
}
.icon-files-coding-timeout-2:before {
  content: unicode('f142');
}
.icon-water-level-flag:before {
  content: unicode('f143');
}
.icon-banking-transfer-international-2:before {
  content: unicode('f144');
}
.icon-data-download-14:before {
  content: unicode('f145');
}
.icon-spa-flower:before {
  content: unicode('f146');
}
.icon-border-inside-horizontal:before {
  content: unicode('f147');
}
.icon-hotel-air-conditioner:before {
  content: unicode('f148');
}
.icon-space-robot-2:before {
  content: unicode('f149');
}
.icon-shopping-basket-block:before {
  content: unicode('f14a');
}
.icon-data-download-15:before {
  content: unicode('f14b');
}
.icon-email-check:before {
  content: unicode('f14c');
}
.icon-file-statistic-sync-2:before {
  content: unicode('f14d');
}
.icon-file-audio-aif:before {
  content: unicode('f14e');
}
.icon-banking-transfer-international-3:before {
  content: unicode('f14f');
}
.icon-box-add:before {
  content: unicode('f150');
}
.icon-shopping-basket-sync:before {
  content: unicode('f151');
}
.icon-share-rss-feed:before {
  content: unicode('f152');
}
.icon-location-pin-add-1:before {
  content: unicode('f153');
}
.icon-view-subtract:before {
  content: unicode('f154');
}
.icon-inbox-heart:before {
  content: unicode('f155');
}
.icon-shipping-crate:before {
  content: unicode('f156');
}
.icon-file-statistic-alert-2:before {
  content: unicode('f157');
}
.icon-content-book-6:before {
  content: unicode('f158');
}
.icon-night-hail:before {
  content: unicode('f159');
}
.icon-hide:before {
  content: unicode('f15a');
}
.icon-hotel-pets-not-allow:before {
  content: unicode('f15b');
}
.icon-time-subtract:before {
  content: unicode('f15c');
}
.icon-cursor-hold:before {
  content: unicode('f15d');
}
.icon-hotel-fireplace:before {
  content: unicode('f15e');
}
.icon-circle-speech-bubble:before {
  content: unicode('f15f');
}
.icon-user-group-favorite-star:before {
  content: unicode('f160');
}
.icon-religious-sign-2:before {
  content: unicode('f161');
}
.icon-location-globe-view:before {
  content: unicode('f162');
}
.icon-text:before {
  content: unicode('f163');
}
.icon-mobile-phone-contact-remove:before {
  content: unicode('f164');
}
.icon-file-video-home:before {
  content: unicode('f165');
}
.icon-fire-extinguisher:before {
  content: unicode('f166');
}
.icon-vote-star:before {
  content: unicode('f167');
}
.icon-cursor-finger:before {
  content: unicode('f168');
}
.icon-wormhole:before {
  content: unicode('f169');
}
.icon-kitchen-scale:before {
  content: unicode('f16a');
}
.icon-leisure-betting-chip:before {
  content: unicode('f16b');
}
.icon-mobile-phone-power:before {
  content: unicode('f16c');
}
.icon-compass-north:before {
  content: unicode('f16d');
}
.icon-banking-office-hour-4:before {
  content: unicode('f16e');
}
.icon-align-right:before {
  content: unicode('f16f');
}
.icon-banking-transfer-reccuring-3:before {
  content: unicode('f170');
}
.icon-data-transfer-10:before {
  content: unicode('f171');
}
.icon-places-temple:before {
  content: unicode('f172');
}
.icon-gold-nuggets:before {
  content: unicode('f173');
}
.icon-arrow-tab:before {
  content: unicode('f174');
}
.icon-percent-box-2:before {
  content: unicode('f175');
}
.icon-real-estate-search:before {
  content: unicode('f176');
}
.icon-health-hospital-1:before {
  content: unicode('f177');
}
.icon-view-cash-1:before {
  content: unicode('f178');
}
.icon-sport-football-field:before {
  content: unicode('f179');
}
.icon-sport-fishing:before {
  content: unicode('f17a');
}
.icon-shield-7:before {
  content: unicode('f17b');
}
.icon-switch-right:before {
  content: unicode('f17c');
}
.icon-banking-bank-account:before {
  content: unicode('f17d');
}
.icon-vote-heart-settings:before {
  content: unicode('f17e');
}
.icon-server-settings-2:before {
  content: unicode('f17f');
}
.icon-banking-international-transfer-1:before {
  content: unicode('f180');
}
.icon-file-images-box-jpg:before {
  content: unicode('f181');
}
.icon-two-finger-swipe-vertical-1:before {
  content: unicode('f182');
}
.icon-box-dollar:before {
  content: unicode('f183');
}
.icon-arrange-a-to-z:before {
  content: unicode('f184');
}
.icon-calendar-edit:before {
  content: unicode('f185');
}
.icon-smart-watch-circle-volume:before {
  content: unicode('f186');
}
.icon-login-arrow:before {
  content: unicode('f187');
}
.icon-music-album-view:before {
  content: unicode('f188');
}
.icon-shopping-bag-share:before {
  content: unicode('f189');
}
.icon-lock-door:before {
  content: unicode('f18a');
}
.icon-audio-control-rewind:before {
  content: unicode('f18b');
}
.icon-day-rainy:before {
  content: unicode('f18c');
}
.icon-pet-grass:before {
  content: unicode('f18d');
}
.icon-files-coding-subtract-1:before {
  content: unicode('f18e');
}
.icon-business-graph-bar-decrease:before {
  content: unicode('f18f');
}
.icon-tag-favorite-star:before {
  content: unicode('f190');
}
.icon-location-pin-cemetery-1:before {
  content: unicode('f191');
}
.icon-files-coding-dat:before {
  content: unicode('f192');
}
.icon-file-office-graph:before {
  content: unicode('f193');
}
.icon-file-office-zip:before {
  content: unicode('f194');
}
.icon-sunset:before {
  content: unicode('f195');
}
.icon-parking-booth:before {
  content: unicode('f196');
}
.icon-two-finger-swipe-down-2:before {
  content: unicode('f197');
}
.icon-file-tasks-upload:before {
  content: unicode('f198');
}
.icon-file-audio-edit:before {
  content: unicode('f199');
}
.icon-milk-canister:before {
  content: unicode('f19a');
}
.icon-horizontal-vertical-touch:before {
  content: unicode('f19b');
}
.icon-user-favorite-heart:before {
  content: unicode('f19c');
}
.icon-sign-garage:before {
  content: unicode('f19d');
}
.icon-shopping-trolley-upload:before {
  content: unicode('f19e');
}
.icon-shopping-bag-home:before {
  content: unicode('f19f');
}
.icon-video-control-stop:before {
  content: unicode('f1a0');
}
.icon-file-office-doc:before {
  content: unicode('f1a1');
}
.icon-castle-2:before {
  content: unicode('f1a2');
}
.icon-tag-share:before {
  content: unicode('f1a3');
}
.icon-speaker-volume-low:before {
  content: unicode('f1a4');
}
.icon-leisure-theater:before {
  content: unicode('f1a5');
}
.icon-home-fire:before {
  content: unicode('f1a6');
}
.icon-user-group-timeout:before {
  content: unicode('f1a7');
}
.icon-symbol-firewire:before {
  content: unicode('f1a8');
}
.icon-lock-4:before {
  content: unicode('f1a9');
}
.icon-paint-brush-1:before {
  content: unicode('f1aa');
}
.icon-organization-hierarchy-2:before {
  content: unicode('f1ab');
}
.icon-mountain-sun:before {
  content: unicode('f1ac');
}
.icon-banking-transfer-between-account-1:before {
  content: unicode('f1ad');
}
.icon-user-business-man:before {
  content: unicode('f1ae');
}
.icon-disc-player:before {
  content: unicode('f1af');
}
.icon-content-view-content:before {
  content: unicode('f1b0');
}
.icon-hotel-mirror:before {
  content: unicode('f1b1');
}
.icon-navigation-drawer-1:before {
  content: unicode('f1b2');
}
.icon-building-tower-1:before {
  content: unicode('f1b3');
}
.icon-smart-watch-square-sync:before {
  content: unicode('f1b4');
}
.icon-sign-parking:before {
  content: unicode('f1b5');
}
.icon-credit-card-information:before {
  content: unicode('f1b6');
}
.icon-time-add:before {
  content: unicode('f1b7');
}
.icon-file-video-dollar:before {
  content: unicode('f1b8');
}
.icon-webcam-2:before {
  content: unicode('f1b9');
}
.icon-usb-head:before {
  content: unicode('f1ba');
}
.icon-window-expand:before {
  content: unicode('f1bb');
}
.icon-window-shield:before {
  content: unicode('f1bc');
}
.icon-flick-left-2:before {
  content: unicode('f1bd');
}
.icon-money-stack:before {
  content: unicode('f1be');
}
.icon-data-transfer-2:before {
  content: unicode('f1bf');
}
.icon-file-tasks-download:before {
  content: unicode('f1c0');
}
.icon-network-download:before {
  content: unicode('f1c1');
}
.icon-file-notes-modules:before {
  content: unicode('f1c2');
}
.icon-music-album-shuffle:before {
  content: unicode('f1c3');
}
.icon-music-album-check:before {
  content: unicode('f1c4');
}
.icon-location-pin-bar-2:before {
  content: unicode('f1c5');
}
.icon-music-album:before {
  content: unicode('f1c6');
}
.icon-credit-card-settings:before {
  content: unicode('f1c7');
}
.icon-vote-heart-block:before {
  content: unicode('f1c8');
}
.icon-grid-lock:before {
  content: unicode('f1c9');
}
.icon-file-notes-share:before {
  content: unicode('f1ca');
}
.icon-file-landscape-new-1:before {
  content: unicode('f1cb');
}
.icon-rechargable-battery:before {
  content: unicode('f1cc');
}
.icon-calendar-lock-1:before {
  content: unicode('f1cd');
}
.icon-banking-money-receive-2:before {
  content: unicode('f1ce');
}
.icon-smiley-dolar:before {
  content: unicode('f1cf');
}
.icon-location-pin-list-2:before {
  content: unicode('f1d0');
}
.icon-cursor-move-left-1:before {
  content: unicode('f1d1');
}
.icon-chat-bubble-circle-home:before {
  content: unicode('f1d2');
}
.icon-compass-west:before {
  content: unicode('f1d3');
}
.icon-window-graph-pie:before {
  content: unicode('f1d4');
}
.icon-tag-double-2:before {
  content: unicode('f1d5');
}
.icon-mobile-phone-play:before {
  content: unicode('f1d6');
}
.icon-laptop-shopping-1:before {
  content: unicode('f1d7');
}
.icon-asterisk-1:before {
  content: unicode('f1d8');
}
.icon-arrow-right-2:before {
  content: unicode('f1d9');
}
.icon-paperclip:before {
  content: unicode('f1da');
}
.icon-wireless-syncing:before {
  content: unicode('f1db');
}
.icon-leisure-lava-lamp:before {
  content: unicode('f1dc');
}
.icon-arrange-z-to-a:before {
  content: unicode('f1dd');
}
.icon-food-nut:before {
  content: unicode('f1de');
}
.icon-file-images-home:before {
  content: unicode('f1df');
}
.icon-user-group-settings:before {
  content: unicode('f1e0');
}
.icon-file-tasks-information:before {
  content: unicode('f1e1');
}
.icon-calendar-pencil:before {
  content: unicode('f1e2');
}
.icon-user-headphone:before {
  content: unicode('f1e3');
}
.icon-beauty-lipstick:before {
  content: unicode('f1e4');
}
.icon-beauty-powder-mirror:before {
  content: unicode('f1e5');
}
.icon-hdr-off:before {
  content: unicode('f1e6');
}
.icon-gender-male:before {
  content: unicode('f1e7');
}
.icon-places-warehouse-1:before {
  content: unicode('f1e8');
}
.icon-exposure-level-add-subtract:before {
  content: unicode('f1e9');
}
.icon-water-tower:before {
  content: unicode('f1ea');
}
.icon-flick-all-direction-2:before {
  content: unicode('f1eb');
}
.icon-wifi-signal-infomation:before {
  content: unicode('f1ec');
}
.icon-blur-linear:before {
  content: unicode('f1ed');
}
.icon-window-application-2:before {
  content: unicode('f1ee');
}
.icon-box-home:before {
  content: unicode('f1ef');
}
.icon-real-estate-rent:before {
  content: unicode('f1f0');
}
.icon-beard:before {
  content: unicode('f1f1');
}
.icon-transfer-user-group-download:before {
  content: unicode('f1f2');
}
.icon-banking-two-factor-authentication-2:before {
  content: unicode('f1f3');
}
.icon-calendar-download-2:before {
  content: unicode('f1f4');
}
.icon-car-battery:before {
  content: unicode('f1f5');
}
.icon-prism:before {
  content: unicode('f1f6');
}
.icon-zoom-out:before {
  content: unicode('f1f7');
}
.icon-chat-bubble-square-dollar:before {
  content: unicode('f1f8');
}
.icon-shield-5:before {
  content: unicode('f1f9');
}
.icon-tap-hold-2:before {
  content: unicode('f1fa');
}
.icon-hand-remote:before {
  content: unicode('f1fb');
}
.icon-location-pin-globe-1:before {
  content: unicode('f1fc');
}
.icon-leisure-ferris-wheel:before {
  content: unicode('f1fd');
}
.icon-content-layers-show:before {
  content: unicode('f1fe');
}
.icon-food-hotdog:before {
  content: unicode('f1ff');
}
.icon-cloud-view:before {
  content: unicode('f200');
}
.icon-location-globe:before {
  content: unicode('f201');
}
.icon-wifi-signal-share:before {
  content: unicode('f202');
}
.icon-business-maze:before {
  content: unicode('f203');
}
.icon-user-box:before {
  content: unicode('f204');
}
.icon-earthquake:before {
  content: unicode('f205');
}
.icon-chemical:before {
  content: unicode('f206');
}
.icon-superscript:before {
  content: unicode('f207');
}
.icon-real-estate-dollar:before {
  content: unicode('f208');
}
.icon-data-transfer-12:before {
  content: unicode('f209');
}
.icon-keyboard-key-option:before {
  content: unicode('f20a');
}
.icon-banking-money-send-2:before {
  content: unicode('f20b');
}
.icon-battery-low:before {
  content: unicode('f20c');
}
.icon-document-shredder:before {
  content: unicode('f20d');
}
.icon-tools-screw:before {
  content: unicode('f20e');
}
.icon-file-statistic-add-1:before {
  content: unicode('f20f');
}
.icon-banking-transfer-reccuring-1:before {
  content: unicode('f210');
}
.icon-rewards-certificate:before {
  content: unicode('f211');
}
.icon-programming-graph-line:before {
  content: unicode('f212');
}
.icon-saxophone:before {
  content: unicode('f213');
}
.icon-tap-swipe-horizontal-1:before {
  content: unicode('f214');
}
.icon-cursor-move-right-1:before {
  content: unicode('f215');
}
.icon-percent:before {
  content: unicode('f216');
}
.icon-trolley-bag:before {
  content: unicode('f217');
}
.icon-file-tasks-checklist:before {
  content: unicode('f218');
}
.icon-audio-keyboard:before {
  content: unicode('f219');
}
.icon-files-coding-block-1:before {
  content: unicode('f21a');
}
.icon-auction:before {
  content: unicode('f21b');
}
.icon-location-path-2:before {
  content: unicode('f21c');
}
.icon-shopping-basket-add:before {
  content: unicode('f21d');
}
.icon-webcam-broken:before {
  content: unicode('f21e');
}
.icon-bluetooth-searching:before {
  content: unicode('f21f');
}
.icon-car-2:before {
  content: unicode('f220');
}
.icon-alarm-snooze:before {
  content: unicode('f221');
}
.icon-travel-bag-2:before {
  content: unicode('f222');
}
.icon-nurse-female:before {
  content: unicode('f223');
}
.icon-mannequin:before {
  content: unicode('f224');
}
.icon-drink-bottle-2:before {
  content: unicode('f225');
}
.icon-android-lock:before {
  content: unicode('f226');
}
.icon-calendar-star:before {
  content: unicode('f227');
}
.icon-thermometer-positive:before {
  content: unicode('f228');
}
.icon-files-coding-timeout-1:before {
  content: unicode('f229');
}
.icon-content-book-4:before {
  content: unicode('f22a');
}
.icon-shopping-bag-video:before {
  content: unicode('f22b');
}
.icon-kitchen-ladle:before {
  content: unicode('f22c');
}
.icon-kitchen-food-dome:before {
  content: unicode('f22d');
}
.icon-envelope-circle:before {
  content: unicode('f22e');
}
.icon-spa-lotion:before {
  content: unicode('f22f');
}
.icon-tag-view:before {
  content: unicode('f230');
}
.icon-space-robot-1:before {
  content: unicode('f231');
}
.icon-telephone-circle:before {
  content: unicode('f232');
}
.icon-android-timeout:before {
  content: unicode('f233');
}
.icon-location-pin-add-3:before {
  content: unicode('f234');
}
.icon-file-tasks-block:before {
  content: unicode('f235');
}
.icon-banking-transfer-international-1:before {
  content: unicode('f236');
}
.icon-location-pin-add-2:before {
  content: unicode('f237');
}
.icon-file-statistic-sync-1:before {
  content: unicode('f238');
}
.icon-mobile-phone-alert:before {
  content: unicode('f239');
}
.icon-email-skull:before {
  content: unicode('f23a');
}
.icon-files-coding-py:before {
  content: unicode('f23b');
}
.icon-files-coding-box-html:before {
  content: unicode('f23c');
}
.icon-user-group-download:before {
  content: unicode('f23d');
}
.icon-moonrise:before {
  content: unicode('f23e');
}
.icon-shopping-basket-shield:before {
  content: unicode('f23f');
}
.icon-pet-love:before {
  content: unicode('f240');
}
.icon-file-statistic-alert-1:before {
  content: unicode('f241');
}
.icon-content-book-5:before {
  content: unicode('f242');
}
.icon-grid-information:before {
  content: unicode('f243');
}
.icon-religious-sign-1:before {
  content: unicode('f244');
}
.icon-fence-caution:before {
  content: unicode('f245');
}
.icon-content-book-close:before {
  content: unicode('f246');
}
.icon-drink-bottle-3:before {
  content: unicode('f247');
}
.icon-folder-network:before {
  content: unicode('f248');
}
.icon-folder-block:before {
  content: unicode('f249');
}
.icon-location-globe-favorite-star:before {
  content: unicode('f24a');
}
.icon-car-3:before {
  content: unicode('f24b');
}
.icon-food-salt-pepper:before {
  content: unicode('f24c');
}
.icon-food-donut:before {
  content: unicode('f24d');
}
.icon-file-audio-block:before {
  content: unicode('f24e');
}
.icon-kitchen-gas-burner:before {
  content: unicode('f24f');
}
.icon-cloud-timeout:before {
  content: unicode('f250');
}
.icon-projector-screen-video:before {
  content: unicode('f251');
}
.icon-organic-milk:before {
  content: unicode('f252');
}
.icon-credit-card-download:before {
  content: unicode('f253');
}
.icon-file-video-m4v:before {
  content: unicode('f254');
}
.icon-chat-bubble-square-subtract:before {
  content: unicode('f255');
}
.icon-speech-stand:before {
  content: unicode('f256');
}
.icon-banking-money-send-3:before {
  content: unicode('f257');
}
.icon-user-hierarchy:before {
  content: unicode('f258');
}
.icon-user-edit:before {
  content: unicode('f259');
}
.icon-view-cash-2:before {
  content: unicode('f25a');
}
.icon-share-signal-around:before {
  content: unicode('f25b');
}
.icon-percent-box-1:before {
  content: unicode('f25c');
}
.icon-health-hospital-2:before {
  content: unicode('f25d');
}
.icon-file-tasks-add:before {
  content: unicode('f25e');
}
.icon-trumpet:before {
  content: unicode('f25f');
}
.icon-file-zipped-information:before {
  content: unicode('f260');
}
.icon-scooter:before {
  content: unicode('f261');
}
.icon-lightbulb-4:before {
  content: unicode('f262');
}
.icon-two-finger-swipe-vertical-2:before {
  content: unicode('f263');
}
.icon-tag-information:before {
  content: unicode('f264');
}
.icon-credit-card-edit:before {
  content: unicode('f265');
}
.icon-server-settings-1:before {
  content: unicode('f266');
}
.icon-shield-4:before {
  content: unicode('f267');
}
.icon-file-video-mov:before {
  content: unicode('f268');
}
.icon-file-zipped-share:before {
  content: unicode('f269');
}
.icon-touch-screen:before {
  content: unicode('f26a');
}
.icon-sd-card-share:before {
  content: unicode('f26b');
}
.icon-banking-two-factor-authentication-3:before {
  content: unicode('f26c');
}
.icon-file-notes-favorite-star:before {
  content: unicode('f26d');
}
.icon-credit-card-upload:before {
  content: unicode('f26e');
}
.icon-location-pin-cemetery-2:before {
  content: unicode('f26f');
}
.icon-smiley-surprise:before {
  content: unicode('f270');
}
.icon-files-coding-subtract-2:before {
  content: unicode('f271');
}
.icon-sport-helmet:before {
  content: unicode('f272');
}
.icon-music-album-sync:before {
  content: unicode('f273');
}
.icon-user-bubble:before {
  content: unicode('f274');
}
.icon-content-book-upload:before {
  content: unicode('f275');
}
.icon-construction-blueprint-4:before {
  content: unicode('f276');
}
.icon-window-application-3:before {
  content: unicode('f277');
}
.icon-font-color:before {
  content: unicode('f278');
}
.icon-quill-circle:before {
  content: unicode('f279');
}
.icon-chair-barber:before {
  content: unicode('f27a');
}
.icon-html-5:before {
  content: unicode('f27b');
}
.icon-vodka-bottle:before {
  content: unicode('f27c');
}
.icon-music-album-dollar:before {
  content: unicode('f27d');
}
.icon-castle-1:before {
  content: unicode('f27e');
}
.icon-file-office-box-pdf:before {
  content: unicode('f27f');
}
.icon-photo-landscape:before {
  content: unicode('f280');
}
.icon-places-stonehenge:before {
  content: unicode('f281');
}
.icon-paint-brush-2:before {
  content: unicode('f282');
}
.icon-car-check:before {
  content: unicode('f283');
}
.icon-android-block:before {
  content: unicode('f284');
}
.icon-organization-hierarchy-1:before {
  content: unicode('f285');
}
.icon-files-coding-box-js:before {
  content: unicode('f286');
}
.icon-baby:before {
  content: unicode('f287');
}
.icon-network-shield:before {
  content: unicode('f288');
}
.icon-move-to-back:before {
  content: unicode('f289');
}
.icon-vote-heart-shield:before {
  content: unicode('f28a');
}
.icon-navigation-drawer-2:before {
  content: unicode('f28b');
}
.icon-building-tower-2:before {
  content: unicode('f28c');
}
.icon-cloud-share:before {
  content: unicode('f28d');
}
.icon-window-add:before {
  content: unicode('f28e');
}
.icon-location-globe-sync:before {
  content: unicode('f28f');
}
.icon-chat-bubble-circle-4:before {
  content: unicode('f290');
}
.icon-banking-transfer-between-account-2:before {
  content: unicode('f291');
}
.icon-mobile-phone-chat:before {
  content: unicode('f292');
}
.icon-webcam-1:before {
  content: unicode('f293');
}
.icon-sign-route-66:before {
  content: unicode('f294');
}
.icon-panorama-vertical:before {
  content: unicode('f295');
}
.icon-printer:before {
  content: unicode('f296');
}
.icon-file-office-box-ppt:before {
  content: unicode('f297');
}
.icon-banking-money-receive-3:before {
  content: unicode('f298');
}
.icon-data-transfer-1:before {
  content: unicode('f299');
}
.icon-tools-clamps:before {
  content: unicode('f29a');
}
.icon-smart-watch-circle-line-graph:before {
  content: unicode('f29b');
}
.icon-files-coding-box-rb:before {
  content: unicode('f29c');
}
.icon-camera-timer-10-seconds:before {
  content: unicode('f29d');
}
.icon-border-top:before {
  content: unicode('f29e');
}
.icon-rewards-banner-check:before {
  content: unicode('f29f');
}
.icon-flick-left-1:before {
  content: unicode('f2a0');
}
.icon-shopping-basket-view:before {
  content: unicode('f2a1');
}
.icon-box-timeout:before {
  content: unicode('f2a2');
}
.icon-operator-male:before {
  content: unicode('f2a3');
}
.icon-window-cloud:before {
  content: unicode('f2a4');
}
.icon-keyboard-key-eject:before {
  content: unicode('f2a5');
}
.icon-file-design-box-3ds:before {
  content: unicode('f2a6');
}
.icon-redo:before {
  content: unicode('f2a7');
}
.icon-css-3:before {
  content: unicode('f2a8');
}
.icon-tag-upload:before {
  content: unicode('f2a9');
}
.icon-smart-watch-square-email:before {
  content: unicode('f2aa');
}
.icon-cloud-add:before {
  content: unicode('f2ab');
}
.icon-space-gun:before {
  content: unicode('f2ac');
}
.icon-shuriken:before {
  content: unicode('f2ad');
}
.icon-folder-document:before {
  content: unicode('f2ae');
}
.icon-files-coding-refresh-1:before {
  content: unicode('f2af');
}
.icon-mail-refresh-1:before {
  content: unicode('f2b0');
}
.icon-car-lock:before {
  content: unicode('f2b1');
}
.icon-files-coding-box-py:before {
  content: unicode('f2b2');
}
.icon-mobile-phone-broken:before {
  content: unicode('f2b3');
}
.icon-arrow-up-right:before {
  content: unicode('f2b4');
}
.icon-sim-card:before {
  content: unicode('f2b5');
}
.icon-truck:before {
  content: unicode('f2b6');
}
.icon-data-transfer-5:before {
  content: unicode('f2b7');
}
.icon-banking-bill-send:before {
  content: unicode('f2b8');
}
.icon-transfer-computer-satellite:before {
  content: unicode('f2b9');
}
.icon-romance-hot:before {
  content: unicode('f2ba');
}
.icon-molotov-cocktail:before {
  content: unicode('f2bb');
}
.icon-cloud-block:before {
  content: unicode('f2bc');
}
.icon-location-pin-bank-1:before {
  content: unicode('f2bd');
}
.icon-chat-bubble-circle-dollar:before {
  content: unicode('f2be');
}
.icon-kitchen-heat-high:before {
  content: unicode('f2bf');
}
.icon-places-christ-the-redeemer:before {
  content: unicode('f2c0');
}
.icon-files-3:before {
  content: unicode('f2c1');
}
.icon-window-reduce-up:before {
  content: unicode('f2c2');
}
.icon-cloud-upload-mobile:before {
  content: unicode('f2c3');
}
.icon-treasure-chest-close:before {
  content: unicode('f2c4');
}
.icon-business-graph-bar-increase:before {
  content: unicode('f2c5');
}
.icon-eco-tag:before {
  content: unicode('f2c6');
}
.icon-server-view-1:before {
  content: unicode('f2c7');
}
.icon-cloudy-snow-storm:before {
  content: unicode('f2c8');
}
.icon-music-album-settings:before {
  content: unicode('f2c9');
}
.icon-border-inside:before {
  content: unicode('f2ca');
}
.icon-network-question-mark:before {
  content: unicode('f2cb');
}
.icon-smiley-smile-8:before {
  content: unicode('f2cc');
}
.icon-audio-control-eject:before {
  content: unicode('f2cd');
}
.icon-clapboard-rewind:before {
  content: unicode('f2ce');
}
.icon-soda-can-3:before {
  content: unicode('f2cf');
}
.icon-window-list:before {
  content: unicode('f2d0');
}
.icon-programming-bug-1:before {
  content: unicode('f2d1');
}
.icon-two-finger-swipe-horizontal-2:before {
  content: unicode('f2d2');
}
.icon-device-wireless:before {
  content: unicode('f2d3');
}
.icon-file-audio-favorite-star:before {
  content: unicode('f2d4');
}
.icon-pen-tool:before {
  content: unicode('f2d5');
}
.icon-time-sync:before {
  content: unicode('f2d6');
}
.icon-passport-1:before {
  content: unicode('f2d7');
}
.icon-magic-wand-1:before {
  content: unicode('f2d8');
}
.icon-wine-glass:before {
  content: unicode('f2d9');
}
.icon-server-1:before {
  content: unicode('f2da');
}
.icon-food-chili-pepper:before {
  content: unicode('f2db');
}
.icon-heater-2:before {
  content: unicode('f2dc');
}
.icon-swipe-all-direction-2:before {
  content: unicode('f2dd');
}
.icon-wifi-signal-dollar:before {
  content: unicode('f2de');
}
.icon-pound-price:before {
  content: unicode('f2df');
}
.icon-food-croissant:before {
  content: unicode('f2e0');
}
.icon-strike-through-2:before {
  content: unicode('f2e1');
}
.icon-harddisk-2:before {
  content: unicode('f2e2');
}
.icon-at-sign:before {
  content: unicode('f2e3');
}
.icon-lock-3:before {
  content: unicode('f2e4');
}
.icon-coffee-cup-1:before {
  content: unicode('f2e5');
}
.icon-files-coding-dmg:before {
  content: unicode('f2e6');
}
.icon-file-zipped-view:before {
  content: unicode('f2e7');
}
.icon-business-scale-1:before {
  content: unicode('f2e8');
}
.icon-banking-bill:before {
  content: unicode('f2e9');
}
.icon-box-check:before {
  content: unicode('f2ea');
}
.icon-water-level-up:before {
  content: unicode('f2eb');
}
.icon-pen-brush:before {
  content: unicode('f2ec');
}
.icon-plug-1:before {
  content: unicode('f2ed');
}
.icon-health-graph-2:before {
  content: unicode('f2ee');
}
.icon-religion-bible:before {
  content: unicode('f2ef');
}
.icon-android-share:before {
  content: unicode('f2f0');
}
.icon-monocle:before {
  content: unicode('f2f1');
}
.icon-user-group-shield:before {
  content: unicode('f2f2');
}
.icon-expand-corner-2:before {
  content: unicode('f2f3');
}
.icon-kitchen-frying-machine:before {
  content: unicode('f2f4');
}
.icon-hotel-luggage-lock:before {
  content: unicode('f2f5');
}
.icon-camera-live-view-on:before {
  content: unicode('f2f6');
}
.icon-places-park:before {
  content: unicode('f2f7');
}
.icon-move-forward-1:before {
  content: unicode('f2f8');
}
.icon-window-application-7:before {
  content: unicode('f2f9');
}
.icon-smart-watch-circle-email:before {
  content: unicode('f2fa');
}
.icon-shopping-cart-view:before {
  content: unicode('f2fb');
}
.icon-grid-add:before {
  content: unicode('f2fc');
}
.icon-camera-pause:before {
  content: unicode('f2fd');
}
.icon-inbox-video:before {
  content: unicode('f2fe');
}
.icon-kitchen-frying-pan:before {
  content: unicode('f2ff');
}
.icon-file-upload-2:before {
  content: unicode('f300');
}
.icon-business-graph-pie-1:before {
  content: unicode('f301');
}
.icon-mobile-phone-vibrate:before {
  content: unicode('f302');
}
.icon-tap-swipe-down-1:before {
  content: unicode('f303');
}
.icon-send-to-front:before {
  content: unicode('f304');
}
.icon-receipt-1:before {
  content: unicode('f305');
}
.icon-file-audio-information:before {
  content: unicode('f306');
}
.icon-police-officer-1:before {
  content: unicode('f307');
}
.icon-file-zipped-block:before {
  content: unicode('f308');
}
.icon-sd-card-block:before {
  content: unicode('f309');
}
.icon-file-new-2:before {
  content: unicode('f30a');
}
.icon-banking-office-hour-3:before {
  content: unicode('f30b');
}
.icon-box-favorite-star:before {
  content: unicode('f30c');
}
.icon-keyboard-key-backtab:before {
  content: unicode('f30d');
}
.icon-synchronize-alert:before {
  content: unicode('f30e');
}
.icon-file-zipped-add:before {
  content: unicode('f30f');
}
.icon-move-left-2:before {
  content: unicode('f310');
}
.icon-location-globe-refresh:before {
  content: unicode('f311');
}
.icon-cloud-information:before {
  content: unicode('f312');
}
.icon-chat-bubble-circle-favorite-star:before {
  content: unicode('f313');
}
.icon-files-coding-js:before {
  content: unicode('f314');
}
.icon-flip-up:before {
  content: unicode('f315');
}
.icon-window-programming:before {
  content: unicode('f316');
}
.icon-mailbox-1:before {
  content: unicode('f317');
}
.icon-nature-jellyfish:before {
  content: unicode('f318');
}
.icon-eco-recycle-bag:before {
  content: unicode('f319');
}
.icon-swipe-vertical-1:before {
  content: unicode('f31a');
}
.icon-car-7:before {
  content: unicode('f31b');
}
.icon-text-input-2:before {
  content: unicode('f31c');
}
.icon-baby-stacker:before {
  content: unicode('f31d');
}
.icon-smart-watch-square-navigation:before {
  content: unicode('f31e');
}
.icon-shield-viking:before {
  content: unicode('f31f');
}
.icon-umbrella-close:before {
  content: unicode('f320');
}
.icon-intersect:before {
  content: unicode('f321');
}
.icon-file-block-1:before {
  content: unicode('f322');
}
.icon-file-design-ai:before {
  content: unicode('f323');
}
.icon-location-pin-skull-1:before {
  content: unicode('f324');
}
.icon-content-book-edit:before {
  content: unicode('f325');
}
.icon-swipe-down-1:before {
  content: unicode('f326');
}
.icon-data-download-12:before {
  content: unicode('f327');
}
.icon-chat-bubble-square-favorite-star:before {
  content: unicode('f328');
}
.icon-banking-buy-airtime-2:before {
  content: unicode('f329');
}
.icon-laptop-heart-2:before {
  content: unicode('f32a');
}
.icon-car-fire:before {
  content: unicode('f32b');
}
.icon-time-close:before {
  content: unicode('f32c');
}
.icon-keyboard-key-shift:before {
  content: unicode('f32d');
}
.icon-banking-transfer-international-4:before {
  content: unicode('f32e');
}
.icon-banking-bill-pay-to-someone:before {
  content: unicode('f32f');
}
.icon-file-audio-share:before {
  content: unicode('f330');
}
.icon-check-list:before {
  content: unicode('f331');
}
.icon-server-block-2:before {
  content: unicode('f332');
}
.icon-files-coding-rb:before {
  content: unicode('f333');
}
.icon-folder-share:before {
  content: unicode('f334');
}
.icon-content-book-1:before {
  content: unicode('f335');
}
.icon-cursor-hand:before {
  content: unicode('f336');
}
.icon-bacteria:before {
  content: unicode('f337');
}
.icon-chat-bubble-square-alert-1:before {
  content: unicode('f338');
}
.icon-sofa-1:before {
  content: unicode('f339');
}
.icon-alarm-sound:before {
  content: unicode('f33a');
}
.icon-arrow-left-2:before {
  content: unicode('f33b');
}
.icon-pencil-write:before {
  content: unicode('f33c');
}
.icon-file-home-2:before {
  content: unicode('f33d');
}
.icon-camera-rewind:before {
  content: unicode('f33e');
}
.icon-user-group-alert:before {
  content: unicode('f33f');
}
.icon-grid-refresh:before {
  content: unicode('f340');
}
.icon-sport-takraw:before {
  content: unicode('f341');
}
.icon-design-application:before {
  content: unicode('f342');
}
.icon-tap-swipe-all-direction-1:before {
  content: unicode('f343');
}
.icon-banking-buy-airtime-3:before {
  content: unicode('f344');
}
.icon-data-download-13:before {
  content: unicode('f345');
}
.icon-hat-beanie-1:before {
  content: unicode('f346');
}
.icon-window-reduce-right:before {
  content: unicode('f347');
}
.icon-network-forward:before {
  content: unicode('f348');
}
.icon-pet-yarn:before {
  content: unicode('f349');
}
.icon-file-notes-home:before {
  content: unicode('f34a');
}
.icon-banking-branches:before {
  content: unicode('f34b');
}
.icon-file-tasks-share:before {
  content: unicode('f34c');
}
.icon-tap-single-2:before {
  content: unicode('f34d');
}
.icon-location-pin-gas-station-1:before {
  content: unicode('f34e');
}
.icon-server-subtract-1:before {
  content: unicode('f34f');
}
.icon-file-video-refresh:before {
  content: unicode('f350');
}
.icon-speaker-volume-medium:before {
  content: unicode('f351');
}
.icon-sport-martial-art:before {
  content: unicode('f352');
}
.icon-file-statistic-lock-1:before {
  content: unicode('f353');
}
.icon-coffin:before {
  content: unicode('f354');
}
.icon-car-6:before {
  content: unicode('f355');
}
.icon-location-pin-network-1:before {
  content: unicode('f356');
}
.icon-eco-electric-bike:before {
  content: unicode('f357');
}
.icon-video-games-angry-bird:before {
  content: unicode('f358');
}
.icon-business-graph-bar-1:before {
  content: unicode('f359');
}
.icon-real-estate-check:before {
  content: unicode('f35a');
}
.icon-at-sign-stamp:before {
  content: unicode('f35b');
}
.icon-wifi-signal-block:before {
  content: unicode('f35c');
}
.icon-banking-money-send-6:before {
  content: unicode('f35d');
}
.icon-car-rv:before {
  content: unicode('f35e');
}
.icon-file-lock-2:before {
  content: unicode('f35f');
}
.icon-window-maximize:before {
  content: unicode('f360');
}
.icon-move-left-3:before {
  content: unicode('f361');
}
.icon-folder-audio:before {
  content: unicode('f362');
}
.icon-sign-no-food-allowed-2:before {
  content: unicode('f363');
}
.icon-banking-office-hour-2:before {
  content: unicode('f364');
}
.icon-file-new-3:before {
  content: unicode('f365');
}
.icon-file-statistic-favorite-heart-1:before {
  content: unicode('f366');
}
.icon-vote-star-box:before {
  content: unicode('f367');
}
.icon-navigation-filter-video:before {
  content: unicode('f368');
}
.icon-hotel-fan:before {
  content: unicode('f369');
}
.icon-music-album-download:before {
  content: unicode('f36a');
}
.icon-computer-screen-2:before {
  content: unicode('f36b');
}
.icon-tools-plunger:before {
  content: unicode('f36c');
}
.icon-pencil-1:before {
  content: unicode('f36d');
}
.icon-smart-glasses:before {
  content: unicode('f36e');
}
.icon-window-refresh:before {
  content: unicode('f36f');
}
.icon-smiley-love:before {
  content: unicode('f370');
}
.icon-layout-none:before {
  content: unicode('f371');
}
.icon-circle-graph-bar-2:before {
  content: unicode('f372');
}
.icon-file-statistic-home-1:before {
  content: unicode('f373');
}
.icon-file-zipped-refresh:before {
  content: unicode('f374');
}
.icon-health-mortar-pestle:before {
  content: unicode('f375');
}
.icon-location-globe-shield:before {
  content: unicode('f376');
}
.icon-lightbulb-1:before {
  content: unicode('f377');
}
.icon-android-shield:before {
  content: unicode('f378');
}
.icon-shield-1:before {
  content: unicode('f379');
}
.icon-car-gate:before {
  content: unicode('f37a');
}
.icon-construction-blueprint-1:before {
  content: unicode('f37b');
}
.icon-network-alert:before {
  content: unicode('f37c');
}
.icon-video-control-rewind:before {
  content: unicode('f37d');
}
.icon-add-2:before {
  content: unicode('f37e');
}
.icon-file-images-box-png:before {
  content: unicode('f37f');
}
.icon-window-application-6:before {
  content: unicode('f380');
}
.icon-shopping-basket-1:before {
  content: unicode('f381');
}
.icon-microphone-on:before {
  content: unicode('f382');
}
.icon-harp:before {
  content: unicode('f383');
}
.icon-files-coding-upload-1:before {
  content: unicode('f384');
}
.icon-health-graph-3:before {
  content: unicode('f385');
}
.icon-file-design-ttf:before {
  content: unicode('f386');
}
.icon-file-zipped-shield:before {
  content: unicode('f387');
}
.icon-expand-corner-3:before {
  content: unicode('f388');
}
.icon-cursor-move-all-direction-2:before {
  content: unicode('f389');
}
.icon-file-refresh-2:before {
  content: unicode('f38a');
}
.icon-list-add:before {
  content: unicode('f38b');
}
.icon-lock-2:before {
  content: unicode('f38c');
}
.icon-phone-close:before {
  content: unicode('f38d');
}
.icon-file-video-favorite-star:before {
  content: unicode('f38e');
}
.icon-sign-toilet:before {
  content: unicode('f38f');
}
.icon-video-control-eject:before {
  content: unicode('f390');
}
.icon-music-note-1:before {
  content: unicode('f391');
}
.icon-swipe-all-direction-3:before {
  content: unicode('f392');
}
.icon-sd-card-favorite-heart:before {
  content: unicode('f393');
}
.icon-check-bubble:before {
  content: unicode('f394');
}
.icon-sheriff-star:before {
  content: unicode('f395');
}
.icon-unite:before {
  content: unicode('f396');
}
.icon-chat-bubble-square-lock:before {
  content: unicode('f397');
}
.icon-painting-canvas:before {
  content: unicode('f398');
}
.icon-mobile-phone-download-1:before {
  content: unicode('f399');
}
.icon-sport-hockey:before {
  content: unicode('f39a');
}
.icon-user-target:before {
  content: unicode('f39b');
}
.icon-sign-no-smoking:before {
  content: unicode('f39c');
}
.icon-soda-can-2:before {
  content: unicode('f39d');
}
.icon-boat-ship-1:before {
  content: unicode('f39e');
}
.icon-smiley-smile-9:before {
  content: unicode('f39f');
}
.icon-leisure-circuit-tent:before {
  content: unicode('f3a0');
}
.icon-chef-hat:before {
  content: unicode('f3a1');
}
.icon-vote-heart-share:before {
  content: unicode('f3a2');
}
.icon-files-coding-settings-2:before {
  content: unicode('f3a3');
}
.icon-file-notes-block:before {
  content: unicode('f3a4');
}
.icon-line-spacing:before {
  content: unicode('f3a5');
}
.icon-organization-file-arborescence-1:before {
  content: unicode('f3a6');
}
.icon-pound-coin:before {
  content: unicode('f3a7');
}
.icon-temperature-celcius:before {
  content: unicode('f3a8');
}
.icon-files-2:before {
  content: unicode('f3a9');
}
.icon-telephone-dial:before {
  content: unicode('f3aa');
}
.icon-computer-share:before {
  content: unicode('f3ab');
}
.icon-chat-bubble-circle-1:before {
  content: unicode('f3ac');
}
.icon-food-truck:before {
  content: unicode('f3ad');
}
.icon-thermometer-hot:before {
  content: unicode('f3ae');
}
.icon-user-sun:before {
  content: unicode('f3af');
}
.icon-wrench:before {
  content: unicode('f3b0');
}
.icon-file-design-otf:before {
  content: unicode('f3b1');
}
.icon-travel-snorkel:before {
  content: unicode('f3b2');
}
.icon-greek-column:before {
  content: unicode('f3b3');
}
.icon-wheel-brace:before {
  content: unicode('f3b4');
}
.icon-nature-moon-star:before {
  content: unicode('f3b5');
}
.icon-cd-box-2:before {
  content: unicode('f3b6');
}
.icon-data-transfer-4:before {
  content: unicode('f3b7');
}
.icon-coin-purse-2:before {
  content: unicode('f3b8');
}
.icon-pyramid:before {
  content: unicode('f3b9');
}
.icon-wifi-signal-favorite-star:before {
  content: unicode('f3ba');
}
.icon-user-information:before {
  content: unicode('f3bb');
}
.icon-vote-hot-box:before {
  content: unicode('f3bc');
}
.icon-tsunami:before {
  content: unicode('f3bd');
}
.icon-data-transfer-6:before {
  content: unicode('f3be');
}
.icon-camera-sync:before {
  content: unicode('f3bf');
}
.icon-banking-money-receive-4:before {
  content: unicode('f3c0');
}
.icon-road-intersection:before {
  content: unicode('f3c1');
}
.icon-files-coding-css:before {
  content: unicode('f3c2');
}
.icon-shopping-cart-dollar:before {
  content: unicode('f3c3');
}
.icon-gauge:before {
  content: unicode('f3c4');
}
.icon-file-design-box-max:before {
  content: unicode('f3c5');
}
.icon-television-stand:before {
  content: unicode('f3c6');
}
.icon-files-coding-refresh-2:before {
  content: unicode('f3c7');
}
.icon-mobile-phone-sync-remove:before {
  content: unicode('f3c8');
}
.icon-camera-timer:before {
  content: unicode('f3c9');
}
.icon-file-video-check:before {
  content: unicode('f3ca');
}
.icon-location-pin-bank-2:before {
  content: unicode('f3cb');
}
.icon-truck-dump:before {
  content: unicode('f3cc');
}
.icon-shopping-basket-information:before {
  content: unicode('f3cd');
}
.icon-oil-jerry-can:before {
  content: unicode('f3ce');
}
.icon-sport-golf:before {
  content: unicode('f3cf');
}
.icon-server-view-2:before {
  content: unicode('f3d0');
}
.icon-file-zipped-settings:before {
  content: unicode('f3d1');
}
.icon-chat-bubble-circle-3:before {
  content: unicode('f3d2');
}
.icon-two-finger-swipe-horizontal-1:before {
  content: unicode('f3d3');
}
.icon-programming-bug-2:before {
  content: unicode('f3d4');
}
.icon-hotel-luggage-service:before {
  content: unicode('f3d5');
}
.icon-upload-harddisk:before {
  content: unicode('f3d6');
}
.icon-file-office-box-pps:before {
  content: unicode('f3d7');
}
.icon-window-block:before {
  content: unicode('f3d8');
}
.icon-swipe-all-direction-1:before {
  content: unicode('f3d9');
}
.icon-server-2:before {
  content: unicode('f3da');
}
.icon-christmas-party-hat:before {
  content: unicode('f3db');
}
.icon-heater-1:before {
  content: unicode('f3dc');
}
.icon-network-global:before {
  content: unicode('f3dd');
}
.icon-magic-wand-2:before {
  content: unicode('f3de');
}
.icon-files-creative-commons-no-commercial:before {
  content: unicode('f3df');
}
.icon-zoom-area-out:before {
  content: unicode('f3e0');
}
.icon-passport-2:before {
  content: unicode('f3e1');
}
.icon-business-scale-2:before {
  content: unicode('f3e2');
}
.icon-prisoner:before {
  content: unicode('f3e3');
}
.icon-leisure-tic-tac-toe:before {
  content: unicode('f3e4');
}
.icon-user-alert:before {
  content: unicode('f3e5');
}
.icon-coffee-cup-2:before {
  content: unicode('f3e6');
}
.icon-strike-through-1:before {
  content: unicode('f3e7');
}
.icon-harddisk-1:before {
  content: unicode('f3e8');
}
.icon-coupon-percent:before {
  content: unicode('f3e9');
}
.icon-music-note-3:before {
  content: unicode('f3ea');
}
.icon-match-stick:before {
  content: unicode('f3eb');
}
.icon-religion-egypt-eye:before {
  content: unicode('f3ec');
}
.icon-expand-corner-1:before {
  content: unicode('f3ed');
}
.icon-health-graph-1:before {
  content: unicode('f3ee');
}
.icon-hat-lady:before {
  content: unicode('f3ef');
}
.icon-plug-2:before {
  content: unicode('f3f0');
}
.icon-food-barbecue:before {
  content: unicode('f3f1');
}
.icon-vote-heart-edit:before {
  content: unicode('f3f2');
}
.icon-moon-waxing-crescent:before {
  content: unicode('f3f3');
}
.icon-folder-favorite-heart:before {
  content: unicode('f3f4');
}
.icon-network-lock:before {
  content: unicode('f3f5');
}
.icon-move-forward-2:before {
  content: unicode('f3f6');
}
.icon-window-application-4:before {
  content: unicode('f3f7');
}
.icon-construction-blueprint-3:before {
  content: unicode('f3f8');
}
.icon-folder-download:before {
  content: unicode('f3f9');
}
.icon-tag-block:before {
  content: unicode('f3fa');
}
.icon-grid-close:before {
  content: unicode('f3fb');
}
.icon-file-upload-1:before {
  content: unicode('f3fc');
}
.icon-business-graph-pie-2:before {
  content: unicode('f3fd');
}
.icon-lightbulb-3:before {
  content: unicode('f3fe');
}
.icon-sport-rugby:before {
  content: unicode('f3ff');
}
.icon-cheque-sign:before {
  content: unicode('f400');
}
.icon-romance-find-love:before {
  content: unicode('f401');
}
.icon-hotel-towel:before {
  content: unicode('f402');
}
.icon-user-refresh-1:before {
  content: unicode('f403');
}
.icon-shield-3:before {
  content: unicode('f404');
}
.icon-thread:before {
  content: unicode('f405');
}
.icon-receipt-2:before {
  content: unicode('f406');
}
.icon-space-rocket:before {
  content: unicode('f407');
}
.icon-tap-swipe-down-2:before {
  content: unicode('f408');
}
.icon-sign-danger:before {
  content: unicode('f409');
}
.icon-pencil-3:before {
  content: unicode('f40a');
}
.icon-photocopy-machine:before {
  content: unicode('f40b');
}
.icon-gender-female-male:before {
  content: unicode('f40c');
}
.icon-shopping-cart-sync:before {
  content: unicode('f40d');
}
.icon-slippers:before {
  content: unicode('f40e');
}
.icon-file-new-1:before {
  content: unicode('f40f');
}
.icon-shopping-bag-block:before {
  content: unicode('f410');
}
.icon-file-video-avi:before {
  content: unicode('f411');
}
.icon-food-honey-jar:before {
  content: unicode('f412');
}
.icon-police-officer-2:before {
  content: unicode('f413');
}
.icon-computer-chip-shield:before {
  content: unicode('f414');
}
.icon-time-view:before {
  content: unicode('f415');
}
.icon-window-users:before {
  content: unicode('f416');
}
.icon-health-bandage:before {
  content: unicode('f417');
}
.icon-sport-football:before {
  content: unicode('f418');
}
.icon-window-auction:before {
  content: unicode('f419');
}
.icon-mailbox-2:before {
  content: unicode('f41a');
}
.icon-banking-money-send-4:before {
  content: unicode('f41b');
}
.icon-credit-card-question-mark:before {
  content: unicode('f41c');
}
.icon-files-coding-iso:before {
  content: unicode('f41d');
}
.icon-inbox-audio:before {
  content: unicode('f41e');
}
.icon-food-sushi:before {
  content: unicode('f41f');
}
.icon-move-left-1:before {
  content: unicode('f420');
}
.icon-file-zipped-sync:before {
  content: unicode('f421');
}
.icon-folder-lock:before {
  content: unicode('f422');
}
.icon-file-images-shield:before {
  content: unicode('f423');
}
.icon-email-question-mark:before {
  content: unicode('f424');
}
.icon-kitchen-turning-spatula:before {
  content: unicode('f425');
}
.icon-sd-card-favorite-star:before {
  content: unicode('f426');
}
.icon-video-games-tamagochi:before {
  content: unicode('f427');
}
.icon-reflect-copy-left:before {
  content: unicode('f428');
}
.icon-leisure-dj-booth:before {
  content: unicode('f429');
}
.icon-text-input-1:before {
  content: unicode('f42a');
}
.icon-chat-bubble-circle-alert-1:before {
  content: unicode('f42b');
}
.icon-files-coding-php:before {
  content: unicode('f42c');
}
.icon-swipe-vertical-2:before {
  content: unicode('f42d');
}
.icon-car-4:before {
  content: unicode('f42e');
}
.icon-programming-script:before {
  content: unicode('f42f');
}
.icon-swipe-down-2:before {
  content: unicode('f430');
}
.icon-sd-card-refresh:before {
  content: unicode('f431');
}
.icon-projector-screen-location:before {
  content: unicode('f432');
}
.icon-control-split:before {
  content: unicode('f433');
}
.icon-location-pin-9:before {
  content: unicode('f434');
}
.icon-shopping-basket-share:before {
  content: unicode('f435');
}
.icon-location-pin-skull-2:before {
  content: unicode('f436');
}
.icon-sd-card-lock:before {
  content: unicode('f437');
}
.icon-file-tasks-favorite-heart:before {
  content: unicode('f438');
}
.icon-file-block-2:before {
  content: unicode('f439');
}
.icon-server-block-1:before {
  content: unicode('f43a');
}
.icon-file-tasks-edit:before {
  content: unicode('f43b');
}
.icon-hot-air-balloon:before {
  content: unicode('f43c');
}
.icon-laptop-heart-1:before {
  content: unicode('f43d');
}
.icon-data-download-11:before {
  content: unicode('f43e');
}
.icon-file-notes-hand:before {
  content: unicode('f43f');
}
.icon-banking-buy-airtime-1:before {
  content: unicode('f440');
}
.icon-file-hand:before {
  content: unicode('f441');
}
.icon-chat-bubble-square-alert-2:before {
  content: unicode('f442');
}
.icon-mobile-phone-edit:before {
  content: unicode('f443');
}
.icon-banking-bill-overdue:before {
  content: unicode('f444');
}
.icon-files-coding-box-app:before {
  content: unicode('f445');
}
.icon-paint-roll:before {
  content: unicode('f446');
}
.icon-content-book-2:before {
  content: unicode('f447');
}
.icon-sign-euro:before {
  content: unicode('f448');
}
.icon-file-home-1:before {
  content: unicode('f449');
}
.icon-romance-arrow-heart:before {
  content: unicode('f44a');
}
.icon-content-book-3:before {
  content: unicode('f44b');
}
.icon-arrow-left-1:before {
  content: unicode('f44c');
}
.icon-sofa-2:before {
  content: unicode('f44d');
}
.icon-data-download-10:before {
  content: unicode('f44e');
}
.icon-hat-beanie-2:before {
  content: unicode('f44f');
}
.icon-location-pin-8:before {
  content: unicode('f450');
}
.icon-location-pin-gas-station-2:before {
  content: unicode('f451');
}
.icon-server-subtract-2:before {
  content: unicode('f452');
}
.icon-tap-single-1:before {
  content: unicode('f453');
}
.icon-calendar-information:before {
  content: unicode('f454');
}
.icon-origami-paper-bird:before {
  content: unicode('f455');
}
.icon-file-audio-upload:before {
  content: unicode('f456');
}
.icon-user-favorite-star:before {
  content: unicode('f457');
}
.icon-smart-watch-square:before {
  content: unicode('f458');
}
.icon-business-graph-bar-2:before {
  content: unicode('f459');
}
.icon-car-5:before {
  content: unicode('f45a');
}
.icon-location-pin-network-2:before {
  content: unicode('f45b');
}
.icon-folder-settings:before {
  content: unicode('f45c');
}
.icon-file-statistic-lock-2:before {
  content: unicode('f45d');
}
.icon-pocket-knife:before {
  content: unicode('f45e');
}
.icon-border-left:before {
  content: unicode('f45f');
}
.icon-file-audio-add:before {
  content: unicode('f460');
}
.icon-christmas-snowflake:before {
  content: unicode('f461');
}
.icon-baby-rocking-horse:before {
  content: unicode('f462');
}
.icon-file-lock-1:before {
  content: unicode('f463');
}
.icon-espresso-machine:before {
  content: unicode('f464');
}
.icon-file-images-tiff:before {
  content: unicode('f465');
}
.icon-banking-money-send-5:before {
  content: unicode('f466');
}
.icon-mailbox-3:before {
  content: unicode('f467');
}
.icon-file-statistic-favorite-heart-2:before {
  content: unicode('f468');
}
.icon-sport-pingpong:before {
  content: unicode('f469');
}
.icon-bitcoin-rising:before {
  content: unicode('f46a');
}
.icon-traffic-cone:before {
  content: unicode('f46b');
}
.icon-sign-no-food-allowed-1:before {
  content: unicode('f46c');
}
.icon-location-globe-check:before {
  content: unicode('f46d');
}
.icon-navigation-filter-audio:before {
  content: unicode('f46e');
}
.icon-computer-screen-1:before {
  content: unicode('f46f');
}
.icon-pencil-2:before {
  content: unicode('f470');
}
.icon-file-zipped-download:before {
  content: unicode('f471');
}
.icon-receipt-3:before {
  content: unicode('f472');
}
.icon-folder-video:before {
  content: unicode('f473');
}
.icon-user-group-lock:before {
  content: unicode('f474');
}
.icon-farmer:before {
  content: unicode('f475');
}
.icon-cloud-dollar:before {
  content: unicode('f476');
}
.icon-shield-2:before {
  content: unicode('f477');
}
.icon-folder-refresh:before {
  content: unicode('f478');
}
.icon-file-images-question-makr:before {
  content: unicode('f479');
}
.icon-credit-card-check:before {
  content: unicode('f47a');
}
.icon-lightbulb-2:before {
  content: unicode('f47b');
}
.icon-health-emergency-patient:before {
  content: unicode('f47c');
}
.icon-circle-graph-bar-1:before {
  content: unicode('f47d');
}
.icon-file-statistic-home-2:before {
  content: unicode('f47e');
}
.icon-files-coding-upload-2:before {
  content: unicode('f47f');
}
.icon-flip-left:before {
  content: unicode('f480');
}
.icon-file-images-close:before {
  content: unicode('f481');
}
.icon-paint-color-tube:before {
  content: unicode('f482');
}
.icon-sd-card-dollar:before {
  content: unicode('f483');
}
.icon-shopping-basket-2:before {
  content: unicode('f484');
}
.icon-construction-blueprint-2:before {
  content: unicode('f485');
}
.icon-add-1:before {
  content: unicode('f486');
}
.icon-camera-resolution-16-9:before {
  content: unicode('f487');
}
.icon-romance-heart-coffee:before {
  content: unicode('f488');
}
.icon-window-application-5:before {
  content: unicode('f489');
}
.icon-plug-3:before {
  content: unicode('f48a');
}
.icon-file-refresh-1:before {
  content: unicode('f48b');
}
.icon-user-group-favorite-heart:before {
  content: unicode('f48c');
}
.icon-cursor-move-all-direction-1:before {
  content: unicode('f48d');
}
.icon-wifi-signal-edit:before {
  content: unicode('f48e');
}
.icon-files-coding-box-c-plus-plus:before {
  content: unicode('f48f');
}
.icon-credit-card:before {
  content: unicode('f490');
}
.icon-user-circle:before {
  content: unicode('f491');
}
.icon-music-note-2:before {
  content: unicode('f492');
}
.icon-g-signal1:before {
  content: unicode('f493');
}
.icon-lock-1:before {
  content: unicode('f494');
}
.icon-mobile-phone-download-2:before {
  content: unicode('f495');
}
.icon-files-coding-apk:before {
  content: unicode('f496');
}
.icon-files-coding-asp:before {
  content: unicode('f497');
}
.icon-underwear:before {
  content: unicode('f498');
}
.icon-smart-watch-circle-flash:before {
  content: unicode('f499');
}
.icon-files-coding-settings-1:before {
  content: unicode('f49a');
}
.icon-wind-flag:before {
  content: unicode('f49b');
}
.icon-boat-ship-2:before {
  content: unicode('f49c');
}
.icon-building-igloo:before {
  content: unicode('f49d');
}
.icon-soda-can-1:before {
  content: unicode('f49e');
}
.icon-chat-bubble-circle-2:before {
  content: unicode('f49f');
}
.icon-text-redo:before {
  content: unicode('f4a0');
}
.icon-files-1:before {
  content: unicode('f4a1');
}
.icon-check-box:before {
  content: unicode('f4a2');
}
.icon-organization-file-arborescence-2:before {
  content: unicode('f4a3');
}
.icon-user-dollar:before {
  content: unicode('f4a4');
}
.icon-smart-watch-square-bar-graph:before {
  content: unicode('f4a5');
}
.icon-navigation-show-more-vertical:before {
  content: unicode('f4a6');
}
.icon-sport-baseball:before {
  content: unicode('f4a7');
}
.icon-time-question-mark:before {
  content: unicode('f4a8');
}
.icon-control-curve:before {
  content: unicode('f4a9');
}
.icon-food-pizza:before {
  content: unicode('f4aa');
}
.icon-files-coding-box-pl:before {
  content: unicode('f4ab');
}
.icon-clapboard:before {
  content: unicode('f4ac');
}
.icon-reply-all:before {
  content: unicode('f4ad');
}
.icon-moon-orbitting-earth:before {
  content: unicode('f4ae');
}
.icon-coin-purse-1:before {
  content: unicode('f4af');
}
.icon-smart-watch-circle-play:before {
  content: unicode('f4b0');
}
.icon-banking-money-receive-5:before {
  content: unicode('f4b1');
}
.icon-cd-box-1:before {
  content: unicode('f4b2');
}
.icon-data-transfer-7:before {
  content: unicode('f4b3');
}
.icon-hand-fan:before {
  content: unicode('f4b4');
}
.icon-smart-watch-square-volume:before {
  content: unicode('f4b5');
}
.icon-grid-dollar:before {
  content: unicode('f4b6');
}
.icon-romance-shield-heart:before {
  content: unicode('f4b7');
}
.icon-cursor-tap:before {
  content: unicode('f4b8');
}
.icon-projector-screen-image:before {
  content: unicode('f4b9');
}
.icon-real-estate-sign-sold-3:before {
  content: unicode('f4ba');
}
.icon-small-caps:before {
  content: unicode('f4bb');
}
.icon-car-gearbox-manual:before {
  content: unicode('f4bc');
}
.icon-send-to-back:before {
  content: unicode('f4bd');
}
.icon-nature-plant-2:before {
  content: unicode('f4be');
}
.icon-food-grain:before {
  content: unicode('f4bf');
}
.icon-places-water-well:before {
  content: unicode('f4c0');
}
.icon-sd-card-settings:before {
  content: unicode('f4c1');
}
.icon-user-group:before {
  content: unicode('f4c2');
}
.icon-smiley-frown-2:before {
  content: unicode('f4c3');
}
.icon-window-close:before {
  content: unicode('f4c4');
}
.icon-files-coding-3:before {
  content: unicode('f4c5');
}
.icon-vest:before {
  content: unicode('f4c6');
}
.icon-mobile-phone-email:before {
  content: unicode('f4c7');
}
.icon-box-information:before {
  content: unicode('f4c8');
}
.icon-sign-beware-animal:before {
  content: unicode('f4c9');
}
.icon-file-office-txt:before {
  content: unicode('f4ca');
}
.icon-two-finger-swipe-left-2:before {
  content: unicode('f4cb');
}
.icon-location-pin-question-mark-2:before {
  content: unicode('f4cc');
}
.icon-email-download:before {
  content: unicode('f4cd');
}
.icon-business-graph-bar-horizontal:before {
  content: unicode('f4ce');
}
.icon-calendar-dollar-2:before {
  content: unicode('f4cf');
}
.icon-file-design-vector-2:before {
  content: unicode('f4d0');
}
.icon-food-double-burger:before {
  content: unicode('f4d1');
}
.icon-health-prescription-2:before {
  content: unicode('f4d2');
}
.icon-mobile-phone-stylus:before {
  content: unicode('f4d3');
}
.icon-vote-heart-circle-2:before {
  content: unicode('f4d4');
}
.icon-hanging-rope:before {
  content: unicode('f4d5');
}
.icon-building-3:before {
  content: unicode('f4d6');
}
.icon-file-zipped-vice:before {
  content: unicode('f4d7');
}
.icon-file-zipped-edit:before {
  content: unicode('f4d8');
}
.icon-file-audio-refresh:before {
  content: unicode('f4d9');
}
.icon-center-point:before {
  content: unicode('f4da');
}
.icon-barcode-2:before {
  content: unicode('f4db');
}
.icon-flick-down-2:before {
  content: unicode('f4dc');
}
.icon-tag-close:before {
  content: unicode('f4dd');
}
.icon-banking-transfer-future-1:before {
  content: unicode('f4de');
}
.icon-grid-block:before {
  content: unicode('f4df');
}
.icon-file-statistic-upload-1:before {
  content: unicode('f4e0');
}
.icon-diamond:before {
  content: unicode('f4e1');
}
.icon-cloudy-thunder-storm:before {
  content: unicode('f4e2');
}
.icon-delete:before {
  content: unicode('f4e3');
}
.icon-vote-medal-1:before {
  content: unicode('f4e4');
}
.icon-food-noodle-1:before {
  content: unicode('f4e5');
}
.icon-taxi-2:before {
  content: unicode('f4e6');
}
.icon-window-settings:before {
  content: unicode('f4e7');
}
.icon-shopping-bag-favorite-star:before {
  content: unicode('f4e8');
}
.icon-file-statistic-block-2:before {
  content: unicode('f4e9');
}
.icon-places-bridge-1:before {
  content: unicode('f4ea');
}
.icon-shopping-cart-edit:before {
  content: unicode('f4eb');
}
.icon-stamp-1:before {
  content: unicode('f4ec');
}
.icon-box-reply:before {
  content: unicode('f4ed');
}
.icon-tools-vice:before {
  content: unicode('f4ee');
}
.icon-mobile-phone-close-2:before {
  content: unicode('f4ef');
}
.icon-user-settings:before {
  content: unicode('f4f0');
}
.icon-window-minimize:before {
  content: unicode('f4f1');
}
.icon-shopping-bag-close:before {
  content: unicode('f4f2');
}
.icon-organization-flowchart-1:before {
  content: unicode('f4f3');
}
.icon-format-clear:before {
  content: unicode('f4f4');
}
.icon-file-settings-1:before {
  content: unicode('f4f5');
}
.icon-vote-heart-sync:before {
  content: unicode('f4f6');
}
.icon-places-church:before {
  content: unicode('f4f7');
}
.icon-music-album-alert:before {
  content: unicode('f4f8');
}
.icon-share-rss-feed-box:before {
  content: unicode('f4f9');
}
.icon-camera-next:before {
  content: unicode('f4fa');
}
.icon-g-roaming:before {
  content: unicode('f4fb');
}
.icon-mobile-phone-check-1:before {
  content: unicode('f4fc');
}
.icon-travel-departure:before {
  content: unicode('f4fd');
}
.icon-time-shield:before {
  content: unicode('f4fe');
}
.icon-file-statistic-view-1:before {
  content: unicode('f4ff');
}
.icon-files-coding-box-sql:before {
  content: unicode('f500');
}
.icon-boots-2:before {
  content: unicode('f501');
}
.icon-file-images-box-svg:before {
  content: unicode('f502');
}
.icon-color-contrast-off:before {
  content: unicode('f503');
}
.icon-cloud-upload-2:before {
  content: unicode('f504');
}
.icon-expand-diagonal-6:before {
  content: unicode('f505');
}
.icon-network-refresh:before {
  content: unicode('f506');
}
.icon-water-fountain:before {
  content: unicode('f507');
}
.icon-files-coding-c-plus-plus:before {
  content: unicode('f508');
}
.icon-grid-settings:before {
  content: unicode('f509');
}
.icon-bitcoin-computer-chip:before {
  content: unicode('f50a');
}
.icon-health-broken-bone:before {
  content: unicode('f50b');
}
.icon-file-office-document:before {
  content: unicode('f50c');
}
.icon-water-level-down:before {
  content: unicode('f50d');
}
.icon-food-carrot:before {
  content: unicode('f50e');
}
.icon-calendar-magnify:before {
  content: unicode('f50f');
}
.icon-file-video-mp4:before {
  content: unicode('f510');
}
.icon-sport-canoeing:before {
  content: unicode('f511');
}
.icon-contact-book-4:before {
  content: unicode('f512');
}
.icon-shrink-horizontal-3:before {
  content: unicode('f513');
}
.icon-banking-debit-machine:before {
  content: unicode('f514');
}
.icon-synchronize-1:before {
  content: unicode('f515');
}
.icon-sport-badminton-shuttlecock:before {
  content: unicode('f516');
}
.icon-banking-cheque:before {
  content: unicode('f517');
}
.icon-chair-2:before {
  content: unicode('f518');
}
.icon-oil-pump:before {
  content: unicode('f519');
}
.icon-mobile-phone-settings:before {
  content: unicode('f51a');
}
.icon-nuclear-chemical:before {
  content: unicode('f51b');
}
.icon-clapboard-previous:before {
  content: unicode('f51c');
}
.icon-sport-basketball-hoop:before {
  content: unicode('f51d');
}
.icon-file-office-rtf:before {
  content: unicode('f51e');
}
.icon-beauty-hand-soap:before {
  content: unicode('f51f');
}
.icon-content-book-view:before {
  content: unicode('f520');
}
.icon-shopping-basket-refresh:before {
  content: unicode('f521');
}
.icon-file-view-2:before {
  content: unicode('f522');
}
.icon-data-upload-7:before {
  content: unicode('f523');
}
.icon-window-forward:before {
  content: unicode('f524');
}
.icon-navigation-up-1:before {
  content: unicode('f525');
}
.icon-eco-solar-panel:before {
  content: unicode('f526');
}
.icon-business-briefcase-cash:before {
  content: unicode('f527');
}
.icon-food-cake-whole:before {
  content: unicode('f528');
}
.icon-camera-flash-1:before {
  content: unicode('f529');
}
.icon-window-reduce-left:before {
  content: unicode('f52a');
}
.icon-shrink-diagonal-2:before {
  content: unicode('f52b');
}
.icon-cat-playground:before {
  content: unicode('f52c');
}
.icon-car-fuel-empty-2:before {
  content: unicode('f52d');
}
.icon-camera-resolution-4-3:before {
  content: unicode('f52e');
}
.icon-cursor-arrow-alert:before {
  content: unicode('f52f');
}
.icon-building-14:before {
  content: unicode('f530');
}
.icon-construction-crane:before {
  content: unicode('f531');
}
.icon-cloud-audio-1:before {
  content: unicode('f532');
}
.icon-files-coding-java:before {
  content: unicode('f533');
}
.icon-file-quill:before {
  content: unicode('f534');
}
.icon-photo-view-all:before {
  content: unicode('f535');
}
.icon-building-15:before {
  content: unicode('f536');
}
.icon-shrink-diagonal-3:before {
  content: unicode('f537');
}
.icon-navigation-show-more-horizontal:before {
  content: unicode('f538');
}
.icon-smart-watch-circle-dollar:before {
  content: unicode('f539');
}
.icon-nature-bee-hive:before {
  content: unicode('f53a');
}
.icon-move-down-4:before {
  content: unicode('f53b');
}
.icon-files-landscape-text:before {
  content: unicode('f53c');
}
.icon-location-gps-on-1:before {
  content: unicode('f53d');
}
.icon-indent-increase-2:before {
  content: unicode('f53e');
}
.icon-headphone:before {
  content: unicode('f53f');
}
.icon-move-circle-up-2:before {
  content: unicode('f540');
}
.icon-data-upload-6:before {
  content: unicode('f541');
}
.icon-add-square-2:before {
  content: unicode('f542');
}
.icon-sport-boxing-glove:before {
  content: unicode('f543');
}
.icon-money-note-coin:before {
  content: unicode('f544');
}
.icon-road-tunnel:before {
  content: unicode('f545');
}
.icon-car-battery-charge:before {
  content: unicode('f546');
}
.icon-boat-sail-1:before {
  content: unicode('f547');
}
.icon-volcanoe:before {
  content: unicode('f548');
}
.icon-shrink-horizontal-2:before {
  content: unicode('f549');
}
.icon-user-download:before {
  content: unicode('f54a');
}
.icon-video-games-joypad-wireless:before {
  content: unicode('f54b');
}
.icon-neck-tie:before {
  content: unicode('f54c');
}
.icon-coffee-mug:before {
  content: unicode('f54d');
}
.icon-laptop-shield-1:before {
  content: unicode('f54e');
}
.icon-content-book-video:before {
  content: unicode('f54f');
}
.icon-window-image:before {
  content: unicode('f550');
}
.icon-files-copyright-r:before {
  content: unicode('f551');
}
.icon-location-pin-close-1:before {
  content: unicode('f552');
}
.icon-phone-off:before {
  content: unicode('f553');
}
.icon-file-notes-upload:before {
  content: unicode('f554');
}
.icon-fire-truck-2:before {
  content: unicode('f555');
}
.icon-border-outside:before {
  content: unicode('f556');
}
.icon-flash-off:before {
  content: unicode('f557');
}
.icon-expand-diagonal-7:before {
  content: unicode('f558');
}
.icon-clapboard-next:before {
  content: unicode('f559');
}
.icon-check-shield:before {
  content: unicode('f55a');
}
.icon-sd-card-download:before {
  content: unicode('f55b');
}
.icon-resolution-3d:before {
  content: unicode('f55c');
}
.icon-brick-wall:before {
  content: unicode('f55d');
}
.icon-glass-cocktail-1:before {
  content: unicode('f55e');
}
.icon-signal-coffee:before {
  content: unicode('f55f');
}
.icon-location-pin-hospital-2:before {
  content: unicode('f560');
}
.icon-christmas-sleigh:before {
  content: unicode('f561');
}
.icon-resolution-hd:before {
  content: unicode('f562');
}
.icon-speaker-volume-decrease:before {
  content: unicode('f563');
}
.icon-calendar-refresh-2:before {
  content: unicode('f564');
}
.icon-shopping-bag-upload:before {
  content: unicode('f565');
}
.icon-file-images-gif:before {
  content: unicode('f566');
}
.icon-film-roll:before {
  content: unicode('f567');
}
.icon-android-home:before {
  content: unicode('f568');
}
.icon-swipe-horizontal-2:before {
  content: unicode('f569');
}
.icon-camera-refresh-1:before {
  content: unicode('f56a');
}
.icon-file-office-box-xls:before {
  content: unicode('f56b');
}
.icon-video-camera-6:before {
  content: unicode('f56c');
}
.icon-video-games-gamegear:before {
  content: unicode('f56d');
}
.icon-file-audio-subtract:before {
  content: unicode('f56e');
}
.icon-tube:before {
  content: unicode('f56f');
}
.icon-wifi-signal-sync:before {
  content: unicode('f570');
}
.icon-flood:before {
  content: unicode('f571');
}
.icon-leisure-dice-1:before {
  content: unicode('f572');
}
.icon-shopping-basket-subtract:before {
  content: unicode('f573');
}
.icon-business-trade:before {
  content: unicode('f574');
}
.icon-email-settings:before {
  content: unicode('f575');
}
.icon-video-games-steam-engine:before {
  content: unicode('f576');
}
.icon-file-video-shield:before {
  content: unicode('f577');
}
.icon-location-pin-camera-1:before {
  content: unicode('f578');
}
.icon-cursor-choose:before {
  content: unicode('f579');
}
.icon-bin:before {
  content: unicode('f57a');
}
.icon-email-envelope:before {
  content: unicode('f57b');
}
.icon-train-1:before {
  content: unicode('f57c');
}
.icon-shield-settings:before {
  content: unicode('f57d');
}
.icon-move-left-right-2:before {
  content: unicode('f57e');
}
.icon-battery-charging-1:before {
  content: unicode('f57f');
}
.icon-cursor-move-down-2:before {
  content: unicode('f580');
}
.icon-control-zigzag-1:before {
  content: unicode('f581');
}
.icon-yen-coin:before {
  content: unicode('f582');
}
.icon-food-bread-basket:before {
  content: unicode('f583');
}
.icon-smiley-smile-12:before {
  content: unicode('f584');
}
.icon-audio-control-stop:before {
  content: unicode('f585');
}
.icon-grid-home:before {
  content: unicode('f586');
}
.icon-health-nurse-cap:before {
  content: unicode('f587');
}
.icon-location-pin-check-2:before {
  content: unicode('f588');
}
.icon-files-coding-alert-2:before {
  content: unicode('f589');
}
.icon-file-images-block:before {
  content: unicode('f58a');
}
.icon-travel-flippers:before {
  content: unicode('f58b');
}
.icon-chat-bubble-circle-lock:before {
  content: unicode('f58c');
}
.icon-navigation-filter-mail:before {
  content: unicode('f58d');
}
.icon-file-statistic-favorite-star-1:before {
  content: unicode('f58e');
}
.icon-barcode-3:before {
  content: unicode('f58f');
}
.icon-health-saline:before {
  content: unicode('f590');
}
.icon-window-flag:before {
  content: unicode('f591');
}
.icon-building-2:before {
  content: unicode('f592');
}
.icon-tag-add:before {
  content: unicode('f593');
}
.icon-two-finger-tap:before {
  content: unicode('f594');
}
.icon-shopping-bag-refresh:before {
  content: unicode('f595');
}
.icon-email-favorite-heart:before {
  content: unicode('f596');
}
.icon-server-lock-1:before {
  content: unicode('f597');
}
.icon-spa-bamboo:before {
  content: unicode('f598');
}
.icon-server-add-1:before {
  content: unicode('f599');
}
.icon-two-finger-tap-hold-2:before {
  content: unicode('f59a');
}
.icon-artist:before {
  content: unicode('f59b');
}
.icon-location-pin-wifi-2:before {
  content: unicode('f59c');
}
.icon-credit-card-timeout:before {
  content: unicode('f59d');
}
.icon-file-images-lock:before {
  content: unicode('f59e');
}
.icon-book-shelf:before {
  content: unicode('f59f');
}
.icon-file-images-box-bmp:before {
  content: unicode('f5a0');
}
.icon-box-shield:before {
  content: unicode('f5a1');
}
.icon-wifi-signal-subtract:before {
  content: unicode('f5a2');
}
.icon-police-cap:before {
  content: unicode('f5a3');
}
.icon-server-information-2:before {
  content: unicode('f5a4');
}
.icon-file-statistic-download-1:before {
  content: unicode('f5a5');
}
.icon-pet-comb:before {
  content: unicode('f5a6');
}
.icon-files-coding-2:before {
  content: unicode('f5a7');
}
.icon-email-inbox:before {
  content: unicode('f5a8');
}
.icon-rewards-badge-1:before {
  content: unicode('f5a9');
}
.icon-smiley-frown-3:before {
  content: unicode('f5aa');
}
.icon-box-lock:before {
  content: unicode('f5ab');
}
.icon-mobile-phone-timeout:before {
  content: unicode('f5ac');
}
.icon-user-setting:before {
  content: unicode('f5ad');
}
.icon-calendar-favorite-heart:before {
  content: unicode('f5ae');
}
.icon-key-hole-1:before {
  content: unicode('f5af');
}
.icon-nature-plant-3:before {
  content: unicode('f5b0');
}
.icon-grid-download:before {
  content: unicode('f5b1');
}
.icon-expand-1:before {
  content: unicode('f5b2');
}
.icon-flash-refresh:before {
  content: unicode('f5b3');
}
.icon-calculator-1:before {
  content: unicode('f5b4');
}
.icon-share:before {
  content: unicode('f5b5');
}
.icon-percent-increase:before {
  content: unicode('f5b6');
}
.icon-server-home-1:before {
  content: unicode('f5b7');
}
.icon-real-estate-sign-sold-2:before {
  content: unicode('f5b8');
}
.icon-real-estate-shield:before {
  content: unicode('f5b9');
}
.icon-pet-basket:before {
  content: unicode('f5ba');
}
.icon-network-information:before {
  content: unicode('f5bb');
}
.icon-places-eiffel-tower:before {
  content: unicode('f5bc');
}
.icon-expand-3:before {
  content: unicode('f5bd');
}
.icon-nature-plant-1:before {
  content: unicode('f5be');
}
.icon-border-bottom:before {
  content: unicode('f5bf');
}
.icon-euro-price:before {
  content: unicode('f5c0');
}
.icon-money-bag-bitcoin:before {
  content: unicode('f5c1');
}
.icon-shopping-bag-lock:before {
  content: unicode('f5c2');
}
.icon-arrow-up:before {
  content: unicode('f5c3');
}
.icon-video-games-furby:before {
  content: unicode('f5c4');
}
.icon-smiley-frown-1:before {
  content: unicode('f5c5');
}
.icon-sd-card-information:before {
  content: unicode('f5c6');
}
.icon-key-hole-3:before {
  content: unicode('f5c7');
}
.icon-content-briefcase:before {
  content: unicode('f5c8');
}
.icon-folder-add:before {
  content: unicode('f5c9');
}
.icon-paper-pencil:before {
  content: unicode('f5ca');
}
.icon-shopping-cart-alert:before {
  content: unicode('f5cb');
}
.icon-rewards-badge-3:before {
  content: unicode('f5cc');
}
.icon-file-tasks-question-mark:before {
  content: unicode('f5cd');
}
.icon-panorama-wide-angle:before {
  content: unicode('f5ce');
}
.icon-camera-brightness-auto:before {
  content: unicode('f5cf');
}
.icon-calendar-dollar-1:before {
  content: unicode('f5d0');
}
.icon-tag-favorite-heart:before {
  content: unicode('f5d1');
}
.icon-file-design-vector-1:before {
  content: unicode('f5d2');
}
.icon-data-download-9:before {
  content: unicode('f5d3');
}
.icon-chat-bubble-square-shield:before {
  content: unicode('f5d4');
}
.icon-hat-1:before {
  content: unicode('f5d5');
}
.icon-paper-binder:before {
  content: unicode('f5d6');
}
.icon-computer-chip-64:before {
  content: unicode('f5d7');
}
.icon-dollar-price:before {
  content: unicode('f5d8');
}
.icon-cloud-close:before {
  content: unicode('f5d9');
}
.icon-location-pin-question-mark-1:before {
  content: unicode('f5da');
}
.icon-content-book-sync:before {
  content: unicode('f5db');
}
.icon-transfer-folder-computer:before {
  content: unicode('f5dc');
}
.icon-hotel-sign-do-not-disturb:before {
  content: unicode('f5dd');
}
.icon-vote-heart-circle-1:before {
  content: unicode('f5de');
}
.icon-health-prescription-1:before {
  content: unicode('f5df');
}
.icon-data-ftp:before {
  content: unicode('f5e0');
}
.icon-paint-palette:before {
  content: unicode('f5e1');
}
.icon-wrap-text:before {
  content: unicode('f5e2');
}
.icon-banking-transfer-future-2:before {
  content: unicode('f5e3');
}
.icon-file-statistic-upload-2:before {
  content: unicode('f5e4');
}
.icon-flick-down-1:before {
  content: unicode('f5e5');
}
.icon-glass-beer:before {
  content: unicode('f5e6');
}
.icon-file-notes-information:before {
  content: unicode('f5e7');
}
.icon-barcode-1:before {
  content: unicode('f5e8');
}
.icon-religion-japanese-temple:before {
  content: unicode('f5e9');
}
.icon-credit-card-master-card:before {
  content: unicode('f5ea');
}
.icon-vote-heart-view:before {
  content: unicode('f5eb');
}
.icon-undo:before {
  content: unicode('f5ec');
}
.icon-taxi-1:before {
  content: unicode('f5ed');
}
.icon-control-zigzag-3:before {
  content: unicode('f5ee');
}
.icon-battery-charging-3:before {
  content: unicode('f5ef');
}
.icon-food-noodle-2:before {
  content: unicode('f5f0');
}
.icon-smiley-smile-10:before {
  content: unicode('f5f1');
}
.icon-vote-medal-2:before {
  content: unicode('f5f2');
}
.icon-email-read:before {
  content: unicode('f5f3');
}
.icon-mobile-phone-close-1:before {
  content: unicode('f5f4');
}
.icon-bank-note-fire:before {
  content: unicode('f5f5');
}
.icon-chat-bubble-circle-download:before {
  content: unicode('f5f6');
}
.icon-file-statistic-block-1:before {
  content: unicode('f5f7');
}
.icon-places-bridge-2:before {
  content: unicode('f5f8');
}
.icon-calendar-block:before {
  content: unicode('f5f9');
}
.icon-file-settings-2:before {
  content: unicode('f5fa');
}
.icon-file-zipped-close:before {
  content: unicode('f5fb');
}
.icon-tag-refresh:before {
  content: unicode('f5fc');
}
.icon-file-images-eps:before {
  content: unicode('f5fd');
}
.icon-sd-card-close:before {
  content: unicode('f5fe');
}
.icon-organization-flowchart-2:before {
  content: unicode('f5ff');
}
.icon-keyboard-arrows:before {
  content: unicode('f600');
}
.icon-mobile-phone-check-2:before {
  content: unicode('f601');
}
.icon-control-refresh-one:before {
  content: unicode('f602');
}
.icon-smart-watch-square-power:before {
  content: unicode('f603');
}
.icon-window-hash:before {
  content: unicode('f604');
}
.icon-health-condom:before {
  content: unicode('f605');
}
.icon-warehouse-box:before {
  content: unicode('f606');
}
.icon-video-camera-4:before {
  content: unicode('f607');
}
.icon-gender-female:before {
  content: unicode('f608');
}
.icon-cloud-upload-1:before {
  content: unicode('f609');
}
.icon-expand-diagonal-5:before {
  content: unicode('f60a');
}
.icon-location-globe-add:before {
  content: unicode('f60b');
}
.icon-romance-love-music:before {
  content: unicode('f60c');
}
.icon-mobile-phone-favorite-heart:before {
  content: unicode('f60d');
}
.icon-time-edit:before {
  content: unicode('f60e');
}
.icon-exposure-level:before {
  content: unicode('f60f');
}
.icon-glass-cocktail-3:before {
  content: unicode('f610');
}
.icon-android-download:before {
  content: unicode('f611');
}
.icon-boots-1:before {
  content: unicode('f612');
}
.icon-file-statistic-view-2:before {
  content: unicode('f613');
}
.icon-kitchen-boil:before {
  content: unicode('f614');
}
.icon-tag-download:before {
  content: unicode('f615');
}
.icon-vector-triangle:before {
  content: unicode('f616');
}
.icon-email-outbox:before {
  content: unicode('f617');
}
.icon-file-video-lock:before {
  content: unicode('f618');
}
.icon-indent-right:before {
  content: unicode('f619');
}
.icon-file-notes-refresh:before {
  content: unicode('f61a');
}
.icon-window-search:before {
  content: unicode('f61b');
}
.icon-camera-flash-auto:before {
  content: unicode('f61c');
}
.icon-beauty-perfume:before {
  content: unicode('f61d');
}
.icon-temperature-fahrenheit:before {
  content: unicode('f61e');
}
.icon-text-vector:before {
  content: unicode('f61f');
}
.icon-alarm-check:before {
  content: unicode('f620');
}
.icon-car-wheel:before {
  content: unicode('f621');
}
.icon-synchronize-2:before {
  content: unicode('f622');
}
.icon-layout-8:before {
  content: unicode('f623');
}
.icon-two-finger-flick-left:before {
  content: unicode('f624');
}
.icon-files-coding-box-hqx:before {
  content: unicode('f625');
}
.icon-smiley-worry:before {
  content: unicode('f626');
}
.icon-camera-night-mode:before {
  content: unicode('f627');
}
.icon-tombstone:before {
  content: unicode('f628');
}
.icon-leisure-tent:before {
  content: unicode('f629');
}
.icon-time-block:before {
  content: unicode('f62a');
}
.icon-switch:before {
  content: unicode('f62b');
}
.icon-phone-incoming:before {
  content: unicode('f62c');
}
.icon-file-view-1:before {
  content: unicode('f62d');
}
.icon-data-upload-4:before {
  content: unicode('f62e');
}
.icon-airship:before {
  content: unicode('f62f');
}
.icon-italic:before {
  content: unicode('f630');
}
.icon-content-book-share:before {
  content: unicode('f631');
}
.icon-chair-1:before {
  content: unicode('f632');
}
.icon-file-tasks-view:before {
  content: unicode('f633');
}
.icon-camera-previous:before {
  content: unicode('f634');
}
.icon-navigation-up-2:before {
  content: unicode('f635');
}
.icon-building-17:before {
  content: unicode('f636');
}
.icon-pencil-circle:before {
  content: unicode('f637');
}
.icon-calendar-graduation-hat:before {
  content: unicode('f638');
}
.icon-cloud-audio-2:before {
  content: unicode('f639');
}
.icon-business-whiteboard-graph:before {
  content: unicode('f63a');
}
.icon-mobile-phone-view:before {
  content: unicode('f63b');
}
.icon-car-fuel-empty-1:before {
  content: unicode('f63c');
}
.icon-sport-protein-shake:before {
  content: unicode('f63d');
}
.icon-camera-flash-2:before {
  content: unicode('f63e');
}
.icon-shopping-bag-subtract:before {
  content: unicode('f63f');
}
.icon-vote-heart-timeout:before {
  content: unicode('f640');
}
.icon-shrink-diagonal-1:before {
  content: unicode('f641');
}
.icon-user-refresh:before {
  content: unicode('f642');
}
.icon-car-fuel:before {
  content: unicode('f643');
}
.icon-yen-decreasing:before {
  content: unicode('f644');
}
.icon-arrow-shift:before {
  content: unicode('f645');
}
.icon-road-left-turn:before {
  content: unicode('f646');
}
.icon-vote-star-banner:before {
  content: unicode('f647');
}
.icon-window-application-search:before {
  content: unicode('f648');
}
.icon-business-graph-plot:before {
  content: unicode('f649');
}
.icon-christmas-snowglobe:before {
  content: unicode('f64a');
}
.icon-building-16:before {
  content: unicode('f64b');
}
.icon-projector-screen-speech-bubble:before {
  content: unicode('f64c');
}
.icon-shopping-trolley:before {
  content: unicode('f64d');
}
.icon-car-steering-wheel:before {
  content: unicode('f64e');
}
.icon-indent-increase-1:before {
  content: unicode('f64f');
}
.icon-leisure-card-hearts:before {
  content: unicode('f650');
}
.icon-navigation-up-3:before {
  content: unicode('f651');
}
.icon-location-gps-on-2:before {
  content: unicode('f652');
}
.icon-hat-charlie-chaplin:before {
  content: unicode('f653');
}
.icon-globe:before {
  content: unicode('f654');
}
.icon-network-dollar:before {
  content: unicode('f655');
}
.icon-chat-bubble-square-smiley:before {
  content: unicode('f656');
}
.icon-wifi-signal-refresh:before {
  content: unicode('f657');
}
.icon-align-center:before {
  content: unicode('f658');
}
.icon-boat-sail-2:before {
  content: unicode('f659');
}
.icon-business-graph-bar-vertical:before {
  content: unicode('f65a');
}
.icon-data-upload-5:before {
  content: unicode('f65b');
}
.icon-move-circle-up-1:before {
  content: unicode('f65c');
}
.icon-android-question-makr:before {
  content: unicode('f65d');
}
.icon-add-square-1:before {
  content: unicode('f65e');
}
.icon-food-boiled-egg:before {
  content: unicode('f65f');
}
.icon-laptop-shield-2:before {
  content: unicode('f660');
}
.icon-chat-bubble-circle-settings:before {
  content: unicode('f661');
}
.icon-compass-south:before {
  content: unicode('f662');
}
.icon-synchronize-3:before {
  content: unicode('f663');
}
.icon-layout-9:before {
  content: unicode('f664');
}
.icon-music-album-shield:before {
  content: unicode('f665');
}
.icon-arrow-return:before {
  content: unicode('f666');
}
.icon-bus-4:before {
  content: unicode('f667');
}
.icon-shrink-horizontal-1:before {
  content: unicode('f668');
}
.icon-calendar-heart:before {
  content: unicode('f669');
}
.icon-coin-receive:before {
  content: unicode('f66a');
}
.icon-location-pin-close-2:before {
  content: unicode('f66b');
}
.icon-truck-cement-mixer:before {
  content: unicode('f66c');
}
.icon-navigation-filter-add:before {
  content: unicode('f66d');
}
.icon-mining-cart:before {
  content: unicode('f66e');
}
.icon-mobile-phone-favorite-star:before {
  content: unicode('f66f');
}
.icon-hand-gun:before {
  content: unicode('f670');
}
.icon-ufo:before {
  content: unicode('f671');
}
.icon-glass-cocktail-2:before {
  content: unicode('f672');
}
.icon-safety-pin:before {
  content: unicode('f673');
}
.icon-arrow-down-right:before {
  content: unicode('f674');
}
.icon-file-office-rar:before {
  content: unicode('f675');
}
.icon-expand-diagonal-4:before {
  content: unicode('f676');
}
.icon-ram:before {
  content: unicode('f677');
}
.icon-email-shield:before {
  content: unicode('f678');
}
.icon-fire-truck-1:before {
  content: unicode('f679');
}
.icon-interface-share-star:before {
  content: unicode('f67a');
}
.icon-cloud-image:before {
  content: unicode('f67b');
}
.icon-xylophone:before {
  content: unicode('f67c');
}
.icon-vote-heart-dollar:before {
  content: unicode('f67d');
}
.icon-files-coding-bin:before {
  content: unicode('f67e');
}
.icon-swipe-horizontal-1:before {
  content: unicode('f67f');
}
.icon-exchange-chips-dollar:before {
  content: unicode('f680');
}
.icon-video-camera-5:before {
  content: unicode('f681');
}
.icon-wifi-signal-close:before {
  content: unicode('f682');
}
.icon-camera-refresh-2:before {
  content: unicode('f683');
}
.icon-kitchen-fork-spoon:before {
  content: unicode('f684');
}
.icon-kitchen-seasoning:before {
  content: unicode('f685');
}
.icon-food-cookie:before {
  content: unicode('f686');
}
.icon-segway:before {
  content: unicode('f687');
}
.icon-location-pin-hospital-1:before {
  content: unicode('f688');
}
.icon-chat-bubble-circle-alert:before {
  content: unicode('f689');
}
.icon-calendar-refresh-1:before {
  content: unicode('f68a');
}
.icon-romance-tear-drop:before {
  content: unicode('f68b');
}
.icon-location-pin-camera-2:before {
  content: unicode('f68c');
}
.icon-programming-css:before {
  content: unicode('f68d');
}
.icon-organization-flowchart-3:before {
  content: unicode('f68e');
}
.icon-leisure-dice-2:before {
  content: unicode('f68f');
}
.icon-share-signal-user:before {
  content: unicode('f690');
}
.icon-file-notes-document:before {
  content: unicode('f691');
}
.icon-music-album-information:before {
  content: unicode('f692');
}
.icon-smart-watch-square-messages:before {
  content: unicode('f693');
}
.icon-move-left-right-1:before {
  content: unicode('f694');
}
.icon-chat-bubble-square-arrow:before {
  content: unicode('f695');
}
.icon-train-2:before {
  content: unicode('f696');
}
.icon-file-design-dwg:before {
  content: unicode('f697');
}
.icon-places-bridge-3:before {
  content: unicode('f698');
}
.icon-real-estate-settings:before {
  content: unicode('f699');
}
.icon-real-estate-sale:before {
  content: unicode('f69a');
}
.icon-email-alert:before {
  content: unicode('f69b');
}
.icon-file-images-add:before {
  content: unicode('f69c');
}
.icon-moon-first-quarter:before {
  content: unicode('f69d');
}
.icon-smiley-smile-11:before {
  content: unicode('f69e');
}
.icon-wifi-signal-view:before {
  content: unicode('f69f');
}
.icon-battery-charging-2:before {
  content: unicode('f6a0');
}
.icon-cursor-move-down-1:before {
  content: unicode('f6a1');
}
.icon-control-zigzag-2:before {
  content: unicode('f6a2');
}
.icon-keyboard-wireless:before {
  content: unicode('f6a3');
}
.icon-file-statistic-favorite-star-2:before {
  content: unicode('f6a4');
}
.icon-price-rise:before {
  content: unicode('f6a5');
}
.icon-files-coding-alert-1:before {
  content: unicode('f6a6');
}
.icon-artboard:before {
  content: unicode('f6a7');
}
.icon-scanner:before {
  content: unicode('f6a8');
}
.icon-location-pin-check-1:before {
  content: unicode('f6a9');
}
.icon-vote-flag-8:before {
  content: unicode('f6aa');
}
.icon-shopping-basket-dollar:before {
  content: unicode('f6ab');
}
.icon-server-lock-2:before {
  content: unicode('f6ac');
}
.icon-building-1:before {
  content: unicode('f6ad');
}
.icon-walking-cane:before {
  content: unicode('f6ae');
}
.icon-needle-thread:before {
  content: unicode('f6af');
}
.icon-places-lighthouse:before {
  content: unicode('f6b0');
}
.icon-video-games-pokeball:before {
  content: unicode('f6b1');
}
.icon-content-note:before {
  content: unicode('f6b2');
}
.icon-glue:before {
  content: unicode('f6b3');
}
.icon-programming-website:before {
  content: unicode('f6b4');
}
.icon-data-download-8:before {
  content: unicode('f6b5');
}
.icon-two-finger-tap-hold-1:before {
  content: unicode('f6b6');
}
.icon-location-pin-wifi-1:before {
  content: unicode('f6b7');
}
.icon-server-add-2:before {
  content: unicode('f6b8');
}
.icon-dog-bone:before {
  content: unicode('f6b9');
}
.icon-rewards-badge-2:before {
  content: unicode('f6ba');
}
.icon-synchronize-lock:before {
  content: unicode('f6bb');
}
.icon-files-coding-1:before {
  content: unicode('f6bc');
}
.icon-server-information-1:before {
  content: unicode('f6bd');
}
.icon-real-estate-pin:before {
  content: unicode('f6be');
}
.icon-file-statistic-download-2:before {
  content: unicode('f6bf');
}
.icon-tag-settings:before {
  content: unicode('f6c0');
}
.icon-android-settings:before {
  content: unicode('f6c1');
}
.icon-food-melon:before {
  content: unicode('f6c2');
}
.icon-key-hole-2:before {
  content: unicode('f6c3');
}
.icon-smart-watch-square-play:before {
  content: unicode('f6c4');
}
.icon-file-notes-close:before {
  content: unicode('f6c5');
}
.icon-kitchen-juicer:before {
  content: unicode('f6c6');
}
.icon-real-estate-sign-sold-1:before {
  content: unicode('f6c7');
}
.icon-text-bar:before {
  content: unicode('f6c8');
}
.icon-paper-grade-a:before {
  content: unicode('f6c9');
}
.icon-navigation-filter-android:before {
  content: unicode('f6ca');
}
.icon-server-home-2:before {
  content: unicode('f6cb');
}
.icon-shopping-bag-barcode:before {
  content: unicode('f6cc');
}
.icon-sport-cycling-water-bottle:before {
  content: unicode('f6cd');
}
.icon-expand-2:before {
  content: unicode('f6ce');
}
.icon-calculator-2:before {
  content: unicode('f6cf');
}
.icon-cloud:before {
  content: unicode('f6d0');
}
.icon-crop-image:before {
  content: unicode('f6d1');
}
.icon-music-shopping:before {
  content: unicode('f6d2');
}
.icon-alien:before {
  content: unicode('f6d3');
}
.icon-credit-card-view-84:before {
  content: unicode('f6d4');
}
.icon-music-album-favorite-star:before {
  content: unicode('f6d5');
}
.icon-indent-left:before {
  content: unicode('f6d6');
}
.icon-mobile-phone-upload-2:before {
  content: unicode('f6d7');
}
.icon-chat-bubble-circle-arrow:before {
  content: unicode('f6d8');
}
.icon-computer-chip-burn:before {
  content: unicode('f6d9');
}
.icon-video-games-pacman-ghost:before {
  content: unicode('f6da');
}
.icon-sea-level:before {
  content: unicode('f6db');
}
.icon-expand-6:before {
  content: unicode('f6dc');
}
.icon-nature-plant-4:before {
  content: unicode('f6dd');
}
.icon-window:before {
  content: unicode('f6de');
}
.icon-smiley-wink:before {
  content: unicode('f6df');
}
.icon-shield-skull:before {
  content: unicode('f6e0');
}
.icon-shopping-bag-add:before {
  content: unicode('f6e1');
}
.icon-focus-face:before {
  content: unicode('f6e2');
}
.icon-washing-machine:before {
  content: unicode('f6e3');
}
.icon-health-first-aid-kit:before {
  content: unicode('f6e4');
}
.icon-religion-share-christian-1:before {
  content: unicode('f6e5');
}
.icon-login-2:before {
  content: unicode('f6e6');
}
.icon-shopping-cart-settings:before {
  content: unicode('f6e7');
}
.icon-file-images-download:before {
  content: unicode('f6e8');
}
.icon-speaker-volume-high:before {
  content: unicode('f6e9');
}
.icon-vote-heart-close:before {
  content: unicode('f6ea');
}
.icon-dog-house:before {
  content: unicode('f6eb');
}
.icon-wave:before {
  content: unicode('f6ec');
}
.icon-content-view-module-2:before {
  content: unicode('f6ed');
}
.icon-sunny:before {
  content: unicode('f6ee');
}
.icon-rewards-badge-6:before {
  content: unicode('f6ef');
}
.icon-camera-play:before {
  content: unicode('f6f0');
}
.icon-email-view:before {
  content: unicode('f6f1');
}
.icon-romance-love-target:before {
  content: unicode('f6f2');
}
.icon-leisure-party-flag:before {
  content: unicode('f6f3');
}
.icon-disc-burn:before {
  content: unicode('f6f4');
}
.icon-building-5:before {
  content: unicode('f6f5');
}
.icon-folder-image:before {
  content: unicode('f6f6');
}
.icon-location-gps-off:before {
  content: unicode('f6f7');
}
.icon-baby-letter-blocks:before {
  content: unicode('f6f8');
}
.icon-modular-belt-box:before {
  content: unicode('f6f9');
}
.icon-slider-left:before {
  content: unicode('f6fa');
}
.icon-bitcoin-network:before {
  content: unicode('f6fb');
}
.icon-arrow-page-up:before {
  content: unicode('f6fc');
}
.icon-sign-peace:before {
  content: unicode('f6fd');
}
.icon-sign-u-turn:before {
  content: unicode('f6fe');
}
.icon-remove-1:before {
  content: unicode('f6ff');
}
.icon-night-cloudy-1:before {
  content: unicode('f700');
}
.icon-cloud-settings:before {
  content: unicode('f701');
}
.icon-navigation-next-3:before {
  content: unicode('f702');
}
.icon-gesture-rotate-right-2:before {
  content: unicode('f703');
}
.icon-logout-2:before {
  content: unicode('f704');
}
.icon-content-view-list:before {
  content: unicode('f705');
}
.icon-touchpad-wireless:before {
  content: unicode('f706');
}
.icon-layout-11:before {
  content: unicode('f707');
}
.icon-train-tunnel-1:before {
  content: unicode('f708');
}
.icon-cabinet-1:before {
  content: unicode('f709');
}
.icon-hotel-room-service-2:before {
  content: unicode('f70a');
}
.icon-planet-book:before {
  content: unicode('f70b');
}
.icon-video-camera-1:before {
  content: unicode('f70c');
}
.icon-check-circle-1:before {
  content: unicode('f70d');
}
.icon-window-favorite-heart:before {
  content: unicode('f70e');
}
.icon-login-check:before {
  content: unicode('f70f');
}
.icon-file-video-alert:before {
  content: unicode('f710');
}
.icon-cloudy:before {
  content: unicode('f711');
}
.icon-window-download-1:before {
  content: unicode('f712');
}
.icon-car-fuel-full-1:before {
  content: unicode('f713');
}
.icon-food-pretzel:before {
  content: unicode('f714');
}
.icon-sport-hand-grip:before {
  content: unicode('f715');
}
.icon-handdrill:before {
  content: unicode('f716');
}
.icon-video-call-laptop:before {
  content: unicode('f717');
}
.icon-content-book-timeout:before {
  content: unicode('f718');
}
.icon-projector-screen-shopping-cart:before {
  content: unicode('f719');
}
.icon-window-upload-2:before {
  content: unicode('f71a');
}
.icon-smart-watch-square-star:before {
  content: unicode('f71b');
}
.icon-cursor-click-double-2:before {
  content: unicode('f71c');
}
.icon-user-group-circle:before {
  content: unicode('f71d');
}
.icon-moon-full:before {
  content: unicode('f71e');
}
.icon-video-games-joypad-3:before {
  content: unicode('f71f');
}
.icon-chat-bubble-circle-shield:before {
  content: unicode('f720');
}
.icon-mobile-phone-information:before {
  content: unicode('f721');
}
.icon-image-window-mobile:before {
  content: unicode('f722');
}
.icon-tap-swipe-up-2:before {
  content: unicode('f723');
}
.icon-crystal:before {
  content: unicode('f724');
}
.icon-data-upload-1:before {
  content: unicode('f725');
}
.icon-file-subtract-2:before {
  content: unicode('f726');
}
.icon-user-group-dollar:before {
  content: unicode('f727');
}
.icon-speaker-volume-increase:before {
  content: unicode('f728');
}
.icon-content-book-question-mark:before {
  content: unicode('f729');
}
.icon-places-big-ben:before {
  content: unicode('f72a');
}
.icon-contact-book-2:before {
  content: unicode('f72b');
}
.icon-shield-cash:before {
  content: unicode('f72c');
}
.icon-banking-pinpad-2:before {
  content: unicode('f72d');
}
.icon-synchronize-7:before {
  content: unicode('f72e');
}
.icon-data-upload-10:before {
  content: unicode('f72f');
}
.icon-user-check:before {
  content: unicode('f730');
}
.icon-file-tasks-close:before {
  content: unicode('f731');
}
.icon-greeting-card:before {
  content: unicode('f732');
}
.icon-file-images-favorite-star:before {
  content: unicode('f733');
}
.icon-music-album-refresh:before {
  content: unicode('f734');
}
.icon-video-games-space-invaders-2:before {
  content: unicode('f735');
}
.icon-diamond-ring:before {
  content: unicode('f736');
}
.icon-building-12:before {
  content: unicode('f737');
}
.icon-food-corndog:before {
  content: unicode('f738');
}
.icon-user-group-home:before {
  content: unicode('f739');
}
.icon-file-video-flv:before {
  content: unicode('f73a');
}
.icon-shrink-diagonal-4:before {
  content: unicode('f73b');
}
.icon-file-design-psd:before {
  content: unicode('f73c');
}
.icon-tablet:before {
  content: unicode('f73d');
}
.icon-gas-mask:before {
  content: unicode('f73e');
}
.icon-hammer-anvil:before {
  content: unicode('f73f');
}
.icon-video-subtitle:before {
  content: unicode('f740');
}
.icon-move-down-3:before {
  content: unicode('f741');
}
.icon-leisure-checkers:before {
  content: unicode('f742');
}
.icon-wifi-signal-shield:before {
  content: unicode('f743');
}
.icon-plug-hole-1:before {
  content: unicode('f744');
}
.icon-files-coding-exe:before {
  content: unicode('f745');
}
.icon-kitchen-knife-1:before {
  content: unicode('f746');
}
.icon-location-computer:before {
  content: unicode('f747');
}
.icon-content-book-block:before {
  content: unicode('f748');
}
.icon-move-down-2:before {
  content: unicode('f749');
}
.icon-folder-close:before {
  content: unicode('f74a');
}
.icon-sparkling-wine-glass:before {
  content: unicode('f74b');
}
.icon-programming-jigsaw:before {
  content: unicode('f74c');
}
.icon-shopping-basket-question-mark:before {
  content: unicode('f74d');
}
.icon-security-radar:before {
  content: unicode('f74e');
}
.icon-nature-log:before {
  content: unicode('f74f');
}
.icon-time-share:before {
  content: unicode('f750');
}
.icon-worker:before {
  content: unicode('f751');
}
.icon-smart-watch-circle-power:before {
  content: unicode('f752');
}
.icon-file-audio-close:before {
  content: unicode('f753');
}
.icon-kitchen-heat-low:before {
  content: unicode('f754');
}
.icon-projector-screen-graph-pie:before {
  content: unicode('f755');
}
.icon-smart-watch-circle-voice-record:before {
  content: unicode('f756');
}
.icon-building-13:before {
  content: unicode('f757');
}
.icon-location-globe-edit:before {
  content: unicode('f758');
}
.icon-data-upload-11:before {
  content: unicode('f759');
}
.icon-construction-wall:before {
  content: unicode('f75a');
}
.icon-tap-swipe-left-1:before {
  content: unicode('f75b');
}
.icon-location-pin-shopping-1:before {
  content: unicode('f75c');
}
.icon-subtract-square-1:before {
  content: unicode('f75d');
}
.icon-chat-bubble-circle-information-1:before {
  content: unicode('f75e');
}
.icon-bus-1:before {
  content: unicode('f75f');
}
.icon-reflect-left:before {
  content: unicode('f760');
}
.icon-contact-book-3:before {
  content: unicode('f761');
}
.icon-shrink-horizontal-4:before {
  content: unicode('f762');
}
.icon-synchronize-6:before {
  content: unicode('f763');
}
.icon-baby-hanging-toy:before {
  content: unicode('f764');
}
.icon-health-capsule:before {
  content: unicode('f765');
}
.icon-file-zipped-dollar:before {
  content: unicode('f766');
}
.icon-folder-home:before {
  content: unicode('f767');
}
.icon-fire-house:before {
  content: unicode('f768');
}
.icon-religion-islam:before {
  content: unicode('f769');
}
.icon-food-corn:before {
  content: unicode('f76a');
}
.icon-candle-1:before {
  content: unicode('f76b');
}
.icon-video-games-joypad-2:before {
  content: unicode('f76c');
}
.icon-food-ginger-bread-man:before {
  content: unicode('f76d');
}
.icon-files-copyright-c:before {
  content: unicode('f76e');
}
.icon-box-subtract:before {
  content: unicode('f76f');
}
.icon-window-upload-3:before {
  content: unicode('f770');
}
.icon-expand-diagonal-1:before {
  content: unicode('f771');
}
.icon-camera-anti-shake:before {
  content: unicode('f772');
}
.icon-wrap-text-around:before {
  content: unicode('f773');
}
.icon-sd-card-home:before {
  content: unicode('f774');
}
.icon-new-circle:before {
  content: unicode('f775');
}
.icon-lock-unlock-1:before {
  content: unicode('f776');
}
.icon-calendar-share:before {
  content: unicode('f777');
}
.icon-truck-wrecking-ball:before {
  content: unicode('f778');
}
.icon-shopping-cart-download:before {
  content: unicode('f779');
}
.icon-file-images-settings:before {
  content: unicode('f77a');
}
.icon-calendar-view:before {
  content: unicode('f77b');
}
.icon-swipe-left-1:before {
  content: unicode('f77c');
}
.icon-sign-ticket-gate:before {
  content: unicode('f77d');
}
.icon-target-cash:before {
  content: unicode('f77e');
}
.icon-video-call-mobile-phone:before {
  content: unicode('f77f');
}
.icon-copy-up:before {
  content: unicode('f780');
}
.icon-chat-bubble-square-information-1:before {
  content: unicode('f781');
}
.icon-user-email:before {
  content: unicode('f782');
}
.icon-music-album-edit:before {
  content: unicode('f783');
}
.icon-server-dollar-1:before {
  content: unicode('f784');
}
.icon-folder-question-mark:before {
  content: unicode('f785');
}
.icon-eco-recycle-bin:before {
  content: unicode('f786');
}
.icon-shopping-cart-refresh:before {
  content: unicode('f787');
}
.icon-calendar-subtract:before {
  content: unicode('f788');
}
.icon-file-audio-view:before {
  content: unicode('f789');
}
.icon-hat-sombrero:before {
  content: unicode('f78a');
}
.icon-window-link:before {
  content: unicode('f78b');
}
.icon-credit-card-sync:before {
  content: unicode('f78c');
}
.icon-panorama-horizontal:before {
  content: unicode('f78d');
}
.icon-yin-yang:before {
  content: unicode('f78e');
}
.icon-layout-10:before {
  content: unicode('f78f');
}
.icon-navigation-next-2:before {
  content: unicode('f790');
}
.icon-folder-zip:before {
  content: unicode('f791');
}
.icon-sport-bowling:before {
  content: unicode('f792');
}
.icon-server-sync-2:before {
  content: unicode('f793');
}
.icon-thermometer-negative:before {
  content: unicode('f794');
}
.icon-server-alert-2:before {
  content: unicode('f795');
}
.icon-sword:before {
  content: unicode('f796');
}
.icon-android-close:before {
  content: unicode('f797');
}
.icon-chat-bubble-circle-smiley:before {
  content: unicode('f798');
}
.icon-login-wrong:before {
  content: unicode('f799');
}
.icon-file-office-flowchart:before {
  content: unicode('f79a');
}
.icon-interface-share-settings:before {
  content: unicode('f79b');
}
.icon-android-add:before {
  content: unicode('f79c');
}
.icon-content-view-array:before {
  content: unicode('f79d');
}
.icon-fire-sprinkler:before {
  content: unicode('f79e');
}
.icon-box-shipping-ship:before {
  content: unicode('f79f');
}
.icon-location-pin-star-1:before {
  content: unicode('f7a0');
}
.icon-camera-shade-mode:before {
  content: unicode('f7a1');
}
.icon-mobile-phone-sync-1:before {
  content: unicode('f7a2');
}
.icon-travel-departure-confirm:before {
  content: unicode('f7a3');
}
.icon-cat-fishbone:before {
  content: unicode('f7a4');
}
.icon-hotel-bed-2:before {
  content: unicode('f7a5');
}
.icon-health-ambulance:before {
  content: unicode('f7a6');
}
.icon-building-4:before {
  content: unicode('f7a7');
}
.icon-email-document:before {
  content: unicode('f7a8');
}
.icon-file-alert-1:before {
  content: unicode('f7a9');
}
.icon-inbox-user:before {
  content: unicode('f7aa');
}
.icon-eco-water-dam:before {
  content: unicode('f7ab');
}
.icon-cursor-arrow-add:before {
  content: unicode('f7ac');
}
.icon-rgb-color:before {
  content: unicode('f7ad');
}
.icon-server-shield-2:before {
  content: unicode('f7ae');
}
.icon-share-megaphone-2:before {
  content: unicode('f7af');
}
.icon-remove-square-2:before {
  content: unicode('f7b0');
}
.icon-user-sync:before {
  content: unicode('f7b1');
}
.icon-g-roaming1:before {
  content: unicode('f7b2');
}
.icon-mobile-phone-rotate-1:before {
  content: unicode('f7b3');
}
.icon-soda-glass:before {
  content: unicode('f7b4');
}
.icon-spa-hot-water-bath:before {
  content: unicode('f7b5');
}
.icon-files-coding-4:before {
  content: unicode('f7b6');
}
.icon-smart-watch-circle-sync:before {
  content: unicode('f7b7');
}
.icon-leisure-chess:before {
  content: unicode('f7b8');
}
.icon-sign-ramp-right:before {
  content: unicode('f7b9');
}
.icon-camera-flash-off:before {
  content: unicode('f7ba');
}
.icon-location-globe-dollar:before {
  content: unicode('f7bb');
}
.icon-location-globe-alert:before {
  content: unicode('f7bc');
}
.icon-file-statistic-question-mark-1:before {
  content: unicode('f7bd');
}
.icon-chat-bubble-square-refresh:before {
  content: unicode('f7be');
}
.icon-water-spray:before {
  content: unicode('f7bf');
}
.icon-android-dollar:before {
  content: unicode('f7c0');
}
.icon-globe-all-direction:before {
  content: unicode('f7c1');
}
.icon-interface-radioactive-1:before {
  content: unicode('f7c2');
}
.icon-sport-finishing-flag:before {
  content: unicode('f7c3');
}
.icon-eco-electric-car-2:before {
  content: unicode('f7c4');
}
.icon-expand-7:before {
  content: unicode('f7c5');
}
.icon-files-coding-edit-2:before {
  content: unicode('f7c6');
}
.icon-clapboard-play:before {
  content: unicode('f7c7');
}
.icon-airplane:before {
  content: unicode('f7c8');
}
.icon-credit-card-alert:before {
  content: unicode('f7c9');
}
.icon-g-roaming2:before {
  content: unicode('f7ca');
}
.icon-interface-increasing:before {
  content: unicode('f7cb');
}
.icon-video-games-pacman:before {
  content: unicode('f7cc');
}
.icon-network-home:before {
  content: unicode('f7cd');
}
.icon-moon-rocket:before {
  content: unicode('f7ce');
}
.icon-chat-bubble-circle-add:before {
  content: unicode('f7cf');
}
.icon-mobile-phone-bin:before {
  content: unicode('f7d0');
}
.icon-mobile-phone-upload-1:before {
  content: unicode('f7d1');
}
.icon-car-battery-jumper-head:before {
  content: unicode('f7d2');
}
.icon-kitchen-spatula:before {
  content: unicode('f7d3');
}
.icon-paper-grade-f:before {
  content: unicode('f7d4');
}
.icon-mouse:before {
  content: unicode('f7d5');
}
.icon-nature-forest-fire:before {
  content: unicode('f7d6');
}
.icon-phone-timeout:before {
  content: unicode('f7d7');
}
.icon-file-images-dollar:before {
  content: unicode('f7d8');
}
.icon-camera-polaroid:before {
  content: unicode('f7d9');
}
.icon-sign-no-fire:before {
  content: unicode('f7da');
}
.icon-expand-5:before {
  content: unicode('f7db');
}
.icon-shield-check:before {
  content: unicode('f7dc');
}
.icon-window-modules:before {
  content: unicode('f7dd');
}
.icon-login-1:before {
  content: unicode('f7de');
}
.icon-religion-share-christian-2:before {
  content: unicode('f7df');
}
.icon-bow-tie:before {
  content: unicode('f7e0');
}
.icon-smart-watch-square-temperature:before {
  content: unicode('f7e1');
}
.icon-gramophone:before {
  content: unicode('f7e2');
}
.icon-rewards-badge-5:before {
  content: unicode('f7e3');
}
.icon-chat-bubble-square-home:before {
  content: unicode('f7e4');
}
.icon-kitchen-pan-fry:before {
  content: unicode('f7e5');
}
.icon-content-view-module-1:before {
  content: unicode('f7e6');
}
.icon-scale-digital:before {
  content: unicode('f7e7');
}
.icon-food-popcorn:before {
  content: unicode('f7e8');
}
.icon-user-group-email:before {
  content: unicode('f7e9');
}
.icon-car-windshield:before {
  content: unicode('f7ea');
}
.icon-text-undo:before {
  content: unicode('f7eb');
}
.icon-location-globe-pin:before {
  content: unicode('f7ec');
}
.icon-building-6:before {
  content: unicode('f7ed');
}
.icon-file-zipped-favorite-star:before {
  content: unicode('f7ee');
}
.icon-navigation-filter-8:before {
  content: unicode('f7ef');
}
.icon-international-yen:before {
  content: unicode('f7f0');
}
.icon-spa-towel:before {
  content: unicode('f7f1');
}
.icon-cursor-arrow-move:before {
  content: unicode('f7f2');
}
.icon-cloud-favorite-star:before {
  content: unicode('f7f3');
}
.icon-night-cloudy-2:before {
  content: unicode('f7f4');
}
.icon-navigation-filter-image:before {
  content: unicode('f7f5');
}
.icon-gas-tank:before {
  content: unicode('f7f6');
}
.icon-sign-elevator:before {
  content: unicode('f7f7');
}
.icon-remove-2:before {
  content: unicode('f7f8');
}
.icon-leisure-mask-sad:before {
  content: unicode('f7f9');
}
.icon-layout-12:before {
  content: unicode('f7fa');
}
.icon-train-tunnel-2:before {
  content: unicode('f7fb');
}
.icon-cabinet-2:before {
  content: unicode('f7fc');
}
.icon-location-globe-subtract:before {
  content: unicode('f7fd');
}
.icon-grid-question-mark:before {
  content: unicode('f7fe');
}
.icon-logout-1:before {
  content: unicode('f7ff');
}
.icon-user-magnify:before {
  content: unicode('f800');
}
.icon-file-audio-box-aif:before {
  content: unicode('f801');
}
.icon-focus-manual:before {
  content: unicode('f802');
}
.icon-gesture-rotate-right-1:before {
  content: unicode('f803');
}
.icon-smart-watch-circle-bar-graph:before {
  content: unicode('f804');
}
.icon-file-images-share:before {
  content: unicode('f805');
}
.icon-content-modules:before {
  content: unicode('f806');
}
.icon-files-coding-box-xml:before {
  content: unicode('f807');
}
.icon-sport-muscle-training:before {
  content: unicode('f808');
}
.icon-binoculars:before {
  content: unicode('f809');
}
.icon-video-camera-2:before {
  content: unicode('f80a');
}
.icon-check-circle-2:before {
  content: unicode('f80b');
}
.icon-file-notes-add:before {
  content: unicode('f80c');
}
.icon-hotel-room-service-1:before {
  content: unicode('f80d');
}
.icon-kitchen-gloves:before {
  content: unicode('f80e');
}
.icon-window-download-2:before {
  content: unicode('f80f');
}
.icon-car-fuel-full-2:before {
  content: unicode('f810');
}
.icon-cloud-edit:before {
  content: unicode('f811');
}
.icon-projector-screen-graph-bar:before {
  content: unicode('f812');
}
.icon-alarm:before {
  content: unicode('f813');
}
.icon-shopping-cart-shield:before {
  content: unicode('f814');
}
.icon-email-sync:before {
  content: unicode('f815');
}
.icon-battery-eco:before {
  content: unicode('f816');
}
.icon-file-notes-lock:before {
  content: unicode('f817');
}
.icon-lock-unlock-3:before {
  content: unicode('f818');
}
.icon-user-upload-1:before {
  content: unicode('f819');
}
.icon-paint-can:before {
  content: unicode('f81a');
}
.icon-grid-favorite-heart:before {
  content: unicode('f81b');
}
.icon-expand-diagonal-3:before {
  content: unicode('f81c');
}
.icon-window-upload-1:before {
  content: unicode('f81d');
}
.icon-cursor-click-double-1:before {
  content: unicode('f81e');
}
.icon-user-flash:before {
  content: unicode('f81f');
}
.icon-file-subtract-1:before {
  content: unicode('f820');
}
.icon-subtract-tab:before {
  content: unicode('f821');
}
.icon-tap-swipe-up-1:before {
  content: unicode('f822');
}
.icon-data-upload-2:before {
  content: unicode('f823');
}
.icon-file-video-mpg:before {
  content: unicode('f824');
}
.icon-cloud-download-mobile:before {
  content: unicode('f825');
}
.icon-kitchen-steam:before {
  content: unicode('f826');
}
.icon-user-skull:before {
  content: unicode('f827');
}
.icon-data-upload-13:before {
  content: unicode('f828');
}
.icon-paperplane:before {
  content: unicode('f829');
}
.icon-banking-pinpad-1:before {
  content: unicode('f82a');
}
.icon-synchronize-4:before {
  content: unicode('f82b');
}
.icon-contact-book-1:before {
  content: unicode('f82c');
}
.icon-bus-3:before {
  content: unicode('f82d');
}
.icon-file-design-document:before {
  content: unicode('f82e');
}
.icon-building-11:before {
  content: unicode('f82f');
}
.icon-video-games-space-invaders-1:before {
  content: unicode('f830');
}
.icon-box-alert:before {
  content: unicode('f831');
}
.icon-kitchen-knife-2:before {
  content: unicode('f832');
}
.icon-shopping-cart-full:before {
  content: unicode('f833');
}
.icon-g-signal2:before {
  content: unicode('f834');
}
.icon-plug-hole-2:before {
  content: unicode('f835');
}
.icon-wifi-signal-computer:before {
  content: unicode('f836');
}
.icon-file-notes-download:before {
  content: unicode('f837');
}
.icon-tag-edit:before {
  content: unicode('f838');
}
.icon-folder-hand:before {
  content: unicode('f839');
}
.icon-move-down-1:before {
  content: unicode('f83a');
}
.icon-leisure-balloon:before {
  content: unicode('f83b');
}
.icon-beauty-mascara:before {
  content: unicode('f83c');
}
.icon-shopping-cart-information:before {
  content: unicode('f83d');
}
.icon-network-check:before {
  content: unicode('f83e');
}
.icon-building-10:before {
  content: unicode('f83f');
}
.icon-folder-dollar:before {
  content: unicode('f840');
}
.icon-synchronize-5:before {
  content: unicode('f841');
}
.icon-paint-bucket:before {
  content: unicode('f842');
}
.icon-shopping-basket-close:before {
  content: unicode('f843');
}
.icon-sd-card-shield:before {
  content: unicode('f844');
}
.icon-chat-bubble-circle-information-2:before {
  content: unicode('f845');
}
.icon-move-up:before {
  content: unicode('f846');
}
.icon-bus-2:before {
  content: unicode('f847');
}
.icon-slider-right:before {
  content: unicode('f848');
}
.icon-file-images-timeout:before {
  content: unicode('f849');
}
.icon-tap-swipe-left-2:before {
  content: unicode('f84a');
}
.icon-location-pin-shopping-2:before {
  content: unicode('f84b');
}
.icon-subtract-square-2:before {
  content: unicode('f84c');
}
.icon-moon:before {
  content: unicode('f84d');
}
.icon-data-upload-12:before {
  content: unicode('f84e');
}
.icon-view-add:before {
  content: unicode('f84f');
}
.icon-data-upload-3:before {
  content: unicode('f850');
}
.icon-candle-2:before {
  content: unicode('f851');
}
.icon-health-crutch:before {
  content: unicode('f852');
}
.icon-real-estate-add:before {
  content: unicode('f853');
}
.icon-weight:before {
  content: unicode('f854');
}
.icon-vote-star-half:before {
  content: unicode('f855');
}
.icon-user-expand:before {
  content: unicode('f856');
}
.icon-expand-diagonal-2:before {
  content: unicode('f857');
}
.icon-places-moai:before {
  content: unicode('f858');
}
.icon-download-computer:before {
  content: unicode('f859');
}
.icon-glass-cocktail-4:before {
  content: unicode('f85a');
}
.icon-network-connecting:before {
  content: unicode('f85b');
}
.icon-cloud-disable:before {
  content: unicode('f85c');
}
.icon-smiley-sad:before {
  content: unicode('f85d');
}
.icon-video-games-joypad-1:before {
  content: unicode('f85e');
}
.icon-hotel-fitness-room:before {
  content: unicode('f85f');
}
.icon-pet-toy-mouse:before {
  content: unicode('f860');
}
.icon-night-rainy:before {
  content: unicode('f861');
}
.icon-lock-unlock-2:before {
  content: unicode('f862');
}
.icon-romance-wedding-date:before {
  content: unicode('f863');
}
.icon-grid-shield:before {
  content: unicode('f864');
}
.icon-nature-leaf:before {
  content: unicode('f865');
}
.icon-user-view:before {
  content: unicode('f866');
}
.icon-compass-east:before {
  content: unicode('f867');
}
.icon-paragraph-left-to-right:before {
  content: unicode('f868');
}
.icon-reflect-copy-right:before {
  content: unicode('f869');
}
.icon-file-audio-sync:before {
  content: unicode('f86a');
}
.icon-swipe-left-2:before {
  content: unicode('f86b');
}
.icon-window-download-3:before {
  content: unicode('f86c');
}
.icon-food-chicken-drum-stick:before {
  content: unicode('f86d');
}
.icon-nature-sun-rise:before {
  content: unicode('f86e');
}
.icon-camera-live-view-off:before {
  content: unicode('f86f');
}
.icon-border-none:before {
  content: unicode('f870');
}
.icon-food-cake-piece:before {
  content: unicode('f871');
}
.icon-money-bag-yen:before {
  content: unicode('f872');
}
.icon-window-favorite-star:before {
  content: unicode('f873');
}
.icon-server-dollar-2:before {
  content: unicode('f874');
}
.icon-email-timeout:before {
  content: unicode('f875');
}
.icon-chat-bubble-square-information-2:before {
  content: unicode('f876');
}
.icon-file-audio-box-wav:before {
  content: unicode('f877');
}
.icon-user-shield:before {
  content: unicode('f878');
}
.icon-video-camera-3:before {
  content: unicode('f879');
}
.icon-vote-heart-question-mark:before {
  content: unicode('f87a');
}
.icon-smart-watch-square-dollar:before {
  content: unicode('f87b');
}
.icon-percent-circle:before {
  content: unicode('f87c');
}
.icon-server-sync-1:before {
  content: unicode('f87d');
}
.icon-server-alert-1:before {
  content: unicode('f87e');
}
.icon-calendar-sync:before {
  content: unicode('f87f');
}
.icon-grid-share:before {
  content: unicode('f880');
}
.icon-navigation-next-1:before {
  content: unicode('f881');
}
.icon-health-cannabis-leaf:before {
  content: unicode('f882');
}
.icon-cabinet-3:before {
  content: unicode('f883');
}
.icon-bitcoin-windows:before {
  content: unicode('f884');
}
.icon-file-design-box-dxf:before {
  content: unicode('f885');
}
.icon-layout-13:before {
  content: unicode('f886');
}
.icon-window-shrink:before {
  content: unicode('f887');
}
.icon-eco-reuse:before {
  content: unicode('f888');
}
.icon-road-circle:before {
  content: unicode('f889');
}
.icon-smart-watch-circle-wireless-signal:before {
  content: unicode('f88a');
}
.icon-direction-sign:before {
  content: unicode('f88b');
}
.icon-content-board:before {
  content: unicode('f88c');
}
.icon-user-group-check:before {
  content: unicode('f88d');
}
.icon-navigation-filter-9:before {
  content: unicode('f88e');
}
.icon-kitchen-mixing-machine:before {
  content: unicode('f88f');
}
.icon-building-7:before {
  content: unicode('f890');
}
.icon-hotel-bed-1:before {
  content: unicode('f891');
}
.icon-mobile-phone-sync-2:before {
  content: unicode('f892');
}
.icon-location-pin-star-2:before {
  content: unicode('f893');
}
.icon-real-estate-alert:before {
  content: unicode('f894');
}
.icon-inbox-image:before {
  content: unicode('f895');
}
.icon-mobile-phone-rotate-2:before {
  content: unicode('f896');
}
.icon-file-notes-settings:before {
  content: unicode('f897');
}
.icon-chat-bubbles-circle:before {
  content: unicode('f898');
}
.icon-server-shield-1:before {
  content: unicode('f899');
}
.icon-files-creative-commons:before {
  content: unicode('f89a');
}
.icon-share-megaphone-1:before {
  content: unicode('f89b');
}
.icon-stamp:before {
  content: unicode('f89c');
}
.icon-remove-square-1:before {
  content: unicode('f89d');
}
.icon-calendar-question-mark:before {
  content: unicode('f89e');
}
.icon-lte-roaming:before {
  content: unicode('f89f');
}
.icon-file-alert-2:before {
  content: unicode('f8a0');
}
.icon-real-estate-lock:before {
  content: unicode('f8a1');
}
.icon-shopping-cart-add:before {
  content: unicode('f8a2');
}
.icon-rewards-badge-4:before {
  content: unicode('f8a3');
}
.icon-building-hotel:before {
  content: unicode('f8a4');
}
.icon-cursor-arrow-subtract:before {
  content: unicode('f8a5');
}
.icon-travel-scuba:before {
  content: unicode('f8a6');
}
.icon-align-left:before {
  content: unicode('f8a7');
}
.icon-cloud-shield:before {
  content: unicode('f8a8');
}
.icon-file-video-subtract:before {
  content: unicode('f8a9');
}
.icon-share-satellite:before {
  content: unicode('f8aa');
}
.icon-food-apple:before {
  content: unicode('f8ab');
}
.icon-file-modules:before {
  content: unicode('f8ac');
}
.icon-text-eraser:before {
  content: unicode('f8ad');
}
.icon-file-statistic-question-mark-2:before {
  content: unicode('f8ae');
}
.icon-credit-card-favorite-heart:before {
  content: unicode('f8af');
}
.icon-nature-bacteria:before {
  content: unicode('f8b0');
}
.icon-reflect-right:before {
  content: unicode('f8b1');
}
.icon-expand-4:before {
  content: unicode('f8b2');
}
.icon-files-coding-edit-1:before {
  content: unicode('f8b3');
}
.icon-shopping-basket-edit:before {
  content: unicode('f8b4');
}
.icon-interface-radioactive-2:before {
  content: unicode('f8b5');
}
.icon-eco-clean-electricity:before {
  content: unicode('f8b6');
}
.icon-eco-electric-car-1:before {
  content: unicode('f8b7');
}
.icon-zoom-area-in:before {
  content: unicode('f8b8');
}
.icon-chat-bubble-circle-refresh:before {
  content: unicode('f8b9');
}
.icon-wheelbarrow:before {
  content: unicode('f8ba');
}
.icon-cement-mixer:before {
  content: unicode('f8bb');
}
.icon-book-text-view:before {
  content: unicode('f8bc');
}
