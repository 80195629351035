@import '../../node_modules/@mdi/font/css/materialdesignicons.min.css';
// Base
@import './base/colors';
@import './base/base';
@import './base/fonts';
@import './base/CustomFonts/index';
// Effects
@import './effects/2d-transitions/grow';
@import './effects/2d-transitions/fade-in';
@import './effects/background-transitions/radial-out';
@import './effects/background-transitions/back-pulse';
@import './effects/background-transitions/animated-border';
// Common
@import './common/common';

// Tables
@import 'tables/tableHead';

// OktaSignInWidget styles override
@import 'oktaWidget/oktaWidjetStyles';

// Snackbar
@import '../styles/snackbar/snackbar';

// SURVEY
@import 'survey/survey-creator';
