.Dropdown-hover {
  &__container {
    position: relative;

    &--backdrop {
      z-index: 100;
    }

    &:not(.Dropdown-hover__container--force-hidden):hover,
    &.Dropdown-hover__container--visible {
      .Dropdown-hover--menu-container {
        z-index: 100;
        opacity: 1;
        visibility: visible;
        &-bottom {
          top: 100%;
          transform: translateY(10px);
        }

        &-top {
          bottom: 100%;
          transform: translateY(-10px);
        }

        &.drop-bottom-center {
          transform: translate(-50%, 10px);
        }

        &.drop-right-center {
          top: 50%;
          left: 100%;
          right: auto;
          margin: 0;
          padding: 0 0 0 10px;
          transform: translateY(-40%);
        }
      }

      .Dropdown-hover--button.colored-button button {
        color: #fff;
        border-color: #1c5dca;
        background-color: #1c5dca;
      }

      & + .Dropdown-hover--backdrop {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &--menu {
    border-radius: 8px;
    overflow: hidden;

    &:before,
    &:after {
      border-style: solid;
      border-width: 0 7px 7px 7px;

      .drop-right-center & {
        border-width: 7px 7px 7px 0;
      }
    }

    &-bottom {
      &::before {
        top: 5px;
      }
      &::after {
        top: 4px;
      }
    }

    &-top {
      &::before {
        bottom: 4px;
        transform: rotate(180deg);
      }
      &::after {
        bottom: 3px;
        transform: rotate(180deg);
      }
    }

    &:before {
      position: absolute;
      z-index: 200;
      content: '';
      right: 12px;
      border-color: transparent transparent #fff transparent;

      .drop-bottom-right & {
        right: auto;
        left: 12px;
      }

      .drop-bottom-center & {
        right: auto;
        left: 48.5%;
      }

      .drop-right-center & {
        top: 40%;
        left: 4px;
        right: auto;
        border-color: transparent #fff transparent transparent;
        transform: translateY(-50%);
      }
    }

    &:after {
      position: absolute;
      z-index: 100;
      content: '';
      right: 11px;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent #e4e9f3 transparent;

      .drop-bottom-right & {
        right: auto;
        left: 11px;
      }

      .drop-bottom-center & {
        right: auto;
        left: 48.5%;
        transform: translateX(-1px);
      }

      .drop-right-center & {
        top: 40%;
        left: 3px;
        right: auto;
        transform: translateY(-50%);
        border-width: 8px 8px 8px 0;
        border-color: transparent #e4e9f3 transparent transparent;
      }
    }

    &-container {
      opacity: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      margin-top: -10px;
      padding-top: 10px;
      transform: perspective(1px) translateZ(0);
      transition-duration: 0.3s;
      transition-property: transform, opacity;
      visibility: hidden;

      &-top {
        bottom: 100%;
        padding-top: 0px;
        padding-bottom: 10px;
        margin-bottom: -10px;
      }

      &.drop-bottom-right {
        right: auto;
        left: 0;
      }

      &.drop-bottom-center {
        right: auto;
        left: 50%;
        transform: perspective(1px) translateZ(0) translateX(-50%);
      }
    }
  }
}

.Dropdown-hover--backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.ag-menu-option {
  .ag-menu-option-icon {
    .fa-download {
      font-size: 16px;
      color: rgb(173, 179, 194);
    }
  }
}
