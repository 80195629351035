.gradient-border {
  --borderWidth: 3px;
  position: relative;
  border-radius: 4px;
  &:hover {
    &::after {
      border-image: linear-gradient(var(--angle), #5f83bb, #0a83c8, #46a8e0) 1;
      animation: none;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: var(--borderWidth) solid;
    border-image: linear-gradient(var(--angle), #0a1526, #0a83c8, #46a8e0) 1;
    animation: 5s rotate linear infinite;
  }
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}
